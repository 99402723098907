import React from "react";
import {
    DialogContent,
    Divider,
    FilledInput,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Role } from "types/user";
import { AddUserPopupProps } from "./AddUserPopup.types";
import styles from "./AddUserPopup.styles";
import RoundedButton from "../RoundedButton/RoundedButton";

const AddUserPopup: React.FC<AddUserPopupProps> = ({
    handlePopupClose,
    handleOrganisationSelectChange,
    addUser,
    inputFieldValues,
    handleInputFieldChange,
    showPassword,
    handleShowPassword,
    errorText,
    handleRoleSelectChange,
    organisations,
    role,
    currentOrganisations,
}) => {
    const classes = styles();
    const error = errorText !== "";
    return (
        <DialogContent className={classes.popup}>
            <div className={classes.formContainer}>
                <Typography className={classes.formTitle} variant="h5">
                    Nieuwe user toevoegen
                </Typography>
                <FormControl className={classes.textField}>
                    <InputLabel
                        error={error}
                        className={classes.formLabel}
                        htmlFor="filled-username"
                    >
                        Naam
                    </InputLabel>
                    <FilledInput
                        id="filled-username"
                        type="text"
                        value={inputFieldValues.user_name}
                        onChange={handleInputFieldChange("user_name")}
                        error={error}
                    />
                </FormControl>
                {error && <FormHelperText error>{errorText}</FormHelperText>}
                <FormControl className={classes.textField}>
                    <InputLabel
                        error={error}
                        className={classes.formLabel}
                        htmlFor="filled-email"
                    >
                        Email
                    </InputLabel>
                    <FilledInput
                        id="filled-email"
                        type="text"
                        value={inputFieldValues.email}
                        onChange={handleInputFieldChange("email")}
                        error={error}
                    />
                </FormControl>
                <FormControl className={classes.textField}>
                    <InputLabel
                        className={classes.formLabel}
                        htmlFor="filled-password"
                    >
                        Wachtwoord
                    </InputLabel>
                    <FilledInput
                        id="filled-password"
                        type={showPassword ? "text" : "password"}
                        value={inputFieldValues.password}
                        onChange={handleInputFieldChange("password")}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl className={classes.textField}>
                    <InputLabel
                        className={classes.formLabel}
                        htmlFor="select-role"
                    >
                        Rol
                    </InputLabel>
                    <Select
                        labelId="select-role"
                        value={inputFieldValues.role}
                        label="Rol"
                        onChange={handleRoleSelectChange}
                    >
                        <MenuItem value={Role.manager}>Manager</MenuItem>
                        <MenuItem value={Role.user}>User</MenuItem>
                    </Select>
                </FormControl>
                <FormControl className={classes.textField}>
                    <InputLabel
                        className={classes.formLabel}
                        htmlFor="select-organisation"
                    >
                        Organisation
                    </InputLabel>
                    {role === "admin" ? (
                        <Select
                            labelId="select-organisation"
                            value={inputFieldValues.organisation_id}
                            label="Organisation"
                            onChange={handleOrganisationSelectChange}
                        >
                            {organisations.map((organisation) => (
                                <MenuItem value={organisation.id}>
                                    {organisation.name}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <FilledInput
                            id="select-organisation"
                            type="text"
                            value={currentOrganisations[0].name}
                        />
                    )}
                </FormControl>
            </div>
            <Divider />
            <Grid
                className={classes.formContainer}
                container
                justify="space-between"
            >
                <Grid item>
                    <RoundedButton
                        text="Voeg gebruiker toe"
                        onClick={addUser}
                        color="darkBlue"
                    />
                </Grid>
                <Grid item>
                    <RoundedButton
                        text="Annuleren"
                        onClick={handlePopupClose}
                        color="grey"
                    />
                </Grid>
            </Grid>
        </DialogContent>
    );
};

export default AddUserPopup;
