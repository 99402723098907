import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink, useHistory } from "react-router-dom";
import ReduxState from "store/store.types";
import { connect } from "react-redux";
import { getDailyAverages } from "store/patient/patient.actions";
import { GetDailyAveragesRequest } from "api/types/Patient";
import { dateToDayMonthYearString } from "helpers/dateToString";
import { Breadcrumbs, Grid, Link, Typography } from "@material-ui/core";
import {
    GetApp,
    Home,
    NavigateNext,
    Person,
    Timelapse,
} from "@material-ui/icons";
import RoundedButton from "components/RoundedButton/RoundedButton";
import PatientOverviewTable from "components/PatientOverviewTable/PatientOverviewTable";
import PatientOverviewBarChart from "components/PatientOverviewBarChart/PatientOverviewBarChart";
import moment, { Moment } from "moment";
import localStorageManager from "data/localStorageManager";

import { createPatientDailyAveragesCSV } from "helpers/createCsv";
import { Filters } from "components/ValuesFilterFormControl/ValuesFilterFormControl.types";
import styles from "./PatientHome.styles";
import { Dates, PatientHomeProps } from "./PatientHome.types";

const PatientHome: React.FC<PatientHomeProps> = ({
    currentPatient,
    getDailyAverages,
    loadingData,
}) => {
    const { patient } = useParams();
    const history = useHistory();
    const localDates = localStorageManager.getDates(patient);
    const [dates, setDates] = useState<Dates>(
        localDates || {
            startDate: moment.utc().subtract(7, "days"), // REMINDER: if sevenWeeks is used change back to oneWeek
            endDate: moment.utc(),
        }
    );
    const [filters, setFilters] = useState<Filters>({
        average: {
            heart_rate_nonin_average: true,
            spo2_average_average: true,
            spo2_minimum_average: true,
            // pai_average: true,
            hrv_average: true,
            resting_heart_rate: true,
            sleep_efficiency_fitbit: true,
            total_sleeptime_fitbit: true,
            total_activity_fitbit: true,
            temperature_difference: true,
            weight: true, 
            breathing_rate: true,
        },
        detailed: {},
        realTime: {},
    });
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({
            ...filters,
            average: {
                ...filters.average,
                [event.target.name]: event.target.checked,
            },
        });
    };
    const classes = styles();
    const handleDateChange = (date: Moment | null, props: keyof Dates) => {
        if (date !== null) {
            if (props === "startDate") {
                if (date > dates.endDate) {
                    setDates({
                        startDate: date,
                        endDate: moment.utc(date).add(7, "days"),
                    });
                } else {
                    setDates({
                        startDate: date,
                        endDate: dates.endDate,
                    });
                }
            } else if (props === "endDate") {
                if (date < dates.startDate) {
                    setDates({
                        startDate: moment.utc(date).subtract(7, "days"),
                        endDate: date,
                    });
                } else {
                    setDates({
                        startDate: dates.startDate,
                        endDate: date,
                    });
                }
            }
        }
    };
    const handleWeekChange = (backInTime?: boolean) => {
        if (backInTime) {
            setDates({
                startDate: moment.utc(dates.startDate).subtract(6, "days"),
                endDate: moment.utc(dates.endDate).subtract(6, "days"),
            });
        } else {
            setDates({
                startDate: moment.utc(dates.startDate).add(6, "days"),
                endDate: moment.utc(dates.endDate).add(6, "days"),
            });
        }
    };
    const downloadCsv = () => {
        if (currentPatient.dailyAverages) {
            createPatientDailyAveragesCSV(
                currentPatient.dailyAverages,
                patient,
                dates.startDate,
                dates.endDate
            );
        }
    };
    useEffect(() => {
        localStorageManager.setDates(dates, patient);
        const dailyAveragesRequest: GetDailyAveragesRequest = {
            patient_name: patient,
            start_date: dateToDayMonthYearString(dates.startDate, "YYYY-MM-DD"),
            end_date: dateToDayMonthYearString(dates.endDate, "YYYY-MM-DD"),
        };
        getDailyAverages(dailyAveragesRequest);
    }, [dates, patient, getDailyAverages]);

    return (
        <Grid className={classes.div}>
            <Typography variant="h4" className={classes.titleText}>
                {patient}
            </Typography>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.title}
            >
                <Grid item>
                    <Breadcrumbs
                        separator={<NavigateNext className={classes.divider} />}
                    >
                        <Link
                            to="/"
                            className={classes.link}
                            component={RouterLink}
                        >
                            <Home className={classes.icon} />
                            Patienten
                        </Link>
                        <Typography className={classes.currentPageBreadCrumb}>
                            <Person className={classes.icon} />
                            {patient}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid
                    item
                    container
                    direction="row"
                    justify="flex-end"
                    style={{ width: "750px" }}
                >
                    <RoundedButton
                        text="Realtime pagina"
                        onClick={() => history.push(`/${patient}/realtime`)}
                        color="grey"
                        Icon={Timelapse}
                    />
                    <RoundedButton
                        text="Download CSV"
                        onClick={downloadCsv}
                        color="darkBlue"
                        Icon={GetApp}
                    />
                </Grid>
            </Grid>
            <PatientOverviewBarChart
                loadingData={loadingData}
                dates={dates}
                handleDateChange={handleDateChange}
                dailyAverages={currentPatient.dailyAverages}
                dailyAverageGraphData={currentPatient.dailyAveragesGraphData}
                patientName={patient}
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleWeekChange={handleWeekChange}
            />
            <PatientOverviewTable
                loadingData={loadingData}
                dailyAverages={currentPatient?.dailyAverages}
            />
        </Grid>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    currentPatient: state.patient.currentPatient,
    loadingData: state.patient.loadingDailyAverages,
});
const mapDispatchToProps = {
    getDailyAverages,
};
const ConnectedPatientHome = connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientHome);
export default ConnectedPatientHome;
