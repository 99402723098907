import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    root: (props: { selected?: any }) => ({
        padding: 0,
        textTransform: "revert",
        justifyContent: "flex-end",
        color: "#C9CFDA",
        backgroundColor: props.selected ? "rgb(239,239,239)" : "transparent",
        minWidth: "30px",
        minHeight: "30px",
    }),
    icon: { height: "30px", width: "30px" },
    tableHeaderItem: {
        color: "#044272",
        fontWeight: 700,
        size: "16px",
        lineHeight: "32px",
        alignItems: "center",
        display: "flex",
    },
    options: {
        color: "#044272",
        fontWeight: 700,
        size: "16px",
        lineHeight: "21px",
    },
    tableheader: {
        height: "52px",
        width: "1160px",
        boxShadow: "inset 0px -1px 0px rgba(4, 66, 114, 0.16)",
    },
    headerIcon: { margin: "0 5px 0 0", width: "30px", height: "30px" },
    tableCellItem: {
        size: "16px",
        lineHeight: "32px",
        alignItems: "center",
        color: "#044272",
    },
    button: {
        padding: 0,
        textTransform: "revert",
        justifyContent: "revert",
        minWidth: "30px",
        minHeight: "30px",
    },
    optionsButton: {
        padding: 0,
        textTransform: "revert",
        justifyContent: "flex-end",
        color: "#C9CFDA",
    },
    footer: {
        color: "#044272",
        "& .MuiTablePagination-caption": { fontWeight: 600 },
    },
    tableCell: {
        cursor: "pointer",
        padding: "6px 12px",
        "&:hover": { backgroundColor: "rgb(239,239,239)" },
    },
    generalCell: { padding: "6px 12px" },
    popover: {
        pointerEvents: "none",
    },
    paper: {
        padding: 0,
    },
    pieContainer: {
        width: 500,
    },
    fitbitThumb: {
        width: "20px",
        height: "20px",
    },
    noninThumb: {
        width: "20px",
        height: "20px",
    },
    tabButton: (props: { selected?: any }) => ({
        cursor: "pointer",
        padding: "6px",
        backgroundColor: props.selected ? "#fff" : "#rgb(248,249,252)",
        borderRadius: "4px 4px 0 0",
        boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        clipPath: "inset(-10px -10px 0px -10px)",
        "&:hover": { backgroundColor: "#rgb(248,249,252)", cursor: "pointer" },
    }),
});
