import React from "react";
import { Grid, Typography } from "@material-ui/core";
import OrganisationTable from "components/OrganisationTable/OrganisationTable";
import styles from "./OrganisationManagement.styles";

const OrganisationManagement: React.FC = () => {
    const classes = styles();

    return (
        <Grid className={classes.div}>
            <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.title}
            >
                <Grid item>
                    <Typography variant="h4" className={classes.titleText}>
                        Organisaties
                    </Typography>
                </Grid>
            </Grid>
            <OrganisationTable />
        </Grid>
    );
};

export default OrganisationManagement;
