import React, { useState } from "react";
import { Paper, Grid } from "@material-ui/core";
import {
    DailyAverage,
    DailyAverageGraphData,
    emptyGraphObject,
} from "types/patientdata";
import {
    ChartType,
    PatientOverviewBarChartProps,
    SetChartType,
} from "./PatientOverviewBarChart.types";
import styles from "./PatientOverviewBarChart.styles";
import {
    PatientOverviewBarChartBody,
    PatientOverviewBarChartFooter,
    PatientOverviewBarChartHeader,
} from "./PatientOverviewBarChart.components";

const PatientOverviewBarChart: React.FC<PatientOverviewBarChartProps> = ({
    patientName,
    dates,
    dailyAverages,
    dailyAverageGraphData,
    handleDateChange,
    filters,
    handleFilterChange,
    handleWeekChange,
    loadingData,
}) => {
    const classes = styles({});
    const [chartType, setChartType] = useState<ChartType>("scatter");
    const handleChartType: SetChartType = (event, newChartType) => {
        if (newChartType !== null) setChartType(newChartType);
    };
    const filteredDailyAverages: DailyAverage[] | null =
        dailyAverages &&
        dailyAverages?.map((dailyAverage) => {
            const result: DailyAverage = {
                date: dailyAverage.date,
                data: {},
            };
            for (const property in filters.average) {
                if (filters.average[property]) {
                    result.data[property] = dailyAverage.data[property];
                }
            }
            return result;
        });
    const legendFilteredData: DailyAverageGraphData | undefined = {
        heart_rate_nonin_average: emptyGraphObject,
        spo2_minimum_average: emptyGraphObject,
        spo2_average_average: emptyGraphObject,
        hrv_average: emptyGraphObject,
        resting_heart_rate: emptyGraphObject,
        total_sleeptime_fitbit: emptyGraphObject,
        temperature_difference: emptyGraphObject,
        breathing_rate: emptyGraphObject,
        weight: emptyGraphObject,
        total_activity_fitbit: emptyGraphObject,
    };
    if (dailyAverageGraphData) {
        Object.keys(dailyAverageGraphData).map((key) => {
            if (filters.average[key] === true) {
                legendFilteredData[key] = dailyAverageGraphData[key];
                return true;
            }
            return false;
        });
    }

    return (
        <Paper className={classes.paper}>
            <Grid container>
                <PatientOverviewBarChartHeader
                    dates={dates}
                    handleDateChange={handleDateChange}
                    patientName={patientName}
                    handleWeekChange={handleWeekChange}
                    handleChartType={handleChartType}
                    chartType={chartType}
                />
                <PatientOverviewBarChartBody
                    loadingData={loadingData}
                    dailyAverages={filteredDailyAverages}
                    dailyAverageGraphData={legendFilteredData}
                    filters={filters}
                    chartType={chartType}
                />
                <PatientOverviewBarChartFooter
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                />
            </Grid>
        </Paper>
    );
};

export default PatientOverviewBarChart;
