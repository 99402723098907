enum UserActions {
    Login = "Login",
    LoginError = "LoginError",
    SetCurrentOrganisation = "SetCurrentOrganisation",
    Logout = "Logout",
    GetManageableUsers = "GetManageableUsers",
    GetOrganisations = "GetOrganisations",
}

export default UserActions;
