import APIResult from "./apiresult";
import RawResponse from "./rawresponse";

const payloadChecker = <T>(rawResponse: RawResponse<T>): APIResult<T> => {
    try {
        if (!rawResponse.success || rawResponse.error) {
            return {
                error: rawResponse.error || "No error specified",
            };
        }
        return {
            data: rawResponse.data as T,
        };
    } catch (error) {
        return { error };
    }
};

export default payloadChecker;
