import React, { useState } from "react";
import {
    TextField,
    InputAdornment,
    Typography,
    Grid,
    Button,
    CircularProgress,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    dateStringToLocaleDateString,
    dateToDayMonthYearString,
} from "helpers/dateToString";
import {
    ChevronLeft,
    ChevronRight,
    EventOutlined,
    TodayOutlined,
    ShowChart,
    BarChart as BarChartIcon,
} from "@material-ui/icons";
import { Bar, Scatter } from "react-chartjs-2";
import {
    getAverageGraphYLabels,
    getDataLabels,
    getDataSet,
    getYAxesLabels,
} from "helpers/barchartdata";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { minutesToHourMinuteString } from "helpers/time";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
    BarChartProps,
    DateBooleans,
    GraphProps,
    PatientOverviewBarChartBodyProps,
    PatientOverviewBarChartFooterProps,
    PatientOverviewBarChartHeaderProps,
} from "./PatientOverviewBarChart.types";
import styles from "./PatientOverviewBarChart.styles";
import ValuesFilterFormControl from "../ValuesFilterFormControl/ValuesFilterFormControl";
import { getAverageGraphDataSet } from "../../helpers/graphdata";

export const PatientOverviewBarChartHeader: React.FC<PatientOverviewBarChartHeaderProps> =
    ({
        patientName,
        dates,
        handleDateChange,
        handleWeekChange,
        handleChartType,
        chartType,
    }) => {
        const classes = styles({});
        const [isDatePickerOpen, setIsDatePickerOpen] = useState<DateBooleans>({
            startDate: false,
            endDate: false,
        });
        const openDatePicker = (props: keyof DateBooleans) => {
            setIsDatePickerOpen({
                ...isDatePickerOpen,
                [props]: true,
            });
        };
        const closeDatePicker = (props: keyof DateBooleans) => {
            setIsDatePickerOpen({
                ...isDatePickerOpen,
                [props]: false,
            });
        };
        return (
            <Grid
                container
                className={classes.tableheader}
                alignItems="center"
                justify="space-between"
            >
                <Grid item className={classes.tableheaderCell}>
                    <MuiPickersUtilsProvider
                        utils={MomentUtils}
                        libInstance={moment}
                    >
                        <DatePicker
                            open={isDatePickerOpen.startDate}
                            onClose={() => closeDatePicker("startDate")}
                            value={dates.startDate}
                            onChange={(date) =>
                                handleDateChange(date, "startDate")
                            }
                            format="dd/MM/yyyy"
                            TextFieldComponent={() => (
                                <TextField
                                    className={classes.datePickerTextField}
                                    onClick={() => openDatePicker("startDate")}
                                    value={dateToDayMonthYearString(
                                        dates.startDate,
                                        "DD/MM/YY"
                                    )}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <TodayOutlined />
                                            </InputAdornment>
                                        ),
                                        disableUnderline: true,
                                        type: "button",
                                    }}
                                />
                            )}
                        />
                        <DatePicker
                            open={isDatePickerOpen.endDate}
                            onClose={() => closeDatePicker("endDate")}
                            value={dates.endDate}
                            onChange={(date) =>
                                handleDateChange(date, "endDate")
                            }
                            format="dd/MM/yyyy"
                            TextFieldComponent={() => (
                                <TextField
                                    className={classes.datePickerTextField}
                                    onClick={() => openDatePicker("endDate")}
                                    value={dateToDayMonthYearString(
                                        dates.endDate,
                                        "DD/MM/YY"
                                    )}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EventOutlined />
                                            </InputAdornment>
                                        ),
                                        disableUnderline: true,
                                        type: "button",
                                    }}
                                />
                            )}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item className={classes.tableheaderCell}>
                    <Typography className={classes.titleText}>
                        Gemiddelde waardes {patientName}
                    </Typography>
                </Grid>
                <Grid item className={classes.tableheaderCell}>
                    <ToggleButtonGroup
                        value={chartType}
                        exclusive
                        onChange={handleChartType}
                        aria-label="text alignment"
                    >
                        <ToggleButton value="scatter" aria-label="left aligned">
                            <ShowChart />
                        </ToggleButton>
                        <ToggleButton value="bar" aria-label="right aligned">
                            <BarChartIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item className={classes.tableheaderCell}>
                    <Button
                        className={classes.headerButton}
                        startIcon={<ChevronLeft />}
                        onClick={() => handleWeekChange(true)}
                    >
                        Vorige week
                    </Button>
                    <Button
                        className={classes.headerButton}
                        endIcon={<ChevronRight />}
                        onClick={() => handleWeekChange(false)}
                    >
                        Volgende week
                    </Button>
                </Grid>
            </Grid>
        );
    };

const BarChart: React.FC<BarChartProps> = ({ dailyAverages, filters }) =>
    dailyAverages ? (
        <Bar
            data={{
                labels: getDataLabels(dailyAverages),
                datasets: getDataSet(dailyAverages, filters),
            }}
            options={{
                maintainAspectRatio: false,
                tooltips: {
                    callbacks: {
                        label(tooltipItem, data) {
                            let label = "undefined";
                            const found = data.datasets.find((dataset) =>
                                dataset.data.find(
                                    (point) =>
                                        dateStringToLocaleDateString(
                                            point.x
                                        ) === tooltipItem.xLabel &&
                                        point.y === tooltipItem.yLabel
                                )
                            );
                            if (found !== undefined) {
                                label = found.label;
                            }
                            const y = tooltipItem.yLabel;
                            switch (label) {
                                case "Totale slaapduur":
                                    return `${label}: ${minutesToHourMinuteString(
                                        y,
                                        false
                                    )}`;
                                case "Slaap efficientie":
                                    return `${label}: ${y}%`;
                                case "Gewicht":
                                    return `${label}: ${y}kg`;
                                case "Temp verschil":
                                    return `${label}: ${y}°C`;
                                case "HRV":
                                    return `${label}: ${y} ms`;
                                case "Ademhaling":
                                    return `${label}: ${y} brpm`;
                                case "Vet":
                                    return `${label}: ${y}%`;
                                default:
                                    return `${label}:  ${y}`;
                            }
                        },
                    },
                },
                scales: {
                    xAxes: [
                        {
                            stacked: false,
                            offset: true,
                        },
                    ],
                    yAxes: getYAxesLabels(dailyAverages, filters),
                },
                responsive: true,
                legend: {
                    display: false,
                },
                plugins: {
                    labels: {
                        render: "value",
                    },
                },
            }}
        />
    ) : (
        <Typography>Geen data aanwezig voor deze datums</Typography>
    );

export const Graph: React.FC<GraphProps> = ({
    dailyAverageGraphData,
    filters,
}) =>
    dailyAverageGraphData ? (
        <Scatter
            data={getAverageGraphDataSet(dailyAverageGraphData)}
            options={{
                maintainAspectRatio: false,
                animation: {
                    duration: 0, // general animation time
                },
                hover: {
                    animationDuration: 0, // duration of animations when hovering an item
                },
                responsiveAnimationDuration: 0, // animation duration after a resize
                responsive: true,
                legend: {
                    display: false,
                },
                tooltips: {
                    callbacks: {
                        label(tooltipItem, data) {
                            let label = "undefined";
                            const found = data.datasets.find((dataset) =>
                                dataset.data.find(
                                    (point) =>
                                        point.x === tooltipItem.xLabel &&
                                        point.y === tooltipItem.yLabel
                                )
                            );
                            if (found !== undefined) {
                                label = found.label;
                            }
                            const y = tooltipItem.yLabel;
                            const date = tooltipItem.xLabel;

                            switch (label) {
                                case "Totale Slaapduur":
                                    return `${label}\n(${dateStringToLocaleDateString(
                                        date
                                    )}, ${minutesToHourMinuteString(
                                        y,
                                        false
                                    )})`;
                                case "Gewicht":
                                    return `${label}\n(${dateStringToLocaleDateString(
                                        date
                                    )}, ${y}kg)`;
                                case "Temp verschil":
                                    return `${label}\n(${dateStringToLocaleDateString(
                                        date
                                    )}, ${y}℃)`;
                                case "HRV":
                                    return `${label}\n(${dateStringToLocaleDateString(
                                        date
                                    )}, ${y} ms)`;
                                case "HR in rust":
                                    return `${label}\n(${dateStringToLocaleDateString(
                                        date
                                    )}, ${y} bpm)`;
                                case "Heart rate nonin":
                                    return `${label}\n(${dateStringToLocaleDateString(
                                        date
                                    )}, ${y} bpm)`;
                                case "SpO2 min":
                                    return `${label}\n(${dateStringToLocaleDateString(
                                        date
                                    )}, ${y}%)`;
                                case "SpO2 gem":
                                    return `${label}\n(${dateStringToLocaleDateString(
                                        date
                                    )}, ${y}%)`;
                                case "Vet":
                                    return `${label}\n(${dateStringToLocaleDateString(
                                        date
                                    )}, ${y}%)`;
                                case "Ademhaling":
                                    return `${label}\n(${dateStringToLocaleDateString(
                                        date
                                    )}, ${y} brpm)`;
                                default:
                                    return `${label}\n(${dateStringToLocaleDateString(
                                        date
                                    )}, ${y})`;
                            }
                        },
                    },
                },
                scales: {
                    xAxes: [
                        {
                            type: "time",
                            stacked: false,
                            time: {
                                unit: "day",
                            },
                            gridLines: {
                                zeroLineColor: "transparent",
                            },
                        },
                    ],
                    yAxes: getAverageGraphYLabels(
                        dailyAverageGraphData,
                        filters
                    ),
                },
            }}
        />
    ) : (
        <Typography>Geen data aanwezig voor deze datums</Typography>
    );
export const PatientOverviewBarChartBody: React.FC<PatientOverviewBarChartBodyProps> =
    ({
        dailyAverages,
        filters,
        loadingData,
        dailyAverageGraphData,
        chartType,
    }) => {
        const classes = styles({ empty: !dailyAverages });
        const Chart = () =>
            chartType === "scatter" ? (
                <Graph
                    dailyAverageGraphData={dailyAverageGraphData}
                    filters={filters}
                />
            ) : (
                <BarChart dailyAverages={dailyAverages} filters={filters} />
            );

        if (loadingData) {
            return (
                <Grid
                    container
                    className={classes.barchartContainer}
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <CircularProgress />
                </Grid>
            );
        }

        return (
            <Grid container className={classes.barchartContainer}>
                <Chart />
            </Grid>
        );
    };

export const PatientOverviewBarChartFooter: React.FC<PatientOverviewBarChartFooterProps> =
    ({ filters, handleFilterChange }) => {
        const classes = styles({});

        return (
            <Grid container alignItems="flex-end" direction="column">
                <Grid item className={classes.tableFooterCell}>
                    <ValuesFilterFormControl
                        value="temperature_difference"
                        type="average"
                        filters={filters}
                        onChange={handleFilterChange}
                    />
                    <ValuesFilterFormControl
                        value="breathing_rate"
                        type="average"
                        filters={filters}
                        onChange={handleFilterChange}
                    />
                    <ValuesFilterFormControl
                        value="total_sleeptime_fitbit"
                        type="average"
                        filters={filters}
                        onChange={handleFilterChange}
                    />
                    <ValuesFilterFormControl
                        value="weight"
                        type="average"
                        filters={filters}
                        onChange={handleFilterChange}
                    />
                    <ValuesFilterFormControl
                        value="total_activity_fitbit"
                        type="average"
                        filters={filters}
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item className={classes.tableFooterCell}>
                    <Typography
                        variant="caption"
                        className={classes.footcaption}
                    >
                        Gemiddelde waardes per dag tussen 22:00 en 6:00
                    </Typography>
                    <ValuesFilterFormControl
                        value="resting_heart_rate"
                        type="average"
                        filters={filters}
                        onChange={handleFilterChange}
                    />
                    <ValuesFilterFormControl
                        value="hrv_average"
                        type="average"
                        filters={filters}
                        onChange={handleFilterChange}
                    />
                    <ValuesFilterFormControl
                        value="heart_rate_nonin_average"
                        filters={filters}
                        onChange={handleFilterChange}
                        type="average"
                    />
                    <ValuesFilterFormControl
                        value="spo2_average_average"
                        type="average"
                        filters={filters}
                        onChange={handleFilterChange}
                    />
                    <ValuesFilterFormControl
                        value="spo2_minimum_average"
                        type="average"
                        filters={filters}
                        onChange={handleFilterChange}
                    />
                </Grid>
            </Grid>
        );
    };
