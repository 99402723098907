import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Dialog,
    DialogContent,
    Grid,
    Paper,
    Table,
    TableContainer,
    Typography,
} from "@material-ui/core";
import { ManageableUser } from "types/user";
import { HighlightOff } from "@material-ui/icons";
import ReduxState from "store/store.types";
import { getManageableUsers, deleteUser } from "store/user/user.actions";
import { UserTableProps } from "./UserTable.types";
import styles from "./UserTable.styles";

import {
    UserTableBody,
    UserTableFooter,
    UserTableHeader,
} from "./UserTable.components";
import RoundedButton from "../RoundedButton/RoundedButton";

const clearMangaebleUser = { user_name: "", email: "" };

const UserTable: React.FC<UserTableProps> = ({
    getManageableUsers,
    deleteUser,
    manageableUsers,
}) => {
    const classes = styles();
    const [popup, setPopup] = useState(false);
    const [manageableUser, setManageableUser] =
        useState<{ user_name: string; email: string }>(clearMangaebleUser);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const [sortingConfig, setSortingConfig] = useState<{
        sorting: keyof ManageableUser;
        sortingOrder: boolean;
    }>({ sorting: "username", sortingOrder: true });
    const handlePopup = (
        value: boolean,
        mUser: { user_name: string; email: string }
    ) => {
        setPopup(value);
        setManageableUser(mUser);
    };
    const deleteUserAndClosePopup = async (email: string) => {
        try {
            await deleteUser({ email });
            handlePopup(false, clearMangaebleUser);
        } catch (e) {
            console.error(e);
        }
    };

    const changeSorting = (item: keyof ManageableUser) => {
        if (sortingConfig.sorting === item) {
            setSortingConfig({
                ...sortingConfig,
                sortingOrder: !sortingConfig.sortingOrder,
            });
        } else {
            setSortingConfig({
                sorting: item,
                sortingOrder: true,
            });
        }
    };

    const compare = (
        a: ManageableUser,
        b: ManageableUser,
        key: keyof ManageableUser
    ) => {
        if (key === "lastActive") {
            if (a.lastActive && b.lastActive) {
                return Date.parse(b.lastActive) - Date.parse(a.lastActive);
            }
            if (b.lastActive) {
                return 1;
            }
            if (a.lastActive) {
                return -1;
            }
            return 0;
        }
        if (key === "username") {
            if (a.username < b.username) return -1;
            if (a.username > b.username) return 1;
            return 0;
        }
        if (key === "organisation") {
            if (a.organisation < b.organisation) return -1;
            if (a.organisation > b.organisation) return 1;
            return 0;
        }
        return 0;
    };

    const filteredUsers = manageableUsers.filter(
        (manageableUser) =>
            manageableUser.username
                .toUpperCase()
                .includes(search.toUpperCase()) ||
            manageableUser.email.toUpperCase().includes(search.toUpperCase())
    );

    const sortedUsers = filteredUsers.sort((a, b) =>
        compare(a, b, sortingConfig.sorting)
    );
    if (!sortingConfig.sortingOrder) {
        sortedUsers.reverse();
    }

    const handlePageChange = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        getManageableUsers();
        setPage(0);
    }, [getManageableUsers]);

    return (
        <Paper>
            <TableContainer>
                <Table>
                    <UserTableHeader
                        changeSorting={changeSorting}
                        setSearch={setSearch}
                        search={search}
                    />
                    <UserTableBody
                        manageableUsers={sortedUsers}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handlePopup={handlePopup}
                    />
                    <UserTableFooter
                        manageableUsers={sortedUsers}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handlePageChange={handlePageChange}
                    />
                </Table>
            </TableContainer>
            <Dialog
                open={popup}
                onClose={() => handlePopup(false, clearMangaebleUser)}
            >
                <DialogContent className={classes.popup}>
                    <div className={classes.topContainer}>
                        <Grid container justify="center">
                            <Grid item>
                                <HighlightOff className={classes.formIcon} />
                            </Grid>
                        </Grid>

                        <Typography className={classes.formTitle} variant="h4">
                            Verwijder gebruiker?
                        </Typography>
                        <Typography className={classes.formText}>
                            Weet je zeker dat je gebruiker{" "}
                            <b>&quot;{manageableUser.user_name}&quot;</b> wilt
                            verwijderen?
                        </Typography>
                    </div>

                    <Grid
                        className={classes.formContainer}
                        container
                        justify="center"
                    >
                        <Grid item>
                            <RoundedButton
                                text="Verwijder"
                                onClick={() =>
                                    deleteUserAndClosePopup(
                                        manageableUser.email
                                    )
                                }
                                color="red"
                                margin="center"
                            />
                        </Grid>
                        <Grid item>
                            <RoundedButton
                                text="Annuleer"
                                onClick={() =>
                                    handlePopup(false, clearMangaebleUser)
                                }
                                color="grey"
                                margin="center"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Paper>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    manageableUsers: state.user.manageableUsers,
});
const mapDispatchToProps = {
    getManageableUsers,
    deleteUser,
};
const ConnectedUserTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserTable);

export default ConnectedUserTable;
