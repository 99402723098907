import React from "react";
import { Grid, Paper } from "@material-ui/core";
import LoginLogo from "components/LoginLogo/LoginLogo";
import ResetPasswordForm from "components/ResetPasswordForm/ResetPasswordForm";
import styles from "./ResetPassword.styles";

const ResetPassword: React.FC = () => {
    const classes = styles();
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.grid}
        >
            <Paper className={classes.paper} elevation={7}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <Grid item>
                        <LoginLogo text="Reset Wachtwoord" />
                    </Grid>
                    <Grid item>
                        <ResetPasswordForm />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default ResetPassword;
