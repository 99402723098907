import React from "react";
import { Grid, Paper } from "@material-ui/core";
import styles from "./ChangePassword.styles";
import LoginLogo from "../../components/LoginLogo/LoginLogo";
import NewPasswordForm from "../../components/NewPasswordForm/NewPasswordForm";

const ChangePassword: React.FC = () => {
    const classes = styles();
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.grid}
        >
            <Paper className={classes.paper} elevation={7}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <Grid item>
                        <LoginLogo text="Stel nieuw wachtwoord in" />
                    </Grid>
                    <Grid item>
                        <NewPasswordForm />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default ChangePassword;
