import React from "react";
import { Grid } from "@material-ui/core";
import dawnySunImage from "static/logo_just_sun.svg";
import Typography from "@material-ui/core/Typography";
import styles from "./LoginLogo.styles";
import { LoginLogoProps } from "./LoginLogo.types";

const LoginLogo: React.FC<LoginLogoProps> = ({ text }) => {
    const classes = styles();
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.logo}
        >
            <Grid item>
                <img
                    className={classes.logoImage}
                    src={dawnySunImage}
                    alt="Dawny Logo"
                />
            </Grid>
            <Grid item>
                <Typography variant="h6" className={classes.logoText}>
                    {text}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default LoginLogo;
