import { guard } from "decoders";
import { Decoder } from "decoders/types";

const jsonCheck = <T>(data: T, decoder: Decoder<T>) => {
    const decodeChecker = guard(decoder);
    // TODO: Allow underscore to be not used
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const _ = decodeChecker(data); // Throws if it's not valid
};

export default jsonCheck;
