import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { NoninRealTimeGraphProps } from "./NoninRealTimeGraph.types";
import { emptyGraphObject, RealTimeNoninData } from "../../types/patientdata";
import styles from "./NoninRealTimeGraph.styles";
import {
    NoninRealTimeGraphBody,
    NoninRealTimeGraphFooter,
    NoninRealTimeGraphHeader,
} from "./NoninRealTimeGraph.components";
import {
    getMaxHeartRateFromData,
    getMaxSpO2FromData,
    getMinHeartRateFromData,
    getMinSpO2FromData,
} from "../../helpers/graphdata";

const NoninRealTimeGraph: React.FC<NoninRealTimeGraphProps> = ({
    patientName,
    handleFilterChange,
    filters,
    realTimeNoninData,
    handleInputChange,
    timeWindow,
}) => {
    const classes = styles({});
    const legendFilterData: RealTimeNoninData | undefined = {
        heart_rate_nonin_values: emptyGraphObject,
        spo2_values: emptyGraphObject,
        hrv_values: emptyGraphObject,
        pai_values: emptyGraphObject,
    };
    if (realTimeNoninData) {
        Object.keys(realTimeNoninData).map((key) => {
            if (filters.realTime[key] === true) {
                legendFilterData[key] = realTimeNoninData[key];
                return true;
            }
            return false;
        });
    }
    const minHR = getMinHeartRateFromData(
        5,
        legendFilterData.heart_rate_nonin_values.data,
        undefined
    );
    const maxHR = getMaxHeartRateFromData(
        5,
        legendFilterData.heart_rate_nonin_values.data,
        undefined
    );
    const minSpO2 = getMinSpO2FromData(
        5,
        legendFilterData.spo2_values.data,
        undefined
    );
    const maxSpO2 = getMaxSpO2FromData(
        5,
        legendFilterData.spo2_values.data,
        undefined
    );
    return (
        <Paper className={classes.paper}>
            <Grid container>
                <NoninRealTimeGraphHeader
                    patientName={patientName}
                    handleInputChange={handleInputChange}
                    timeWindow={timeWindow}
                />
                <NoninRealTimeGraphBody
                    legendFilteredData={legendFilterData}
                    minHR={minHR}
                    maxHR={maxHR}
                    minSpO2={minSpO2}
                    maxSpO2={maxSpO2}
                    timeWindow={timeWindow}
                />
                <NoninRealTimeGraphFooter
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                />
            </Grid>
        </Paper>
    );
};

export default NoninRealTimeGraph;
