import Config from "api/types/Config";
import {
    DeleteUserRequest,
    DeleteUserResponse,
    GetManageableUsersResponse,
    GetManageableUsersRequest,
    CreateUserRequest,
    CreateUserResponse,
    CreateOrganisationRequest,
    CreateOrganisationResponse,
    GetOrganisationsResponse,
    ArchiveOrganisationRequest,
    ArchiveOrganisationResponse,
    RenameOrganisationRequest,
    RenameOrganisationResponse,
    ResetPasswordCompleteRequest,
    ResetPasswordCompleteResponse,
    ResetPasswordStartRequest,
    ResetPasswordStartResponse,
    CronSettingsResponse,
    RemoveWeightAlarmRequest,
    RemoveWeightAlarmResponse,
} from "../types/User";
import requireJWT from "../internal/requirejwt";
import jsonCheck from "../internal/jsoncheck";
import {
    createUserRequestDecoder,
    createUserResponseDecoder,
    deleteUserRequestDecoder,
    deleteUserResponseDecoder,
    getManageableUsersResponseDecoder,
    getManageableUsersRequestDecoder,
    createOrganisationRequestDecoder,
    createOrganisationResponseDecoder,
    getOrganisationsResponseDecoder,
    archiveOrganisationRequestDecoder,
    archiveOrganisationResponseDecoder,
    renameOrganisationRequestDecoder,
    renameOrganisationResponseDecoder,
    resetPasswordCompleteRequestDecoder,
    resetPasswordCompleteResponseDecoder,
    resetPasswordStartRequestDecoder,
    resetPasswordStartResponseDecoder,
    cronSettingsResponseDecoder,
    removeWeightAlarmResponseDecoder,
} from "../internal/decoders";
import { get, post } from "../internal/calls";
import { GetEndpoints, PostEndpoints } from "../internal/endpoints";
import throwOrReturn from "../internal/throworreturn";

const getManageableUsers = async (
    config: Config,
    manageableUsersData: GetManageableUsersRequest
): Promise<GetManageableUsersResponse> => {
    requireJWT(config);
    jsonCheck(manageableUsersData, getManageableUsersRequestDecoder);
    const response = await post<
        GetManageableUsersRequest,
        GetManageableUsersResponse
    >(config, PostEndpoints.ManageableUsers, manageableUsersData);
    return throwOrReturn(response, getManageableUsersResponseDecoder);
};

const createUser = async (
    config: Config,
    createUserData: CreateUserRequest
): Promise<CreateUserResponse> => {
    requireJWT(config);
    jsonCheck(createUserData, createUserRequestDecoder);
    const response = await post<CreateUserRequest, CreateUserResponse>(
        config,
        PostEndpoints.CreateUser,
        createUserData
    );
    return throwOrReturn(response, createUserResponseDecoder);
};

const deleteUser = async (
    config: Config,
    deleteUserData: DeleteUserRequest
): Promise<DeleteUserResponse> => {
    requireJWT(config);
    jsonCheck(deleteUserData, deleteUserRequestDecoder);
    const response = await post<DeleteUserRequest, DeleteUserResponse>(
        config,
        PostEndpoints.DeleteUser,
        deleteUserData
    );
    return throwOrReturn(response, deleteUserResponseDecoder);
};

const createOrganisation = async (
    config: Config,
    createOrganisationData: CreateOrganisationRequest
): Promise<CreateOrganisationResponse> => {
    requireJWT(config);
    jsonCheck(createOrganisationData, createOrganisationRequestDecoder);
    const response = await post<
        CreateOrganisationRequest,
        CreateOrganisationResponse
    >(config, PostEndpoints.CreateOrganisation, createOrganisationData);
    return throwOrReturn(response, createOrganisationResponseDecoder);
};

const getOrganisations = async (
    config: Config
): Promise<GetOrganisationsResponse> => {
    requireJWT(config);
    const response = await get<GetOrganisationsResponse>(
        config,
        GetEndpoints.GetOrganisations
    );
    return throwOrReturn(response, getOrganisationsResponseDecoder);
};

const archiveOrganisation = async (
    config: Config,
    archiveOrganisationData: ArchiveOrganisationRequest
): Promise<ArchiveOrganisationResponse> => {
    requireJWT(config);
    jsonCheck(archiveOrganisationData, archiveOrganisationRequestDecoder);
    const response = await post<
        ArchiveOrganisationRequest,
        ArchiveOrganisationResponse
    >(config, PostEndpoints.ArchiveOrganisation, archiveOrganisationData);
    return throwOrReturn(response, archiveOrganisationResponseDecoder);
};

const renameOrganisation = async (
    config: Config,
    renameOrganisationData: RenameOrganisationRequest
): Promise<RenameOrganisationResponse> => {
    requireJWT(config);
    jsonCheck(renameOrganisationData, renameOrganisationRequestDecoder);
    const response = await post<
        RenameOrganisationRequest,
        RenameOrganisationResponse
    >(config, PostEndpoints.RenameOrganisation, renameOrganisationData);
    return throwOrReturn(response, renameOrganisationResponseDecoder);
};

const enableFitbit = async (
    config: Config,
    id: number
): Promise<RenameOrganisationResponse> => {
    requireJWT(config);
    const response = await post<
        {
            organisation_id: number;
        },
        RenameOrganisationResponse
    >(config, PostEndpoints.EnableFitbit, {
        organisation_id: id,
    });
    return throwOrReturn(response, renameOrganisationResponseDecoder);
};

const disableFitbit = async (
    config: Config,
    id: number
): Promise<RenameOrganisationResponse> => {
    requireJWT(config);
    const response = await post<
        {
            organisation_id: number;
        },
        RenameOrganisationResponse
    >(config, PostEndpoints.DisableFitbit, {
        organisation_id: id,
    });
    return throwOrReturn(response, renameOrganisationResponseDecoder);
};

const resetPasswordComplete = async (
    config: Config,
    resetPasswordCompleteData: ResetPasswordCompleteRequest
): Promise<ResetPasswordCompleteResponse> => {
    jsonCheck(resetPasswordCompleteData, resetPasswordCompleteRequestDecoder);
    const response = await post<
        ResetPasswordCompleteRequest,
        ResetPasswordCompleteResponse
    >(config, PostEndpoints.ResetPasswordComplete, resetPasswordCompleteData);
    return throwOrReturn(response, resetPasswordCompleteResponseDecoder);
};

const resetPasswordStart = async (
    config: Config,
    resetPasswordStartData: ResetPasswordStartRequest
): Promise<ResetPasswordStartResponse> => {
    jsonCheck(resetPasswordStartData, resetPasswordStartRequestDecoder);
    const response = await post<
        ResetPasswordStartRequest,
        ResetPasswordStartResponse
    >(config, PostEndpoints.ResetPasswordStart, resetPasswordStartData);
    return throwOrReturn(response, resetPasswordStartResponseDecoder);
};

const cronSettingsApiCall = async (
    config: Config
): Promise<CronSettingsResponse> => {
    requireJWT(config);
    const response = await get<CronSettingsResponse>(
        config,
        GetEndpoints.CronSettings
    );
    return throwOrReturn(response, cronSettingsResponseDecoder);
};

const removeWeightAlarm = async (
    config: Config,
    removeWeightAlarmRequest: RemoveWeightAlarmRequest
): Promise<RemoveWeightAlarmResponse> => {
    requireJWT(config);

    const response = await post<
        RemoveWeightAlarmRequest,
        RemoveWeightAlarmResponse
    >(config, PostEndpoints.RemoveWeightAlarm, removeWeightAlarmRequest);
    return throwOrReturn(response, removeWeightAlarmResponseDecoder);
};

export {
    getManageableUsers,
    createUser,
    deleteUser,
    createOrganisation,
    getOrganisations,
    archiveOrganisation,
    renameOrganisation,
    enableFitbit,
    disableFitbit,
    resetPasswordComplete,
    resetPasswordStart,
    cronSettingsApiCall,
    removeWeightAlarm,
};
