import React from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { NoninRealTimeTableProps } from "./NoninRealTimeTable.types";
import styles from "./NoninRealTimeTable.styles";
import DetailedTableRow from "../DetailedTableRow/DetailedTableRow";

const NoninRealTimeTable: React.FC<NoninRealTimeTableProps> = ({
    spO2Average,
    spO2Minimum,
    heartRateNoninAverage,
    spO2Dips,
}) => {
    const classes = styles();

    return (
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead className={classes.tableheader}>
                        <TableRow>
                            <TableCell
                                align="left"
                                className={classes.tableheaderCell}
                            >
                                <Typography className={classes.tableHeaderItem}>
                                    Stats
                                </Typography>
                            </TableCell>
                            <TableCell
                                align="left"
                                className={classes.tableheaderCell}
                            >
                                <Typography className={classes.tableHeaderItem}>
                                    Value
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {spO2Average || spO2Minimum || heartRateNoninAverage ? (
                            <>
                                <DetailedTableRow
                                    stat="SpO2 minimum"
                                    value={
                                        spO2Minimum
                                            ? `${spO2Minimum} %`
                                            : "Geen data beschikbaar"
                                    }
                                />
                                <DetailedTableRow
                                    stat="SpO2 aantal dips onder 88% (+)"
                                    value={`${spO2Dips.toFixed(
                                        2
                                    )} keer per uur`}
                                />
                                <DetailedTableRow
                                    stat="SpO2 gemiddeld"
                                    value={
                                        spO2Average
                                            ? `${spO2Average.toFixed(2)} %`
                                            : "Geen data beschikbaar"
                                    }
                                />
                                <DetailedTableRow
                                    stat="Hartslag gemiddeld Nonin"
                                    value={
                                        heartRateNoninAverage
                                            ? `${heartRateNoninAverage.toFixed(
                                                  2
                                              )} bpm`
                                            : "Geen data beschikbaar"
                                    }
                                />
                            </>
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={2}
                                    className={classes.tableCell}
                                >
                                    <Typography
                                        className={classes.tableCellItem}
                                    >
                                        Geen patientdata beschikbaar
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default NoninRealTimeTable;
