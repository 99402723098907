export interface LoginData {
    email: string;
    password: string;
}
// TODO: should be 'admin' | 'manager' | 'user' , Aegis doesnt support this (yet)
export enum Role {
    admin = "admin",
    manager = "manager",
    user = "user",
}
export interface User {
    isLoggedIn: boolean;
    organisations: Organisation[];
    role?: Role;
    email?: string;
    authToken?: string;
}

export interface Organisation {
    name: string;
    id: number;
    enable_fitbit: boolean;
}

export interface ManageableUser {
    username: string;
    email: string;
    role: Role;
    lastActive: string | null;
    organisation: string;
}
