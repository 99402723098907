import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    paper: {
        margin: "0 0 50px",
    },
    tableheader: {
        width: "100%",
        boxShadow: "inset 0px -1px 0px rgba(4, 66, 114, 0.16)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    titleText: {
        color: "#044272",
        fontWeight: 600,
    },
    datePickerTextField: {
        margin: "0 7px",
        width: "136px",
        height: "36px",
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
        "& .MuiInputBase-root": {
            color: "#044272",
            fontWeight: 600,
            padding: "2px 10px",
            cursor: "pointer",
        },
        "& .MuiInputBase-input": {
            cursor: "pointer",
        },
    },
    tableheaderCell: {
        padding: "10px 13px 10px 20px",
    },
    headerButton: {
        margin: "0 7px",
        width: "163px",
        height: "36px",
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        textTransform: "revert",
        color: "#044272",
        fontWeight: 600,
        fontSize: "16px",
        padding: "2px 10px",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
        "& .MuiButton-endIcon": {
            marginLeft: 0,
        },
    },
    timefilterContainer: {
        color: "#044272",
        padding: "10px 4px 10px 4px",
        border: "none",
    },
    barchartContainer: (props: { empty?: boolean }) => ({
        padding: "10px 20px",
        height: props.empty ? "none" : "calc(60vw/16*9)",
    }),
    tableFooterCell: {
        color: "#044272",
        padding: "10px 13px",
    },
    filterCheckBox: {
        background: "#F8F9FC",
        margin: "0 7px",
        padding: "5px 10px 5px 0",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        height: "36px",
    },
    checkboxLabel: {
        color: "#044272",
        fontWeight: 600,
    },
    footcaption: {
        color: "#044272",
    },
});
