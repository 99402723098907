import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, TextField, Typography } from "@material-ui/core";
import { resetPasswordStart } from "store/user/user.actions";
import { connect } from "react-redux";
import { ResetPasswordFormProps } from "./ResetPasswordForm.types";
import styles from "./ResetPasswordForm.styles";
import RoundedButton from "../RoundedButton/RoundedButton";

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
    resetPasswordStart,
}) => {
    const history = useHistory();
    const classes = styles();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const handleTextFieldChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setEmail(event.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            await resetPasswordStart({ email });
            setLoading(false);
            setSuccess(true);
            setError("");
        } catch {
            setLoading(false);
            setError("Email is niet gevonden");
        }
    };
    const backToLogin = () => history.push("/login");
    const resetPage = () => {
        setSuccess(false);
        setError("");
        setEmail("");
    };
    const isError = error !== "";
    const canSendForm = email !== "";
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.grid}
            spacing={success ? 2 : 0}
        >
            {success ? (
                <>
                    <Grid item>
                        <Typography className={classes.subtitle}>
                            We hebben de instructies naar U verstuurd. Bekijk je
                            inbox.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <RoundedButton
                            text="Terug naar Login"
                            onClick={backToLogin}
                            color="darkBlue"
                            margin="center"
                        />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.bottomText}>
                            Heb je geen email ontvangen? Wacht enkele minuten,
                            controleer je spam filter of{" "}
                            <Typography
                                onClick={resetPage}
                                className={classes.resetPageButton}
                            >
                                probeer een ander email adres
                            </Typography>
                        </Typography>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item>
                        <Typography className={classes.subtitle}>
                            Vul hieronder je email in die gekoppeld is aan Uw
                            account en we versturen U een email met instructies
                            om je wachtwoord te resetten
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="outlined-email"
                            label="Email"
                            variant="outlined"
                            value={email}
                            autoFocus
                            onChange={handleTextFieldChange}
                            className={classes.textField}
                            InputProps={{
                                className: classes.input,
                            }}
                            error={isError}
                            helperText={isError && error}
                        />
                    </Grid>
                    <Grid item>
                        <RoundedButton
                            text="Zend instructies"
                            onClick={handleSubmit}
                            color="darkBlue"
                            margin="center"
                            disabled={!canSendForm || loading}
                            loading={loading}
                        />
                    </Grid>
                    <Grid item className={classes.link} onClick={backToLogin}>
                        <Typography variant="subtitle1">
                            Terug naar Login
                        </Typography>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    resetPasswordStart,
};

const ConnectedResetPasswordForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordForm);

export default ConnectedResetPasswordForm;
