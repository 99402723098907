import { makeStyles } from "@material-ui/core/styles";

export const ButtonColorCatalogue = {
    darkBlue: {
        color: "#044272",
        boxShadow: "1px 1px 4px rgba(4, 66, 114, 0.16)",
        hover: "#36678e",
    },
    lightBlue: {
        color: "#00B2BD",
        boxShadow: "1px 1px 4px rgba(93, 160, 51, 0.16)",
        hover: "#007c84",
    },
    red: {
        color: "#DB303F",
        boxShadow: "1px 1px 4px rgba(93, 160, 51, 0.16)",
        hover: "#99212c",
    },
    grey: {
        color: "#B0BAC9",
        boxShadow: "1px 1px 4px rgba(4, 66, 114, 0.16)",
        hover: "#7b828c",
    },
};

export const MarginCatalogue = {
    left: "0 20px 0 0",
    center: "0 10px",
    right: "0 0 0 20px",
    none: "0",
    smallLeft: "0 0 0 10px",
};

export default makeStyles({
    root: (props: {
        color: string;
        boxShadow: string;
        hover: string;
        position: string;
        padding: string;
    }) => ({
        backgroundColor: props.color,
        color: "#fff",
        borderRadius: "30px",
        boxSizing: "border-box",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        boxShadow: props.boxShadow,
        textTransform: "revert",
        margin: props.position,
        height: "40px",
        minWidth: "40px",
        fontSize: "18px",
        padding: props.padding,
        "&:hover": {
            backgroundColor: props.hover,
        },
    }),
    disabledButton: {
        color: "#fff!important",
        backgroundColor: "#E5E5E5!important",
    },
});
