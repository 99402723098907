import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    paper: {
        margin: "0 0 50px",
    },
    tableheader: {
        width: "100%",
    },
    text: {
        color: "#044272",
        fontWeight: 400,
    },
    tableheaderCell: {
        padding: "10px 60px",
    },
    tableFooterCell: {
        color: "#044272",
        padding: "10px 4px 10px 10px",
        border: "none",
    },
    filterCheckBox: {
        background: "#F8F9FC",
        margin: "0 7px",
        padding: "5px 10px 5px 0",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        height: "36px",
    },
    checkboxLabel: {
        color: "#044272",
        fontWeight: 600,
    },
    graphContainer: (props: { empty?: boolean }) => ({
        padding: "10px 20px",
        height: props.empty ? "none" : "calc(60vw/16*9)",
    }),
    topFilterContainer: {
        color: "#044272",
        padding: "10px 10px",
        border: "none",
    },
    bottomFilterContainer: {
        color: "#044272",
        padding: "10px 4px 10px 0px",
        border: "none",
    },
    timeWindowSelector: {
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        color: "#044272",
        fontWeight: 600,
        fontSize: "16px",
        padding: "2px 5px",
        marginLeft: "5px",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
    },
    headerButton: {
        margin: "0 7px",
        width: "163px",
        height: "36px",
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        textTransform: "revert",
        color: "#044272",
        fontWeight: 600,
        fontSize: "16px",
        padding: "2px 10px",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
        "& .MuiButton-endIcon": {
            marginLeft: 0,
        },
    },
});
