import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    tableCellItem: {
        size: "16px",
        lineHeight: "32px",
        alignItems: "center",
        color: "#044272",
    },
    tableCell: { padding: "6px 12px", border: "none" },
});
