import React, { useState } from "react";
import { Grid, Input, MenuItem, Select, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import styles from "./PatientNightDetailsTable.styles";
import {
    HeartRateLimitCellProps,
    HeartRateLimitTypeSelectorProps,
    HeartRateLimitValuesSelectorProps,
} from "./PatientNightDetailsTable.types";

const HeartRateLimitTypeSelector: React.FC<HeartRateLimitTypeSelectorProps> = ({
    hrDipData,
    changeHeartRateDipData,
}) => {
    const classes = styles();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <Grid item>
            <Select
                value={hrDipData.type}
                name="type"
                onChange={changeHeartRateDipData}
                className={classes.limitTypeSelector}
                IconComponent={() => <ExpandMore onClick={handleOpen} />}
                disableUnderline
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
            >
                <MenuItem value="heart_rate_fitbit_values">HR Fitbit</MenuItem>
                <MenuItem value="heart_rate_nonin_values">HR Nonin</MenuItem>
            </Select>
        </Grid>
    );
};

const HearRateLimitValuesSelector: React.FC<HeartRateLimitValuesSelectorProps> =
    ({ hrDipData, handleInputChange, type, handleBlur }) => {
        const classes = styles();
        return (
            <Grid item>
                <Input
                    className={classes.limitNumberSelector}
                    value={hrDipData[type]}
                    onChange={(event) =>
                        handleInputChange(event, type === "upperLimit")
                    }
                    onBlur={handleBlur}
                    inputProps={{
                        step: 1,
                        min: 0,
                        max: 300,
                        type: "number",
                        style: {
                            textAlign: "center",
                            width: "50px",
                        },
                    }}
                    disableUnderline
                />
            </Grid>
        );
    };

export const HeartRateLimitCell: React.FC<HeartRateLimitCellProps> = ({
    handleBlur,
    changeHeartRateDipData,
    hrDipData,
    handleInputChange,
}) => (
    <Grid container alignItems="center">
        <HeartRateLimitTypeSelector
            hrDipData={hrDipData}
            changeHeartRateDipData={changeHeartRateDipData}
        />
        <Grid item>
            <Typography> aantal dips boven </Typography>
        </Grid>
        <HearRateLimitValuesSelector
            hrDipData={hrDipData}
            handleInputChange={handleInputChange}
            type="upperLimit"
            handleBlur={handleBlur}
        />
        <Grid item>
            <Typography> en onder </Typography>
        </Grid>
        <HearRateLimitValuesSelector
            hrDipData={hrDipData}
            handleInputChange={handleInputChange}
            type="lowerLimit"
            handleBlur={handleBlur}
        />
    </Grid>
);
