import {
    Button,
    Dialog,
    DialogContent,
    Divider,
    Menu,
    MenuItem,
    TableCell,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { MoreHoriz } from "@material-ui/icons";
import { GetDailyAveragesRequest } from "api/types/Patient";
import { createPatientDailyAveragesCSV } from "helpers/createCsv";
import moment, { Moment } from "moment";
import { connect } from "react-redux";
import { Dates } from "screens/PatientHome/PatientHome.types";
import {
    archivePatient,
    changeName,
    changePassword,
    changePatientSetting,
    deletePatient,
    deletePatientSetting,
    getDailyAveragesOnReturn,
    getPatientSettingKeys,
    getPatientSettings,
} from "store/patient/patient.actions";

import { PatientOverview } from "types/patientdata";
import {
    CsvFooter,
    CsvForm,
    CsvHeader,
    PasswordChangeForm,
    PatientChangeNameForm,
    PatientSettingForm,
    PopupFooter,
    PopupHeader,
    RemovePatientForm,
} from "./TableOptionsMenu.components";

import {
    initialErrorValues,
    ShowPassword,
    TableOptionsMenuProps,
    TextFieldValues,
} from "./TableOptionsMenu.types";

import ReduxState from "../../store/store.types";
import styles from "./TableOptionsMenu.styles";

const TableOptionsMenu: React.FC<TableOptionsMenuProps> = ({
    patient,
    organisations,
    lastEntry,
    changePassword,
    changeName,
    getDailyAveragesOnReturn,
    deletePatient,
    getPatientSettings,
    settings,
    settingKeys,
    getPatientSettingKeys,
    deletePatientSetting,
    changePatientSetting,
}) => {
    const [popup, setPopup] = useState({ patient: false, csv: false });
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dates, setDates] = useState<Dates>({
        startDate: moment.utc().subtract(7, "days"),
        endDate: moment.utc(),
    });
    const handleDateChange = (date: Moment | null, props: keyof Dates) => {
        if (date !== null) {
            setDates({
                ...dates,
                [props]: date,
            });
        }
    };
    const [showPassword, setShowPassword] = useState({
        new: false,
        repeat: false,
    });
    const [textFieldValues, setTextFieldValues] = useState({
        name: patient.name,
        new: "",
        repeat: "",
        archive: "",
        delete: "",
    });
    const [errors, setErrors] = useState(initialErrorValues);
    const classes = styles({ selected: anchorEl });

    useEffect(() => {
        setTextFieldValues({
            ...textFieldValues,
            name: patient.name,
        });
        // eslint-disable-next-line
    }, [patient, patient.name]);

    const handleTextFieldChange =
        (prop: keyof TextFieldValues) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setTextFieldValues({
                ...textFieldValues,
                [prop]: event.target.value,
            });
        };
    const handleClickShowPassword = (prop: keyof ShowPassword) => {
        setShowPassword({ ...showPassword, [prop]: !showPassword[prop] });
    };
    const handlePopupClose = () => {
        setTextFieldValues({
            name: "",
            new: "",
            repeat: "",
            archive: "",
            delete: "",
        });
        setPopup({ patient: false, csv: false });
    };
    const handleClick = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => {
        setErrors({ ...initialErrorValues });
        setAnchorEl(null);
    };
    const handlePopupOpen = (
        type: keyof { patient: boolean; csv: boolean }
    ) => {
        handleClose();
        setPopup({ ...popup, [type]: true });
    };

    const downloadCsv = async () => {
        const dailyAveragesRequest: GetDailyAveragesRequest = {
            patient_name: patient.name,
            start_date: dates.startDate.format("YYYY-MM-DD"),
            end_date: dates.endDate.format("YYYY-MM-DD"),
        };
        setLoading(true);
        const dailyAverages = await getDailyAveragesOnReturn(
            dailyAveragesRequest
        );
        setLoading(false);
        createPatientDailyAveragesCSV(
            dailyAverages,
            patient.name,
            dates.startDate,
            dates.endDate
        );
        handlePopupClose();
    };

    const savePopupData = async () => {
        let close = true;
        if (
            textFieldValues.new !== "" &&
            textFieldValues.new === textFieldValues.repeat
        ) {
            await changePassword({
                id: patient.id,
                password: textFieldValues.new,
            });
        }
        if (
            textFieldValues.new !== "" &&
            textFieldValues.new !== textFieldValues.repeat
        ) {
            setErrors({
                ...initialErrorValues,
                repeat: "Beide wachtwoorden moeten hetzelfde zijn.",
            });
            close = false;
        }
        if (textFieldValues.name !== patient.name) {
            await changeName({
                id: patient.id,
                name: textFieldValues.name.trim(),
            });
        }
        if (close) {
            handlePopupClose();
        }
    };

    const deletePatientCall = () => {
        if (
            textFieldValues.delete.toLocaleLowerCase() ===
            patient.name.toLocaleLowerCase()
        ) {
            deletePatient({ user_name: patient.name }); // TODO: maybe add another popup for confirmation
            handlePopupClose();
        } else {
            setErrors({
                ...initialErrorValues,
                delete: "Vul hier de patientnaam in",
            });
        }
        handlePopupClose();
    };

    const checkEnabledFitbit = (patient: PatientOverview) => {
        const organisation = organisations.find(
            (x) => x.name === patient.organisation
        );
        if (!organisation) {
            return false;
        }

        return organisation.enable_fitbit;
    };

    return (
        <TableCell align="right" className={classes.tableCell}>
            <Button onClick={handleClick} className={classes.root}>
                <MoreHoriz className={classes.icon} />
            </Button>
            <Menu
                id="table-options-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem
                    onClick={() => {
                        handlePopupOpen("csv");
                    }}
                >
                    Download CSV
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handlePopupOpen("patient");
                    }}
                >
                    Beheer patient
                </MenuItem>
                <Dialog open={popup.csv} onClose={handlePopupClose}>
                    <DialogContent className={classes.popupCsv}>
                        <CsvHeader patient={patient.name} />
                        <CsvForm
                            handleDateChange={handleDateChange}
                            dates={dates}
                        />
                        <CsvFooter
                            createCsv={downloadCsv}
                            handlePopupClose={handlePopupClose}
                            loading={loading}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={popup.patient}
                    onClose={handlePopupClose}
                    maxWidth="md"
                >
                    <DialogContent className={classes.popup}>
                        <PopupHeader
                            enabledFitbit={checkEnabledFitbit(patient)}
                            patient={patient}
                            date={lastEntry}
                        />
                        <PatientSettingForm
                            patientName={patient.name}
                            settings={settings}
                            getPatientSettings={getPatientSettings}
                            settingKeys={settingKeys}
                            getPatientSettingKeys={getPatientSettingKeys}
                            deletePatientSetting={deletePatientSetting}
                            changePatientSetting={changePatientSetting}
                        />
                        <PatientChangeNameForm
                            name={textFieldValues}
                            handleTextfieldChange={handleTextFieldChange}
                            errors={errors}
                        />
                        <PasswordChangeForm
                            showPassword={showPassword}
                            password={textFieldValues}
                            handlePasswordChange={handleTextFieldChange}
                            handleClickShowPassword={handleClickShowPassword}
                            errors={errors}
                        />
                        <RemovePatientForm
                            textFieldValues={textFieldValues}
                            handleTextfieldChange={handleTextFieldChange}
                            prop="delete"
                            removePatient={deletePatientCall}
                            errors={errors}
                            title="Patient account verwijderen"
                            buttonText="Patient verwijderen"
                        />
                        <Divider />
                        <PopupFooter
                            savePopupData={savePopupData}
                            handlePopupClose={handlePopupClose}
                        />
                    </DialogContent>
                </Dialog>
            </Menu>
        </TableCell>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    settings: state.patient.settings,
    settingKeys: state.patient.settingKeys,
});
const mapDispatchToProps = {
    changePassword,
    changeName,
    archivePatient,
    deletePatient,
    getDailyAveragesOnReturn,
    getPatientSettings,
    getPatientSettingKeys,
    deletePatientSetting,
    changePatientSetting,
};
const ConnectedTableOptionsMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(TableOptionsMenu);
export default ConnectedTableOptionsMenu;
