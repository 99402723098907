import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    tableHeaderItem: {
        color: "#044272",
        fontWeight: 700,
        size: "16px",
        lineHeight: "32px",
        alignItems: "center",
        display: "flex",
    },
    tableheader: {
        height: "52px",
        width: "1160px",
        boxShadow: "inset 0px -1px 0px rgba(4, 66, 114, 0.16)",
    },
    tableheaderCell: { padding: "6px 12px" },
    tableCellItem: {
        size: "16px",
        lineHeight: "32px",
        alignItems: "center",
        color: "#044272",
    },
    tableCell: { padding: "6px 12px", border: "none" },
});
