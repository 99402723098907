import Config from "api/types/Config";
import config from "config";
import localStorageManager from "data/localStorageManager";

const getApiConfig = (): Config => ({
    baseURL: config.baseURL,
    jwt: localStorageManager.getJWT() || undefined,
});

export default getApiConfig;
