import React, { useState } from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    Divider,
    FilledInput,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Radio,
} from "@material-ui/core";
import dawnySunImage from "static/logo_just_sun.svg";
import Typography from "@material-ui/core/Typography";
import {
    ArrowDropDown,
    PeopleAlt,
    ExitToApp,
    PeopleAltOutlined,
    AddCircle,
    Apartment,
} from "@material-ui/icons";
import {
    LogoProps,
    OrganisationButtonProps,
    UserButtonProps,
} from "./Header.types";
import styles from "./Header.styles";
import RoundedButton from "../RoundedButton/RoundedButton";

export const Logo: React.FC<LogoProps> = ({ goToHome }) => {
    const classes = styles({});
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.logo}
            onClick={goToHome}
        >
            <Grid item>
                <img
                    className={classes.logoImage}
                    src={dawnySunImage}
                    alt="Dawny Logo"
                />
            </Grid>
            <Grid item>
                <Typography variant="h6" className={classes.logoText}>
                    Dawny
                </Typography>
            </Grid>
        </Grid>
    );
};

export const UserButton: React.FC<UserButtonProps> = ({
    email,
    logoutOfSite,
    role,
    goToManageUsers,
    goToManageOrganisations,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = styles({ selected: anchorEl });

    const handleClick = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const signOut = () => {
        handleClose();
        logoutOfSite();
    };
    const username = email?.split("@")[0];
    return (
        <div>
            <Button
                onMouseOver={handleClick}
                onClick={handleClick}
                className={classes.buttonRoot}
            >
                <Grid container direction="column" alignItems="flex-start">
                    <Grid item>
                        <Grid item>
                            <Typography className={classes.buttonTitle}>
                                User
                            </Typography>
                        </Grid>
                        <Typography className={classes.buttonText}>
                            {username}
                            <ArrowDropDown className={classes.arrowDropdown} />
                        </Typography>
                    </Grid>
                </Grid>
            </Button>
            <Menu
                id="table-options-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem disabled>
                    <Grid container direction="column" alignItems="flex-start">
                        <Grid item>
                            <Typography className={classes.userTitle}>
                                {email}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.userText}>
                                {username}
                            </Typography>
                        </Grid>
                    </Grid>
                </MenuItem>
                <Divider />
                {(role === "admin" || role === "manager") && (
                    <div>
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                goToManageUsers();
                            }}
                        >
                            <ListItemIcon>
                                <PeopleAlt />
                            </ListItemIcon>
                            <ListItemText>Manage users</ListItemText>
                        </MenuItem>
                        <Divider />
                    </div>
                )}
                {role === "admin" && (
                    <div>
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                goToManageOrganisations();
                            }}
                        >
                            <ListItemIcon>
                                <Apartment />
                            </ListItemIcon>
                            <ListItemText>Manage organisations</ListItemText>
                        </MenuItem>
                        <Divider />
                    </div>
                )}

                <MenuItem onClick={signOut}>
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    <ListItemText>Sign out</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
};

export const OrganisationButton: React.FC<OrganisationButtonProps> = ({
    currentOrganisation,
    organisations,
    setCurrentOrganisation,
    setOrganisationToAll,
    setNoOrganisations,
    role,
    currentOrganisations,
    popup,
    newOrganisation,
    createOrganisation,
    handlePopupClose,
    handlePopupOpen,
    handleInputChange,
    error,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = styles({ selected: anchorEl });
    const errorCheck = error !== "";
    const handleClick = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const userIsAdmin = role === "admin";
    const allOrganisationsSelected = () => {
        if (organisations && organisations.length > 0) {
            for (const organisation of organisations) {
                const found = currentOrganisations.find(
                    (org) => org.id === organisation.id
                );
                if (!found) {
                    return false;
                }
            }

            return true;
        }
        return false;
    };

    return (
        <div>
            <Button
                onMouseOver={handleClick}
                onClick={handleClick}
                className={classes.buttonRoot}
                disabled={!userIsAdmin}
            >
                <Grid container direction="column" alignItems="flex-start">
                    <Grid item>
                        <Typography className={classes.buttonTitle}>
                            {userIsAdmin ? "Selecteer" : "Organisatie"}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography className={classes.buttonText}>
                            {userIsAdmin ? "Organisaties" : currentOrganisation}
                            {userIsAdmin && (
                                <ArrowDropDown
                                    className={classes.arrowDropdown}
                                />
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </Button>
            {userIsAdmin && (
                <Menu
                    id="table-options-menu"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{ onMouseLeave: handleClose }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <MenuItem>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Boolean(
                                        allOrganisationsSelected()
                                    )}
                                    icon={<PeopleAltOutlined />}
                                    checkedIcon={<PeopleAlt />}
                                    className={classes.checkbox}
                                />
                            }
                            label={allOrganisationsSelected() ? "Geen" : "Alle"}
                            onChange={() => {
                                if (allOrganisationsSelected()) {
                                    setNoOrganisations();
                                } else {
                                    setOrganisationToAll();
                                }
                            }}
                        />
                    </MenuItem>
                    {organisations.map((organisation) => (
                        <div key={organisation.id}>
                            <Divider />
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={Boolean(
                                                currentOrganisations.find(
                                                    (org) =>
                                                        org.id ===
                                                        organisation.id
                                                )
                                            )}
                                            className={classes.checkbox}
                                        />
                                    }
                                    label={organisation.name}
                                    onChange={() => {
                                        setCurrentOrganisation(organisation.id);
                                    }}
                                />
                            </MenuItem>
                        </div>
                    ))}
                    {role === "admin" && (
                        <div>
                            <Divider />
                            <MenuItem>
                                <RoundedButton
                                    text="Organisatie toevoegen"
                                    onClick={handlePopupOpen}
                                    color="darkBlue"
                                    Icon={AddCircle}
                                    margin="center"
                                />
                            </MenuItem>
                        </div>
                    )}
                </Menu>
            )}
            <Dialog open={popup} onClose={handlePopupClose}>
                <DialogContent className={classes.popup}>
                    <div className={classes.formContainer}>
                        <Typography className={classes.formTitle} variant="h5">
                            Nieuwe Organisatie Toevoegen
                        </Typography>
                        <FormControl className={classes.textField}>
                            <InputLabel
                                error={errorCheck}
                                className={classes.formLabel}
                                htmlFor="filled-organisation"
                            >
                                Organisatie naam
                            </InputLabel>
                            <FilledInput
                                id="filled-organisation"
                                type="text"
                                value={newOrganisation}
                                onChange={handleInputChange}
                                error={errorCheck}
                            />
                        </FormControl>
                        {errorCheck && (
                            <FormHelperText error>{error}</FormHelperText>
                        )}
                    </div>
                    <Divider />
                    <Grid
                        className={classes.formContainer}
                        container
                        justify="space-between"
                    >
                        <Grid item>
                            <RoundedButton
                                text="Voeg organisatie toe"
                                onClick={createOrganisation}
                                color="darkBlue"
                            />
                        </Grid>
                        <Grid item>
                            <RoundedButton
                                text="Annuleren"
                                onClick={handlePopupClose}
                                color="grey"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};
