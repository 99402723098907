import React from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import { DetailedTableRowProps } from "./DetailedTableRow.types";
import styles from "./DetailedTableRow.styles";

const DetailedTableRow: React.FC<DetailedTableRowProps> = ({
    stat,
    value,
    children,
}) => {
    const classes = styles();

    return (
        <TableRow>
            <TableCell align="left" className={classes.tableCell}>
                <Typography className={classes.tableCellItem}>
                    {children || stat}
                </Typography>
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
                <Typography className={classes.tableCellItem}>
                    {value}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default DetailedTableRow;
