import {
    Button,
    CircularProgress,
    Grid,
    Menu,
    MenuItem,
    Popover,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles,
    Avatar,
    Tooltip,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeartPulse } from "@fortawesome/free-solid-svg-icons";

import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import {
    MoreHoriz,
    Sort,
    Wc,
    Visibility,
    Hotel,
    Weekend,
    AcUnit,
    Whatshot,
    Favorite,
    TrendingDown,
    TrendingUp,
    SyncProblem,
} from "@material-ui/icons";
import { dateToDayMonthYearString } from "helpers/dateToString";
import { minutesToHourMinuteString } from "helpers/time";
import moment from "moment";
import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import {
    getPieChartColours,
    getPieChartData,
    getPieChartLabels,
} from "../../helpers/pieChartData";
import {
    DailyAverageData,
    DailyAverageFitbitGeneralData,
    getBodyFitbitData,
    getDailyAverageFitbitData,
    getDailyAverageNoninData,
    getGeneralFitbitData,
} from "../../types/patientdata";
import styles from "./PatientOverviewTable.styles";
import {
    FitbitGeneralDataCellProps,
    FitbitBodyDataCellProps,
    OptionsMenuProps,
    PatientOverviewTableBodyProps,
    PatientOverviewTableFooterProps,
    PatientOverviewTableHeaderProps,
    TabButtonProps,
    DataCellProps,
    FitbitCellsProps,
    NoninCellsProps,
} from "./PatientOverviewTable.types";

import "chartjs-plugin-labels"; // Must be imported after react-chartjs-2

const generalFitbitHeaders = [
    "HR in rust",
    "HRV",
    "Ademhaling",
    "Totale slaapduur",
    "Totale stappen",
    "Temp verschil",
];

const bodyFitbitHeaders = ["Vet", "Gewicht"];

const fitbitHeaders = [...generalFitbitHeaders, ...bodyFitbitHeaders];

const noninHeaders = ["HR Nonin", "SpO2 gemiddelde", "SpO2 minimum"];

export const PatientOverviewTableHeader: React.FC<PatientOverviewTableHeaderProps> =
    ({ changeSorting, dataType }) => {
        const classes = styles({});
        return (
            <TableHead className={classes.tableheader}>
                <TableRow>
                    <TableCell
                        align="left"
                        style={{ width: "10%", padding: "6px 12px" }}
                    >
                        <Button
                            className={classes.button}
                            onClick={() => changeSorting()}
                        >
                            <Typography className={classes.tableHeaderItem}>
                                <Sort className={classes.headerIcon} />
                                Datum
                            </Typography>
                        </Button>
                    </TableCell>
                    <TableCell
                        align="center"
                        style={{ width: "12%", padding: "6px 12px" }}
                    >
                        <Typography className={classes.options}>
                            Meldingen
                        </Typography>
                    </TableCell>
                    {dataType === "fitbit"
                        ? fitbitHeaders.map((column) => (
                              <TableCell
                                  align="center"
                                  style={{
                                      width: `calc(70% / ${fitbitHeaders.length})`,
                                      padding: "6px 12px",
                                  }}
                                  key={column}
                              >
                                  <Typography className={classes.options}>
                                      {column}
                                  </Typography>
                              </TableCell>
                          ))
                        : noninHeaders.map((column) => (
                              <TableCell
                                  align="center"
                                  style={{
                                      width: `calc(70% / ${noninHeaders.length})`,
                                      padding: "6px 12px",
                                  }}
                                  key={column}
                              >
                                  <Typography className={classes.options}>
                                      {column}
                                  </Typography>
                              </TableCell>
                          ))}
                    <TableCell
                        align="center"
                        style={{ width: "8%", padding: "6px 12px" }}
                    >
                        <Typography className={classes.options}>
                            Opties
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    };

const StyledTableRow = withStyles({
    root: {
        height: "52px",
        "&:nth-of-type(even)": {
            backgroundColor: "#f8f9fc",
        },
    },
})(TableRow);

const OptionsMenu: React.FC<OptionsMenuProps> = ({
    goToNightDetailsPage,
    downloadCsv,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = styles({ selected: anchorEl });

    const handleClick = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const csvDownload = async () => {
        await downloadCsv();
        handleClose();
    };
    const details = () => {
        handleClose();
        goToNightDetailsPage();
    };
    return (
        <TableCell align="center" className={classes.generalCell}>
            <Button onClick={handleClick} className={classes.root}>
                <MoreHoriz className={classes.icon} />
            </Button>
            <Menu
                id="table-options-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={csvDownload}>Download CSV</MenuItem>
                <MenuItem onClick={details}>Details</MenuItem>
            </Menu>
        </TableCell>
    );
};

export const Flag: React.FC<{ flag: string }> = ({ flag }) => {
    const labels = {
        heart_rate_rest: {
            title: "HR in rust",
            color: "#001219",
            description: "Alarm! Hartslag in rust neemt toe",
            Icon: <Favorite />,
        },
        maximum_steps_during_sleep: {
            title: "Bewegen",
            color: "#725C1C",
            description: "Patiënt bewoog in bed",
            Icon: <Hotel />,
        },
        maximum_bathroom_amount: {
            title: "Toilet",
            color: "#EE9B00",
            description: "Patiënt moest vannacht te vaak naar het toilet",
            Icon: <Wc />,
        },
        nap: {
            title: "Dutje",
            color: "#E8A5C3",
            description: "Patiënt heeft overdag een dutje gedaan",
            Icon: <Weekend />,
        },
        restless_sleep: {
            title: "Fase",
            color: "#CA6702",
            description:
                "De slaap-waak tijd was vannacht langer dan gemiddeld'",
            Icon: <Visibility />,
        },
        cold_during_sleep: {
            title: "Koud",
            color: "#94D2BD",
            description: "Patiënt had het koud",
            Icon: <AcUnit />,
        },
        hot_during_sleep: {
            title: "Warm",
            color: "#9b2226",
            description: "Patiënt had het warm",
            Icon: <Whatshot />,
        },
        weight_gained_month: {
            title: "Maand",
            color: "#001219",
            description:
                "Alarm! Het gewicht is afgelopen MAAND te veel toegenomen",
            Icon: <TrendingUp />,
        },
        weight_gained_week: {
            title: "Week",
            color: "#001219",
            description:
                "Alarm! Het gewicht is afgelopen WEEK te veel toegenomen",
            Icon: <TrendingUp />,
        },
        weight_gained_day: {
            title: "Dag",
            color: "#001219",
            description:
                "Alarm! Het gewicht is afgelopen DAG te veel toegenomen",
            Icon: <TrendingUp />,
        },
        weight_lost_month: {
            title: "Maand",
            color: "#001219",
            description:
                "Alarm! Het gewicht is afgelopen MAAND te veel afgenomen",
            Icon: <TrendingDown />,
        },
        weight_lost_week: {
            title: "Week",
            color: "#001219",
            description:
                "Alarm! Het gewicht is afgelopen WEEK te veel afgenomen",
            Icon: <TrendingDown />,
        },
        weight_lost_day: {
            title: "Dag",
            color: "#001219",
            description:
                "Alarm! Het gewicht is afgelopen DAG te veel afgenomen",
            Icon: <TrendingDown />,
        },
        lost_weight: {
            color: "#001219",
            description: "Alarm! Het gewicht neemt af",
            Icon: <TrendingDown />,
        },
        gained_weight: {
            color: "#001219",
            description: "Alarm! Het gewicht neemt toe",
            Icon: <TrendingUp />,
        },
        no_sleep_phases: {
            color: "#CB3578",
            description: "Geen registreerbare slaapfases",
            Icon: <SyncProblem />,
        },
        hrv_lower_alarm: {
            title: "HRV gedaald",
            color: "#C01907",
            description: "Alarm! HRV is gedaald",
            Icon: <FontAwesomeIcon icon={faHeartPulse} fontSize="1.5em"/>,
        },
        hrv_upper_alarm: {
            title: "HRV gestegen",
            color: "#429329",
            description: "Herstellend, HRV is gestegen",
            Icon: <FontAwesomeIcon icon={faHeartPulse} fontSize="1.5em"/>,
        },
    };

    if (!labels[flag]) {
        return null;
    }
    if (flag.startsWith("weight_gained") || flag.startsWith("weight_lost")) {
        return (
            <Tooltip
                title={<Typography>{labels[flag]?.description}</Typography>}
                placement="top"
            >
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    style={{
                        color: "#fff",
                        backgroundColor: "#044272",
                        margin: 4,
                        padding: 4,
                        borderRadius: 15,
                        height: 30,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginRight: 10,
                        }}
                    >
                        {labels[flag]?.Icon}
                    </div>
                    <Typography>{labels[flag]?.title}</Typography>
                </Grid>
            </Tooltip>
        );
    }

    return (
        <Tooltip
            title={<Typography>{labels[flag]?.description}</Typography>}
            placement="top"
        >
            <div
                style={{
                    padding: 5,
                    color: labels[flag]?.color,
                    lineHeight: 0,
                }}
            >
                {labels[flag]?.Icon}
            </div>
        </Tooltip>
    );
};

const NumberNotification: React.FC<{
    value: number;
    color: string;
    description: string;
    alarmDescription?: string;
    alarmColor?: string;
    alarm?: boolean;
}> = ({ value, color, description, alarmDescription, alarmColor, alarm }) => {
    const valueText = value > 99 ? "99+" : value;

    return (
        <Tooltip
            title={
                <Typography>
                    {alarm ? alarmDescription : description}
                </Typography>
            }
            placement="top"
        >
            <Typography
                style={{
                    backgroundColor: alarm ? alarmColor : color,
                    color: "#FFFFFF",
                    minWidth: 20,
                    padding: 3,
                    margin: 5,
                    fontSize: 14,
                    textAlign: "center",
                    borderRadius: 4,
                    cursor: "pointer",
                }}
            >
                {valueText}
            </Typography>
        </Tooltip>
    );
};

export const renderFlags = (
    flags?: string[],
    daysSinceLastWeighIn?: number | null,
    day?: boolean,
    sleepPhaseCounter?: number | null
) => {
    let weightGained = false;
    let weightLost = false;
    const alarm = flags?.includes("weigh_in_alarm");
    const sleepPhaseAlarm = flags?.includes("sleep_phase_alarm");

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
            }}
        >
            {day && daysSinceLastWeighIn != null && (
                <NumberNotification
                    value={daysSinceLastWeighIn}
                    color="#0771C2"
                    description="Voldoende weegmomenten"
                    alarm={alarm}
                    alarmDescription="Te weinig weegmomenten!"
                    alarmColor="#C01907"
                />
            )}
            {sleepPhaseCounter != null && (
                <NumberNotification
                    value={sleepPhaseCounter}
                    color="#E8A5C3"
                    description="Niet geregistreerde slaapfases"
                    alarm={sleepPhaseAlarm}
                    alarmDescription="Te veel niet geregistreerde slaapfases!"
                    alarmColor="#711D43"
                />
            )}

            {flags != null &&
                flags.length !== 0 &&
                flags
                    .sort((a, b) => (a < b ? 1 : -1))
                    .map((flag) => {
                        if (
                            !flag.startsWith("weight_gained") &&
                            !flag.startsWith("weight_lost")
                        ) {
                            return <Flag key={flag} flag={flag} />;
                        }
                        if (flag.startsWith("weight_gained") && !weightGained) {
                            weightGained = true;
                            return (
                                <Flag
                                    key="gained_weight"
                                    flag="gained_weight"
                                />
                            );
                        }
                        if (flag.startsWith("weight_lost") && !weightLost) {
                            weightLost = true;
                            return (
                                <Flag key="lost_weight" flag="lost_weight" />
                            );
                        }
                        return null;
                    })}
        </div>
    );
};
const DataCell: React.FC<DataCellProps> = ({ data, metric = "" }) => {
    const classes = styles({});
    return (
        <TableCell align="center" className={classes.generalCell}>
            <Typography className={classes.tableCellItem}>
                {data ? `${data} ${metric}` : "Geen data"}
            </Typography>
        </TableCell>
    );
};

const NoFitbitDataCell: React.FC<Record<string, never>> = () => {
    const classes = styles({});
    return (
        <TableCell
            align="center"
            className={classes.generalCell}
            colSpan={fitbitHeaders.length}
        >
            <Typography className={classes.tableCellItem}>
                Geen Actieve Fitbit Data
            </Typography>
        </TableCell>
    );
};

const NoGeneralDataCell: React.FC<Record<string, never>> = () => {
    const classes = styles({});
    return (
        <>
            <TableCell
                align="center"
                className={classes.generalCell}
                colSpan={generalFitbitHeaders.length}
            >
                <Typography className={classes.tableCellItem}>
                    Geen Fitbit Data
                </Typography>
            </TableCell>
        </>
    );
};

const containsData = (obj) => {
    for (const key in obj) {
        if (obj[key] !== null && obj[key] !== 0) return true;
    }
    return false;
};

const FitbitSleepCell: React.FC<FitbitGeneralDataCellProps> = ({ data }) => {
    const classes = styles({});

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [popoverData, setPopoverData] = useState<DailyAverageData | null>(
        null
    );
    const handlePopoverOpen =
        (dailyAverage: DailyAverageFitbitGeneralData) =>
        (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            setAnchorEl(event.currentTarget);
            setPopoverData(dailyAverage);
        };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const { total_sleeptime_fitbit } = data;
    if (!total_sleeptime_fitbit) {
        return (
            <TableCell align="center" className={classes.generalCell}>
                <Typography className={classes.tableCellItem}>
                    Geen data
                </Typography>
            </TableCell>
        );
    }
    return (
        <TableCell align="center" className={classes.generalCell}>
            <Typography
                className={classes.tableCellItem}
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen(data)}
                onMouseLeave={handlePopoverClose}
            >
                {minutesToHourMinuteString(total_sleeptime_fitbit, false)}
            </Typography>

            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {popoverData?.total_deep_sleep_fitbit === 0 &&
                popoverData?.total_light_sleep_fitbit === 0 &&
                popoverData?.total_rem_sleep_fitbit === 0 &&
                popoverData?.total_wake_sleep_fitbit === 0 ? (
                    <Typography
                        style={{
                            padding: 10,
                        }}
                    >
                        Geen slaapfase data voor deze nacht
                    </Typography>
                ) : (
                    <Grid
                        container
                        className={classes.pieContainer}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Pie
                            data={{
                                maintainAspectRatio: true,
                                responsive: true,
                                labels: getPieChartLabels(popoverData),
                                datasets: [
                                    {
                                        data: getPieChartData(popoverData),
                                        backgroundColor:
                                            getPieChartColours(popoverData),
                                        hoverBackgroundColor:
                                            getPieChartColours(popoverData),
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    labels: {
                                        render: "percentage",
                                        fontsize: 20,
                                        fontColor: "white",
                                    },
                                },
                            }}
                        />
                    </Grid>
                )}
            </Popover>
        </TableCell>
    );
};

const FitbitGeneralDataCells: React.FC<FitbitGeneralDataCellProps> = ({
    data,
}) => {
    if (!containsData(data)) {
        return <NoGeneralDataCell />;
    }
    return (
        <>
            <DataCell data={data.resting_heart_rate} metric="bpm" />
            <DataCell data={data.hrv_average} metric="ms" />
            <DataCell data={data.breathing_rate} metric="brpm" />
            <FitbitSleepCell data={data} />
            <DataCell data={data.total_activity_fitbit} />
            <DataCell data={data.temperature_difference} metric="℃" />
        </>
    );
};

const FitbitBodyDataCells: React.FC<FitbitBodyDataCellProps> = ({ data }) => (
    <>
        <DataCell data={data.body_fat} metric="%" />
        <DataCell data={data.weight} metric="kg" />
    </>
);

const FitbitCells: React.FC<FitbitCellsProps> = ({ data }) => {
    if (!containsData(data)) {
        return <NoFitbitDataCell />;
    }
    const bodyData = getBodyFitbitData(data);
    const generalData = getGeneralFitbitData(data);
    return (
        <>
            <FitbitGeneralDataCells data={generalData} />
            <FitbitBodyDataCells data={bodyData} />
        </>
    );
};

const NoNoninDataCell: React.FC<Record<string, never>> = () => {
    const classes = styles({});
    return (
        <TableCell
            align="center"
            className={classes.generalCell}
            colSpan={noninHeaders.length}
        >
            <Typography className={classes.tableCellItem}>
                Geen Nonin Data
            </Typography>
        </TableCell>
    );
};

const NoninCells: React.FC<NoninCellsProps> = ({ data }) => {
    if (!containsData(data)) {
        return <NoNoninDataCell />;
    }
    return <div>Placeholder</div>;
};

export const PatientOverviewTableBody: React.FC<PatientOverviewTableBodyProps> =
    ({
        dailyAverages,
        page,
        rowsPerPage,
        goToNightDetailsPage,
        downloadCsv,
        loadingData,
        dataType,
    }) => {
        const classes = styles({});

        if (loadingData) {
            return (
                <Grid container spacing={2}>
                    <div
                        style={{
                            minHeight: "300px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress />
                    </div>
                </Grid>
            );
        }

        return (
            <TableBody>
                {dailyAverages !== null ? (
                    (rowsPerPage > 0
                        ? dailyAverages.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                          )
                        : dailyAverages
                    ).map((dailyAverage) => {
                        const date = moment.utc(dailyAverage.date);
                        const dateString = dateToDayMonthYearString(
                            date,
                            "YYYY-MM-DD"
                        );
                        const fitbitData = getDailyAverageFitbitData(
                            dailyAverage.data
                        );
                        const noninData = getDailyAverageNoninData(
                            dailyAverage.data
                        );

                        const { flags } = dailyAverage.data;

                        return (
                            <StyledTableRow key={dailyAverage.date}>
                                <TableCell
                                    align="left"
                                    onClick={() =>
                                        goToNightDetailsPage(dateString)
                                    }
                                    className={classes.tableCell}
                                >
                                    <Typography
                                        className={classes.tableCellItem}
                                    >
                                        {dateString}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.generalCell}
                                >
                                    <div>{flags ? renderFlags(flags) : ""}</div>
                                </TableCell>

                                {dataType === "fitbit" ? (
                                    <FitbitCells data={fitbitData} />
                                ) : (
                                    <NoninCells data={noninData} />
                                )}

                                <OptionsMenu
                                    goToNightDetailsPage={() =>
                                        goToNightDetailsPage(dateString)
                                    }
                                    downloadCsv={() => downloadCsv(dateString)}
                                />
                            </StyledTableRow>
                        );
                    })
                ) : (
                    <StyledTableRow>
                        <TableCell colSpan={7} className={classes.generalCell}>
                            <Typography className={classes.tableCellItem}>
                                Geen patientdata beschikbaar
                            </Typography>
                        </TableCell>
                    </StyledTableRow>
                )}
            </TableBody>
        );
    };

export const PatientOverviewTableFooter: React.FC<PatientOverviewTableFooterProps> =
    ({
        dailyAverages,
        handlePageChange,
        handleChangeRowsPerPage,
        page,
        rowsPerPage,
    }) => {
        const classes = styles({});

        return (
            <TableFooter>
                <TableRow>
                    <TablePagination
                        count={dailyAverages.length}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        colSpan={10}
                        rowsPerPageOptions={[
                            { label: "1 week", value: 7 },
                            { label: "2 weken", value: 14 },
                            { label: "4 weken", value: 28 },
                            { label: "Alle", value: -1 },
                        ]}
                        SelectProps={{
                            native: true,
                        }}
                        ActionsComponent={TablePaginationActions}
                        className={classes.footer}
                        labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to > 0 ? to : count} van ${count}`
                        }
                        labelRowsPerPage="Rijen per pagina"
                    />
                </TableRow>
            </TableFooter>
        );
    };

export const FitbitThumb: React.FC<Record<string, never>> = () => {
    const classes = styles({});
    return (
        <Avatar
            className={classes.fitbitThumb}
            src={require("static/logo-fitbit-iconblue.png")}
        />
    );
};

export const NoninThumb: React.FC<Record<string, never>> = () => {
    const classes = styles({});
    return (
        <Avatar
            className={classes.noninThumb}
            src={require("static/nonin-logo.png")}
        />
    );
};

export const TabButton: React.FC<TabButtonProps> = ({
    dataType,
    onClick,
    active,
}) => {
    const classes = styles({ selected: active });
    return (
        <Button
            value={dataType}
            className={classes.tabButton}
            onClick={onClick}
        >
            {dataType === "fitbit" ? <FitbitThumb /> : <NoninThumb />}
        </Button>
    );
};
