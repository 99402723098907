import React from "react";
import { Moment } from "moment";
import { DetailedNightData } from "types/patientdata";
import { StringArgumentReturnsVoid } from "types/general";
import { Times } from "screens/PatientNightDetails/PatientNightDetails.types";
import { Filters } from "../ValuesFilterFormControl/ValuesFilterFormControl.types";
import { DipData } from "../PatientNightDetailsTable/PatientNightDetailsTable.types";

type HandleDateChange = (date: Moment | null, props: keyof Times) => void;

type ChangeDipData = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>
) => void;
export interface PatientNightDetailsGraphProps {
    timeFilteredData: DetailedNightData | undefined;
    times: Times;
    handleDateChange: HandleDateChange;
    patientName: string;
    changeNight: StringArgumentReturnsVoid;
    dipData: DipData;
    amountOfDips: number;
    changeDipData: ChangeDipData;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleBlur: () => void;
    filters: Filters;
    handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    loadingData: boolean;
}

export interface PatientNightDetailsGraphHeaderProps {
    times: Times;
    patientName: string;
    changeNight: StringArgumentReturnsVoid;
    handleDateChange: HandleDateChange;
}

export interface PatientNightDetailsGraphBodyProps {
    legendFilteredData?: DetailedNightData;
    times: Times;
    minHR: number;
    maxHR: number;
    minSpO2: number;
    maxSpO2: number;
    loadingData: boolean;
}

export interface PatientNightDetailsGraphFooterProps {
    filters: Filters;
    handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    dipData: DipData;
    amountOfDips: number;
    changeDipData: ChangeDipData;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleBlur: () => void;
}

export interface LimitTypeSelectorProps {
    dipData: DipData;
    changeDipData: ChangeDipData;
}
export interface DipsDisplayProps {
    amountOfDips: number;
}
export interface TopFilterFormProps {
    filters: Filters;
    handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface LimitNumberSelectorProps {
    dipData: DipData;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleBlur: () => void;
}
export interface TimeSelectorProps {
    times: Times;
    handleDateChange: HandleDateChange;
}
export interface BottomFilterFormProps {
    filters: Filters;
    handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
// SPO2 0%-100%, PAI 0-100, HRV 0-100, HR 1-100? (maybe HR needs higher max)
export const DipLimitInputSteps = {
    heart_rate_nonin_values: {
        steps: 1,
        max: 100,
    },
    spo2_average_values: {
        steps: 1,
        max: 100,
    },
    spo2_minimum_values: {
        steps: 1,
        max: 100,
    },
    pai_values: {
        steps: 0.1,
        max: 100,
    },
    hrv_values: {
        steps: 1,
        max: 100,
    },
};
