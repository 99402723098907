import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import { Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import {
    logout,
    setAllOrganisations,
    setCurrentOrganisation,
    createOrganisation,
    setNoOrganisations,
} from "store/user/user.actions";
import ReduxState from "store/store.types";
import { CreateOrganisationRequest } from "api/types/User";
import { Logo, OrganisationButton, UserButton } from "./Header.components";
import { HeaderProps } from "./Header.types";
import styles from "./Header.styles";

const Header: React.FC<HeaderProps> = ({
    logout,
    user,
    currentOrganisations,
    setCurrentOrganisation,
    setAllOrganisations,
    createOrganisation,
    setNoOrganisations,
}) => {
    const classes = styles({});
    const [popup, setPopup] = useState(false);
    const [newOrganisation, setNewOrganisation] = useState("");
    const [error, setError] = useState("");
    const history = useHistory();
    const logoutOfSite = () => {
        logout(history);
    };
    const goToHome = () => history.push("/");
    const goToManageUsers = () => history.push("/user-management");
    const goToManageOrganisations = () =>
        history.push("/organisation-management");
    const handlePopupClose = () => {
        setPopup(false);
        setNewOrganisation("");
        setError("");
    };
    const handlePopupOpen = () => {
        setPopup(true);
    };
    const handleInputFieldChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => setNewOrganisation(event.target.value);
    const handleCreateOrganisation = async () => {
        if (newOrganisation !== "") {
            try {
                const request: CreateOrganisationRequest = {
                    name: newOrganisation,
                };
                await createOrganisation(request);
                handlePopupClose();
            } catch {
                setError(
                    `Organisatie "${newOrganisation}" bestaat al binnen database`
                );
            }
        } else {
            setError("Vul aub een organisatie naam in");
        }
    };

    const { role, email, organisations } = user;
    return (
        <AppBar className={classes.root} position="static">
            <Logo goToHome={goToHome} />
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
                className={classes.menuItems}
            >
                <Button
                    onClick={goToHome}
                    className={classes.underlinedMenuItem}
                >
                    <Grid container direction="column" alignItems="flex-start">
                        <Grid item>
                            <Typography className={classes.buttonTitle}>
                                Ga naar
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.buttonText}>
                                Patienten
                            </Typography>
                        </Grid>
                    </Grid>
                </Button>

                <OrganisationButton
                    setCurrentOrganisation={setCurrentOrganisation}
                    setOrganisationToAll={setAllOrganisations}
                    setNoOrganisations={setNoOrganisations}
                    currentOrganisation={
                        currentOrganisations[0]
                            ? currentOrganisations[0].name
                            : "Geen geselecteerd"
                    }
                    organisations={organisations}
                    role={role}
                    currentOrganisations={currentOrganisations}
                    popup={popup}
                    handlePopupClose={handlePopupClose}
                    handlePopupOpen={handlePopupOpen}
                    newOrganisation={newOrganisation}
                    createOrganisation={handleCreateOrganisation}
                    handleInputChange={handleInputFieldChange}
                    error={error}
                />

                <UserButton
                    email={email}
                    role={role}
                    goToManageUsers={goToManageUsers}
                    goToManageOrganisations={goToManageOrganisations}
                    logoutOfSite={logoutOfSite}
                />
            </Grid>
        </AppBar>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    user: state.user.user,
    currentOrganisations: state.user.currentOrganisations,
    organisations: state.user.user.organisations,
    role: state.user.user.role,
});
const mapDispatchToProps = {
    logout,
    setCurrentOrganisation,
    setAllOrganisations,
    createOrganisation,
    setNoOrganisations,
};
const ConnectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header);
export default ConnectedHeader;
