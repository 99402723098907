enum GetEndpoints {
    DailyAverages = "user/overview",
    DetailedNight = "user/detailed-night",
    GetOrganisations = "organisations",
    GetRealTimeNonin = "user/realtime",
    GetSettingKeys = "organisation/patient/setting-keys",
    GetPatientSettings = "organisation/patient/settings",
    CronSettings = "cron/settings",
}

enum PostEndpoints {
    UserLogin = "user/login",
    GetPatients = "organisation/patients",
    ChangePassword = "organisation/patient/change-password",
    ChangeName = "organisation/patient/change-name",
    ArchivePatient = "organisation/patient/archive",
    DeletePatient = "organisation/patient/delete",
    CreateNewPatient = "organisation/patient/create",
    ManageableUsers = "organisation/users",
    CreateUser = "organisation/user/create",
    DeleteUser = "organisation/user/delete",
    CreateOrganisation = "organisation",
    ArchiveOrganisation = "organisation/archive",
    RenameOrganisation = "organisation/rename",
    EnableFitbit = "organisation/fitbit/enable",
    DisableFitbit = "organisation/fitbit/disable",
    ResetPasswordComplete = "user/password-reset/complete",
    ResetPasswordStart = "user/password-reset/start",
    ChangePatientSetting = "organisation/patient/settings/change",
    DeletePatientSetting = "organisation/patient/settings/delete",
    CronRefresh = "cron/nonin/refresh",
    CronSettingsUpdate = "cron/settings/update",
    RemoveWeightAlarm = "organisation/remove-weight-alarm",
}

export { GetEndpoints, PostEndpoints };
