import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    filterCheckBox: {
        background: "#F8F9FC",
        margin: "0 7px",
        padding: "5px 10px 5px 0",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        height: "36px",
    },
    checkboxLabel: {
        color: "#044272",
        fontWeight: 600,
    },
});

export const dataColors = {
    heart_rate_nonin_values: "#D05B56",
    spo2_average_values: "#63CB61",
    spo2_minimum_values: "#429329",
    pai_values: "#044272",
    hrv_values: "#AD679B",
    heart_rate_fitbit_values: "#E5A73A",
    activity_fitbit_values: "#8190dc",
    cumulative_activity_fitbit_values: "#4153AF",
    sleep_stage_values: "#E06E91",

    heart_rate_nonin_average: "#D05B56",
    spo2_average_average: "#63CB61",
    spo2_minimum_average: "#429329",
    pai_average: "#044272",
    hrv_average: "#AD679B",
    resting_heart_rate: "#E5A73A",
    sleep_efficiency_fitbit: "#E06E91",
    total_sleeptime_fitbit: "#d33465",
    temperature_difference: "#fe6601",
    weight: "#8190dc",
    body_fat: "#D05B56",
    total_activity_fitbit: "#201E43",
    breathing_rate: "#429329",

    spo2_values: "#63CB61",
};
