import React from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "components/Header/Header";
import { connect } from "react-redux";
import ReduxState from "store/store.types";
import styles from "./Main.styles";
import { MainProps } from "./Main.types";

const Main: React.FC<MainProps> = ({ children, isLoggedIn }) => {
    const classes = styles();
    return (
        <BrowserRouter>
            <div className={classes.root}>
                {isLoggedIn ? <Header /> : null}
                {children}
            </div>
        </BrowserRouter>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isLoggedIn: state.user.user.isLoggedIn,
});
const mapDispatchToProps = {};
const ConnectedMain = connect(mapStateToProps, mapDispatchToProps)(Main);
export default ConnectedMain;
