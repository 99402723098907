import React, { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import RoundedButton from "components/RoundedButton/RoundedButton";
import { login } from "store/user/user.actions";
import ReduxState from "store/store.types";
import { LoginFormProps } from "./LoginForm.types";
import styles from "./LoginForm.styles";

const LoginForm: React.FC<LoginFormProps> = ({ login, error }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const classes = styles();
    const history = useHistory();
    const canSendForm = email.length > 0 && password.length > 0;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await login({
            email,
            password,
        });
        setLoading(false);
        history.push("/");
    };

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.grid}
        >
            <Grid item>
                <TextField
                    id="outlined-email"
                    label="Email"
                    variant="outlined"
                    value={email}
                    autoFocus
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                    }
                    className={classes.textField}
                    InputProps={{
                        className: classes.input,
                    }}
                    error={error}
                />
            </Grid>
            <Grid item>
                <TextField
                    id="outlined-password"
                    label="Password"
                    variant="outlined"
                    value={password}
                    autoComplete="current-password"
                    type="password"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(e.target.value)
                    }
                    className={classes.textField}
                    InputProps={{
                        className: classes.input,
                    }}
                    error={error}
                    helperText={error && "Email of Wachtwoord is incorrect."}
                />
            </Grid>
            <Grid item>
                <RoundedButton
                    disabled={!canSendForm || loading}
                    onClick={handleSubmit}
                    color="darkBlue"
                    text="Log In"
                    margin="center"
                    loading={loading}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    error: state.user.error,
});
const mapDispatchToProps = {
    login,
};
const ConnectedLoginForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm);

export default ConnectedLoginForm;
