import React from "react";

export interface Filters {
    average: {
        heart_rate_nonin_average?: boolean;
        spo2_average_average?: boolean;
        spo2_minimum_average?: boolean;
        hrv_average?: boolean;
        resting_heart_rate?: boolean;
        total_sleeptime_fitbit?: boolean;
        sleep_efficiency_fitbit?: boolean;
        total_activity_fitbit?: boolean;
        temperature_difference?: boolean;
        weight?: boolean;
        body_fat?: boolean;
        breathing_rate?: boolean;
    };
    detailed: {
        heart_rate_nonin_values?: boolean;
        spo2_average_values?: boolean;
        spo2_minimum_values?: boolean;
        pai_values?: boolean;
        hrv_values?: boolean;
        sleep_stage_values?: boolean;
        heart_rate_fitbit_values?: boolean;
        activity_fitbit_values?: boolean;
        cumulative_activity_fitbit_values?: boolean;
    };
    realTime: {
        heart_rate_nonin_values?: boolean;
        spo2_values?: boolean;
        pai_values?: boolean;
        hrv_values?: boolean;
    };
}

export enum FilterLabels {
    heart_rate_nonin_values = "HR nonin",
    spo2_average_values = "SpO2 gem",
    spo2_minimum_values = "SpO2 min",
    pai_values = "PAI",
    hrv_values = "HRV",
    sleep_stage_values = "Sleep stage",
    heart_rate_fitbit_values = "HR fitbit",
    activity_fitbit_values = "Activity",
    cumulative_activity_fitbit_values = "Total Activity",
    total_activity_fitbit = "Totale stappen",

    heart_rate_nonin_average = "HR Nonin",
    spo2_average_average = "SpO2 gem",
    spo2_minimum_average = "SpO2 min",
    pai_average = "PAI",
    hrv_average = "HRV",
    resting_heart_rate = "HR in rust",
    total_sleeptime_fitbit = "Totale slaapduur",
    sleep_efficiency_fitbit = "Slaap efficientie",
    temperature_difference = "Temp verschil",
    weight = "Gewicht",
    breathing_rate = "Ademhaling",
    body_fat = "Vet",

    spo2_values = "SpO2",
}

export interface ValuesFilterFormControlProps {
    value:
        | keyof Filters["detailed"]
        | keyof Filters["average"]
        | keyof Filters["realTime"];
    type: "detailed" | "average" | "realTime";
    filters: Filters;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
