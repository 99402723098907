import { post } from "api/internal/calls";
import {
    getPatientsRequestDecoder,
    getPatientsResponseDataDecoder,
} from "api/internal/decoders";
import { PostEndpoints } from "api/internal/endpoints";
import jsonCheck from "api/internal/jsoncheck";
import requireJWT from "api/internal/requirejwt";
import throwOrReturn from "api/internal/throworreturn";
import Config from "api/types/Config";
import {
    GetPatientsRequest,
    GetPatientsResponseData,
} from "api/types/Organisation";

const getPatients = async (
    config: Config,
    organisationData: GetPatientsRequest
): Promise<GetPatientsResponseData> => {
    requireJWT(config);
    jsonCheck(organisationData, getPatientsRequestDecoder);
    const response = await post<GetPatientsRequest, GetPatientsResponseData>(
        config,
        PostEndpoints.GetPatients,
        organisationData
    );
    return throwOrReturn(response, getPatientsResponseDataDecoder);
};

export { getPatients };
