import React from "react";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { RoundedButtonProps } from "./RoundedButton.types";
import styles, {
    ButtonColorCatalogue,
    MarginCatalogue,
} from "./RoundedButton.styles";

const RoundedButton: React.FC<RoundedButtonProps> = ({
    Icon,
    EndIcon,
    text,
    onClick,
    color,
    disabled,
    margin = "right",
    loading,
    children,
}) => {
    const classes = styles({
        ...ButtonColorCatalogue[color],
        position: MarginCatalogue[margin],
        padding: text ? "6px 16px" : "0",
    });
    if (!text) {
        return (
            <Box style={{ position: "relative" }}>
                <Button
                    onClick={onClick}
                    variant="contained"
                    className={classes.root}
                    disabled={disabled}
                    classes={{
                        disabled: classes.disabledButton,
                    }}
                >
                    {loading ? (
                        <CircularProgress
                            size={24}
                            style={{
                                color: "#63CB61",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    ) : (
                        children
                    )}
                </Button>
            </Box>
        );
    }
    if (Icon && EndIcon) {
        return (
            <Box style={{ position: "relative" }}>
                <Button
                    onClick={onClick}
                    variant="contained"
                    startIcon={<Icon />}
                    endIcon={<EndIcon />}
                    className={classes.root}
                    disabled={disabled}
                    classes={{
                        disabled: classes.disabledButton,
                    }}
                >
                    {text}
                </Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        style={{
                            color: "#63CB61",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                        }}
                    />
                )}
            </Box>
        );
    }
    if (Icon && !EndIcon) {
        return (
            <Box style={{ position: "relative" }}>
                <Button
                    onClick={onClick}
                    variant="contained"
                    startIcon={<Icon />}
                    className={classes.root}
                    disabled={disabled}
                    classes={{
                        disabled: classes.disabledButton,
                    }}
                >
                    {text}
                </Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        style={{
                            color: "#63CB61",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                        }}
                    />
                )}
            </Box>
        );
    }
    if (!Icon && EndIcon) {
        return (
            <Box style={{ position: "relative" }}>
                <Button
                    onClick={onClick}
                    variant="contained"
                    className={classes.root}
                    endIcon={<EndIcon />}
                    disabled={disabled}
                    classes={{
                        disabled: classes.disabledButton,
                    }}
                >
                    {text}
                </Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        style={{
                            color: "#63CB61",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                        }}
                    />
                )}
            </Box>
        );
    }
    return (
        <Box style={{ position: "relative" }}>
            <Button
                onClick={onClick}
                variant="contained"
                className={classes.root}
                disabled={disabled}
                classes={{
                    disabled: classes.disabledButton,
                }}
            >
                {text}
            </Button>
            {loading && (
                <CircularProgress
                    size={24}
                    style={{
                        color: "#63CB61",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                    }}
                />
            )}
        </Box>
    );
};

export default RoundedButton;
