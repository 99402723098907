import { makeStyles } from "@material-ui/core/styles";
import pattern from "static/pattern";

export default makeStyles({
    grid: { minHeight: "100vh", backgroundImage: pattern },
    paper: { padding: "10px", background: "#3779AE" },
    link: {
        alignSelf: "flex-end",
        color: "#FAFAFA",
        textDecoration: "underline",
        "&:hover": {
            cursor: "pointer",
        },
    },
});
