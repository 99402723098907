import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Typography } from "@material-ui/core";
import ReduxState from "store/store.types";
import { getDetailedNight } from "store/patient/patient.actions";
import { connect } from "react-redux";
import { GetDetailedNightRequest } from "api/types/Patient";
import {
    GetApp,
    Home,
    NavigateNext,
    Person,
    Timelapse,
} from "@material-ui/icons";
import RoundedButton from "components/RoundedButton/RoundedButton";
import moment, { Moment } from "moment";
import PatientNightDetailsTable from "components/PatientNightDetailsTable/PatientNightDetailsTable";
import {
    calculateDipsPerHour,
    calculateAmountOfDips,
    calculateOxygenDesaturationIndex,
    calculateMinimumFromGraphObject,
    calculateAverageFromGraphObject,
} from "helpers/calculateOxygenDesaturationIndex";
import { DipData } from "components/PatientNightDetailsTable/PatientNightDetailsTable.types";
import { filterDetailedNightDataByTime } from "helpers/filterDetailedNightDataByTime";
import PatientNightDetailsGraph from "components/PatientNightDetailsGraph/PatientNightDetailsGraph";
import { dateToDayMonthYearString } from "helpers/dateToString";
import { createPatientDetailedNightCSVFull } from "helpers/createCsv";
import { Filters } from "components/ValuesFilterFormControl/ValuesFilterFormControl.types";
import { PatientNightDetailsProps, Times } from "./PatientNightDetails.types";
import styles from "./PatientNightDetails.styles";

const filtersAllTrue: Filters = {
    average: {},
    detailed: {
        heart_rate_nonin_values: true,
        spo2_average_values: true,
        pai_values: true,
        sleep_stage_values: true,

        heart_rate_fitbit_values: true,
        spo2_minimum_values: true,
        hrv_values: true,
        activity_fitbit_values: true,
        cumulative_activity_fitbit_values: true,
    },
    realTime: {},
};

const PatientNightDetails: React.FC<PatientNightDetailsProps> = ({
    currentPatient,
    getDetailedNight,
    loadingData,
}) => {
    const { patient, night } = useParams();
    const classes = styles();
    const history = useHistory();
    const [dipData, setDipData] = useState<DipData>({
        type: "spo2_average_values",
        limit: 97,
    });
    const [times, setTimes] = useState<Times>({
        startTime: moment.utc(`${night}T12:00:00`),
        endTime: moment.utc(`${night}T12:00:00`).add(1, "day"),
    });
    const [filters, setFilters] = useState<Filters>(filtersAllTrue);
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({
            ...filters,
            detailed: {
                ...filters.detailed,
                [event.target.name]: event.target.checked,
            },
        });
    };

    const handleDateChange = (date: Moment | null, props: keyof Times) => {
        if (date !== null) {
            if (
                props === "startTime" &&
                date.valueOf() < times.endTime.valueOf() &&
                date.valueOf() >= moment.utc(`${night}T12:00:00`).valueOf()
            ) {
                setTimes({
                    ...times,
                    [props]: date,
                });
            }
            if (
                props === "endTime" &&
                date.valueOf() > times.startTime.valueOf() &&
                date.valueOf() <=
                    moment.utc(`${night}T12:00:00`).add(1, "day").valueOf()
            ) {
                setTimes({
                    ...times,
                    [props]: date,
                });
            }
        } else {
            // Used when clearing datetimepicker
            if (props === "startTime")
                setTimes({
                    ...times,
                    startTime: moment.utc(`${night}T12:00:00`),
                });
            if (props === "endTime") {
                setTimes({
                    ...times,
                    endTime: moment.utc(`${night}T12:00:00`).add(1, "day"),
                });
            }
        }
    };
    const timeFilteredData = filterDetailedNightDataByTime(
        currentPatient.detailedNight?.data,
        times.startTime,
        times.endTime
    );

    const changeNight = async (newNight: string) => {
        const nightString = dateToDayMonthYearString(
            moment.utc(newNight),
            "YYYY-MM-DD"
        );
        setTimes({
            startTime: moment.utc(`${newNight}T12:00:00`),
            endTime: moment.utc(`${newNight}T12:00:00`).add(1, "day"),
        });

        history.push(`/${patient}/${nightString}`);
    };
    const changeDipData = (
        event: React.ChangeEvent<{ value: unknown; name?: string }>
    ) => {
        if (event.target.name && dipData[event.target.name]) {
            setDipData({
                ...dipData,
                [event.target.name]: event.target.value,
            });
        }
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDipData({
            ...dipData,
            limit: event.target.value === "" ? "" : Number(event.target.value),
        });
    };
    const handleBlur = () => {
        if (dipData.limit < 0) {
            setDipData({
                ...dipData,
                limit: 0,
            });
        } else if (dipData.limit > 100) {
            setDipData({
                ...dipData,
                limit: 100,
            });
        }
    };
    const downloadCsv = () =>
        createPatientDetailedNightCSVFull(
            night as string,
            timeFilteredData,
            patient,
            filtersAllTrue,
            times.startTime,
            times.endTime
        );

    useEffect(() => {
        const detailedNightRequest: GetDetailedNightRequest = {
            patient_name: patient,
            night_date: night,
        };

        // Initial call
        getDetailedNight(detailedNightRequest, true);
        // Loop every X seconds for refresh.
        const timer = setInterval(
            () => getDetailedNight(detailedNightRequest, false),
            10000
        );
        const timer2 = setInterval(
            () => getDetailedNight(detailedNightRequest, true),
            900000
        );

        return () => {
            // Cleanup
            clearInterval(timer);
            clearInterval(timer2);
        };
    }, [patient, night, getDetailedNight]);

    const getODI = () => {
        const odi3 = calculateOxygenDesaturationIndex(timeFilteredData, 3); // Used to calculate the ODI-3% value (can be changed to another number if needed)

        const startTime = timeFilteredData?.spo2_average_values[0]
            ? moment.utc(timeFilteredData.spo2_average_values[0].x)
            : times.startTime;

        const endTime = timeFilteredData?.spo2_average_values[
            timeFilteredData?.spo2_average_values.data.length - 1
        ]
            ? moment.utc(
                  timeFilteredData.spo2_average_values[
                      timeFilteredData.spo2_average_values.data.length - 1
                  ].x
              )
            : times.endTime;

        return calculateDipsPerHour(odi3, startTime, endTime);
    };

    const getSpo2MinDips = (limit: number) =>
        calculateAmountOfDips(
            limit, // Value used to calculate the Dips under 88% oxygen saturation in the blood
            timeFilteredData,
            "spo2_minimum_values"
        );

    const getSpo2Minimum = () =>
        calculateMinimumFromGraphObject(timeFilteredData?.spo2_minimum_values);
    const getSpo2Average = () =>
        calculateAverageFromGraphObject(timeFilteredData?.spo2_average_values);

    return (
        <Grid className={classes.div}>
            <Typography variant="h4" className={classes.titleText}>
                {patient} &#8226; {night}
            </Typography>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.title}
            >
                <Grid item>
                    <Breadcrumbs
                        separator={<NavigateNext className={classes.divider} />}
                    >
                        <Link
                            to="/"
                            className={classes.link}
                            component={RouterLink}
                        >
                            <Home className={classes.icon} />
                            Patienten
                        </Link>
                        <Link
                            to={`/${patient}`}
                            className={classes.link}
                            component={RouterLink}
                        >
                            <Person className={classes.icon} />
                            {patient}
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid
                    item
                    container
                    direction="row"
                    justify="flex-end"
                    style={{ width: "500px" }}
                >
                    <RoundedButton
                        text="Realtime pagina"
                        onClick={() => history.push(`/${patient}/realtime`)}
                        color="grey"
                        Icon={Timelapse}
                    />
                    <RoundedButton
                        text="Download CSV"
                        onClick={downloadCsv}
                        color="darkBlue"
                        Icon={GetApp}
                    />
                </Grid>
            </Grid>
            <PatientNightDetailsGraph
                timeFilteredData={timeFilteredData}
                times={times}
                patientName={patient}
                changeNight={changeNight}
                dipData={dipData}
                amountOfDips={calculateAmountOfDips(
                    dipData.limit,
                    timeFilteredData,
                    dipData.type
                )}
                changeDipData={changeDipData}
                handleBlur={handleBlur}
                handleInputChange={handleInputChange}
                handleDateChange={handleDateChange}
                filters={filters}
                handleFilterChange={handleFilterChange}
                loadingData={loadingData}
            />
            <PatientNightDetailsTable
                loadingData={loadingData}
                detailedNightData={currentPatient.detailedNight?.data}
                oxygenDesaturationIndex={getODI()}
                getSpo2MinDips={getSpo2MinDips}
                dipData={dipData}
                amountOfDips={calculateAmountOfDips(
                    dipData.limit,
                    timeFilteredData,
                    dipData.type
                )}
                spo2Minimum={getSpo2Minimum()}
                spo2Average={getSpo2Average()}
            />
        </Grid>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    currentPatient: state.patient.currentPatient,
    loadingData: state.patient.loadingDetailedNight,
});
const mapDispatchToProps = {
    getDetailedNight,
};
const ConnectedPatientNightDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientNightDetails);

export default ConnectedPatientNightDetails;
