import Config from "api/types/Config";
import axios, { AxiosRequestConfig } from "axios";
import APIResult from "./apiresult";
import RawResponse from "./rawresponse";
import payloadChecker from "./payloadchecker";

export const get = async <T>(
    config: Config,
    endpoint: string
): Promise<APIResult<T>> => {
    const url = `${config.baseURL}/${endpoint}`;
    try {
        const res = await axios.get(url, configToReqConfig(config));
        const data = res.data as RawResponse<T>;
        return payloadChecker(data);
    } catch (error) {
        console.warn(`request to ${url} returned an error :\n${error}`);
        return {
            error,
        };
    }
};

export const post = async <T, U>(
    config: Config,
    endpoint: string,
    payload: T
): Promise<APIResult<U>> => {
    const url = `${config.baseURL}/${endpoint}`;
    try {
        const res = await axios.post(url, payload, configToReqConfig(config));
        const data = res.data as RawResponse<U>;
        return payloadChecker(data);
    } catch (error) {
        console.warn(
            `request to ${url} with data ${JSON.stringify(
                payload
            )} returned an error :\n${error}`
        );
        return {
            error,
        };
    }
};

export const configToReqConfig = (config: Config): AxiosRequestConfig => {
    if (!config.jwt) {
        return {};
    }
    return {
        headers: {
            Authorization: `Bearer ${config.jwt}`,
        },
    };
};
