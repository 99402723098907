import React, { useState } from "react";
import { connect } from "react-redux";
import PatientTable from "components/PatientTable/PatientTable";
import { Dialog, Grid, Typography } from "@material-ui/core";
import RoundedButton from "components/RoundedButton/RoundedButton";
import { PersonAdd, GetApp } from "@material-ui/icons";
import AddPatientPopup from "components/AddPatientPopup/AddPatientPopup";
import { UserObject } from "components/AddPatientPopup/AddPatientPopup.types";
import { createNewPatient } from "store/patient/patient.actions";
import ReduxState from "store/store.types";
import { createPatientListCSV } from "helpers/createCsv";
import { HomeProps } from "./Home.types";
import styles from "./Home.styles";

const Home: React.FC<HomeProps> = ({
    createNewPatient,
    role,
    currentOrganisations,
    organisations,
    patients,
}) => {
    const classes = styles();
    const [popup, setPopup] = useState(false);

    const intialOrganisationNonAdmin =
        role !== "admin" && currentOrganisations && currentOrganisations[0].id;

    const [inputFieldValues, setInputFieldValues] = useState<{
        user_name: string;
        password: string;
        organisation_id?: unknown;
    }>({
        user_name: "",
        password: "",
        organisation_id: intialOrganisationNonAdmin || undefined,
    });
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const downloadCsv = () =>
        createPatientListCSV(patients, currentOrganisations);
    const handlePopupClose = () => {
        setInputFieldValues({
            user_name: "",
            password: "",
            organisation_id: intialOrganisationNonAdmin || undefined,
        });
        setError("");
        setPopup(false);
    };
    const addPatient = async () => {
        const { user_name, password, organisation_id } = inputFieldValues;
        if (user_name !== "" && password !== "" && organisation_id) {
            if (role === "admin") {
                try {
                    await createNewPatient({
                        user_name,
                        password,
                        organisation_id: organisation_id as number,
                    });
                    handlePopupClose();
                } catch (e) {
                    setError(
                        `Patient ${user_name} bestaat al binnen organisatie.`
                    );
                }
            } else if (
                currentOrganisations &&
                currentOrganisations[0].name !== "none"
            ) {
                try {
                    await createNewPatient({
                        user_name,
                        password,
                        organisation_id: currentOrganisations[0].id,
                    });
                    handlePopupClose();
                } catch (e) {
                    setError(
                        `Patient ${user_name} bestaat al binnen organisatie.`
                    );
                }
            }
        } else {
            setError("Vul aub alle velden in");
        }
    };
    const handleInputFieldChange =
        (prop: keyof UserObject) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setInputFieldValues({
                ...inputFieldValues,
                [prop]: event.target.value,
            });
        };
    const handleSelectChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setInputFieldValues({
            ...inputFieldValues,
            organisation_id: event.target.value,
        });
    };
    const handleShowPassword = () => setShowPassword(!showPassword);
    const handlePopupOpen = () => {
        setPopup(true);
    };
    return (
        <Grid className={classes.div}>
            <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.title}
            >
                <Grid item>
                    <Typography variant="h4" className={classes.titleText}>
                        Patienten
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    direction="row"
                    justify="flex-end"
                    style={{ width: "500px" }}
                >
                    <RoundedButton
                        text="Download CSV"
                        onClick={downloadCsv}
                        color="darkBlue"
                        Icon={GetApp}
                    />
                    <RoundedButton
                        text="Toevoegen van patient"
                        onClick={handlePopupOpen}
                        color="darkBlue"
                        Icon={PersonAdd}
                    />

                    <Dialog open={popup} onClose={handlePopupClose}>
                        <AddPatientPopup
                            addPatient={addPatient}
                            errorText={error}
                            inputFieldValues={inputFieldValues}
                            showPassword={showPassword}
                            handleShowPassword={handleShowPassword}
                            handleInputFieldChange={handleInputFieldChange}
                            handlePopupClose={handlePopupClose}
                            handleSelectChange={handleSelectChange}
                            role={role}
                            currentOrganisations={currentOrganisations}
                            organisations={organisations}
                        />
                    </Dialog>
                </Grid>
            </Grid>
            <PatientTable />
        </Grid>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    currentOrganisations: state.user.currentOrganisations,
    organisations: state.user.user.organisations,
    role: state.user.user.role,
    patients: state.patient.patients,
});
const mapDispatchToProps = {
    createNewPatient,
};
const ConnectedHome = connect(mapStateToProps, mapDispatchToProps)(Home);

export default ConnectedHome;
