import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    div: {
        padding: "64px 50px",

        [theme.breakpoints.up("lg")]: {
            padding: "65px 100px",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "64px 140px",
        },
    },
    title: { height: "40px", marginBottom: "15px" },
    titleText: { fontWeight: 700, color: "#044272" },
}));
