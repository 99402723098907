import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    root: (props: { selected?: any | ((element: any) => any) | null }) => ({
        padding: 0,
        textTransform: "revert",
        justifyContent: "flex-end",
        color: "#C9CFDA",
        backgroundColor: props.selected ? "rgb(239,239,239)" : "transparent",
        minWidth: "40px",
        minHeight: "40px",
    }),
    icon: { width: "40px", height: "40px" },
    tableCell: { padding: "0 16px " },
    popup: { width: "600px" },
    popupCsv: { width: "350px" },
    popupText: { fontWeight: "bold" },
    popupTextCsv: { fontWeight: "bold", textAlign: "center" },
    textField: {
        width: "100%",
        margin: "10px 0",
        "& .MuiFilledInput-root": {
            background: "#FAFAFA",
            borderRadius: "10px 10px 0px 0px",
        },
        "& .MuiFilledInput-input": {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "29px",
            color: "#585858",
        },
    },
    formLabel: { padding: "0 5px", zIndex: 100 },
    formTitle: { fontWeight: 700 },
    formContainer: { margin: "20px 0 30px" },
    formButton: {
        justifyContent: "flex-end",
        display: "flex",
        marginTop: "20px",
    },
    datePickerTextField: {
        margin: "0 7px",
        width: "136px",
        height: "36px",
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
        "& .MuiInputBase-root": {
            color: "#044272",
            fontWeight: 600,
            padding: "2px 10px",
            cursor: "pointer",
        },
        "& .MuiInputBase-input": {
            cursor: "pointer",
        },
    },
    settingNumberSelector: {
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        color: "#044272",
        fontWeight: 600,
        fontSize: "16px",
        padding: "2px 5px",
        marginLeft: "5px",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
    },
    settingTypeSelector: {
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        color: "#044272",
        fontWeight: 600,
        fontSize: "16px",
        padding: "2px 5px",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
        "& .MuiSelect-select": {
            padding: "5px 5px 5px 0",
        },
    },
});
