import React from "react";
import LoginLogo from "components/LoginLogo/LoginLogo";
import { Grid, Paper, Typography } from "@material-ui/core";
import LoginForm from "components/LoginForm/LoginForm";
import { useHistory } from "react-router-dom";
import styles from "./Login.styles";

const Login: React.FC = () => {
    const classes = styles();
    const history = useHistory();
    const goToPasswordResetPage = () => history.push("/reset-password");
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.grid}
        >
            <Paper className={classes.paper} elevation={7}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <Grid item>
                        <LoginLogo text="Dawny" />
                    </Grid>
                    <Grid item>
                        <LoginForm />
                    </Grid>
                    <Grid
                        item
                        className={classes.link}
                        onClick={goToPasswordResetPage}
                    >
                        <Typography variant="subtitle1">
                            Wachtwoord vergeten?
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default Login;
