import React, { useState } from "react";
import { Paper, Table, TableContainer } from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DailyAverage } from "types/patientdata";
import { connect } from "react-redux";
import { getDetailedNightOnReturn } from "store/patient/patient.actions";
import { GetDetailedNightRequest } from "api/types/Patient";
import { createPatientDetailedNightCSVFull } from "helpers/createCsv";

import {
    DataType,
    PatientOverviewTableProps,
} from "./PatientOverviewTable.types";
import {
    PatientOverviewTableBody,
    PatientOverviewTableFooter,
    PatientOverviewTableHeader,
    TabButton,
} from "./PatientOverviewTable.components";

const PatientOverviewTable: React.FC<PatientOverviewTableProps> = ({
    dailyAverages,
    getDetailedNightOnReturn,
    loadingData,
}) => {
    const { patient } = useParams();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const history = useHistory();
    const location = useLocation();
    const [sortingOrder, setSortingOrder] = useState(true);
    const [dataType, setDataType] = useState<DataType>("fitbit");

    const changeSorting = () => {
        setSortingOrder(!sortingOrder);
    };
    const goToNightDetailsPage = (date: string) =>
        history.push(`${location.pathname}/${date}`);
    const downloadCsv = async (date: string) => {
        const detailedNightRequest: GetDetailedNightRequest = {
            patient_name: patient,
            night_date: date,
        };
        const detailedNight = await getDetailedNightOnReturn(
            detailedNightRequest
        );
        createPatientDetailedNightCSVFull(date, detailedNight?.data, patient);
    };
    const handlePageChange = (event, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const compare = (a: DailyAverage, b: DailyAverage) =>
        Date.parse(a.date) - Date.parse(b.date);
    const sortedAverages = dailyAverages && [
        ...dailyAverages.sort((a, b) => compare(a, b)),
    ];
    if (sortingOrder && sortedAverages) {
        sortedAverages.reverse();
    }
    const FibitActive = () => (
        <>
            <TabButton
                dataType="fitbit"
                onClick={() => setDataType("fitbit")}
                active
            />
            <TabButton dataType="nonin" onClick={() => setDataType("nonin")} />
        </>
    );

    const NoninActive = () => (
        <>
            <TabButton
                dataType="fitbit"
                onClick={() => setDataType("fitbit")}
            />
            <TabButton
                dataType="nonin"
                onClick={() => setDataType("nonin")}
                active
            />
        </>
    );

    return (
        <>
            {dataType === "fitbit" ? <FibitActive /> : <NoninActive />}

            <Paper
                style={
                    dataType === "fitbit"
                        ? { borderRadius: "0px 4px 4px 4px" }
                        : { borderRadius: "4px" }
                }
            >
                <TableContainer>
                    <Table>
                        <PatientOverviewTableHeader
                            changeSorting={changeSorting}
                            dataType={dataType}
                        />
                        <PatientOverviewTableBody
                            loadingData={loadingData}
                            dailyAverages={sortedAverages}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            goToNightDetailsPage={goToNightDetailsPage}
                            downloadCsv={downloadCsv}
                            dataType={dataType}
                        />
                        {sortedAverages && (
                            <PatientOverviewTableFooter
                                dailyAverages={sortedAverages}
                                handlePageChange={handlePageChange}
                                handleChangeRowsPerPage={
                                    handleChangeRowsPerPage
                                }
                                page={page}
                                rowsPerPage={rowsPerPage}
                            />
                        )}
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    getDetailedNightOnReturn,
};
const ConnectedPatientOverviewTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientOverviewTable);
export default ConnectedPatientOverviewTable;
