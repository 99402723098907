import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    logo: { minWidth: "150px" },
    logoImage: { width: "145px", height: "90px" },
    logoText: {
        fontWeight: "bold",
        fontStyle: "normal",
        textAlign: "center",
        fontSize: "35px",
        lineHeight: "16px",
        height: "50px",
        color: "white",
    },
});
