import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    tableHeaderItem: {
        color: "#044272",
        fontWeight: 700,
        size: "16px",
        lineHeight: "32px",
        alignItems: "center",
        display: "flex",
    },
    tableheader: {
        height: "52px",
        width: "1160px",
        boxShadow: "inset 0px -1px 0px rgba(4, 66, 114, 0.16)",
    },
    tableheaderCell: { padding: "6px 12px" },
    tableCellItem: {
        size: "16px",
        lineHeight: "32px",
        alignItems: "center",
        color: "#044272",
    },
    tableCell: { padding: "6px 12px", border: "none" },
    limitTypeSelector: {
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        color: "#044272",
        fontWeight: 600,
        fontSize: "16px",
        padding: "2px 5px",
        marginLeft: "5px",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
        "& .MuiSelect-select": {
            padding: "5px 5px 5px 0",
        },
    },
    limitNumberSelector: {
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        color: "#044272",
        fontWeight: 600,
        fontSize: "16px",
        padding: "2px 5px",
        marginLeft: "5px",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
    },
});
