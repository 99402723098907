import React from "react";
import {
    Checkbox,
    CheckboxProps,
    FormControlLabel,
    Typography,
    withStyles,
} from "@material-ui/core";
import {
    ValuesFilterFormControlProps,
    FilterLabels,
} from "./ValuesFilterFormControl.types";
import styles, { dataColors } from "./ValuesFilterFormControl.styles";

const ColoredCheckbox = withStyles({
    root: (props: { colorString: string }) => ({
        color: props.colorString,
        "&$checked": {
            color: props.colorString,
        },
    }),
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const ValuesFilterFormControl: React.FC<ValuesFilterFormControlProps> = ({
    value,
    filters,
    onChange,
    type,
}) => {
    const classes = styles();

    return (
        <FormControlLabel
            className={classes.filterCheckBox}
            control={
                <ColoredCheckbox
                    colorString={dataColors[value]}
                    checked={filters[type][value]}
                    onChange={onChange}
                    name={value}
                />
            }
            label={
                <>
                    <Typography className={classes.checkboxLabel}>
                        {FilterLabels[value]}
                    </Typography>
                </>
            }
        />
    );
};

export default ValuesFilterFormControl;
