import Config from "api/types/Config";
import { LoginRequest, LoginResponseData } from "api/types/Login";
import { post } from "api/internal/calls";
import { PostEndpoints } from "api/internal/endpoints";
import jsonCheck from "api/internal/jsoncheck";
import {
    loginRequestDecoder,
    loginResponseDataDecoder,
} from "api/internal/decoders";
import throwOrReturn from "api/internal/throworreturn";

const login = async (
    config: Config,
    accountData: LoginRequest
): Promise<LoginResponseData> => {
    jsonCheck(accountData, loginRequestDecoder);
    const response = await post<LoginRequest, LoginResponseData>(
        config,
        PostEndpoints.UserLogin,
        accountData
    );
    return throwOrReturn(response, loginResponseDataDecoder);
};

export default login;
