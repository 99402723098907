enum PatientActions {
    GetPatientList = "GetPatientList",
    GetDailyAverages = "GetDailyAverages",
    GetDetailedNight = "GetDetailedNight",
    StartLoadingDetails = "StartLoadingDetails",
    StartLoadingAverages = "StartLoadingAverages",
    StartLoadingPatients = "StartLoadingPatients",
    GetRealTimeNonin = "GetRealTimeNonin",
    SetPatientSettings = "SetPatientSettings",
    SetPatientSettingKeys = "SetPatientSettingKeys",
}
export default PatientActions;
