import { NoArgumentsReturnsVoid } from "types/general";
import { Dispatch } from "redux";
import { GetPatientsResponseData } from "api/types/Organisation";
import { getPatients } from "api/calls/organisation";
import getApiConfig from "store/apiconfig";
import {
    ArchivePatientRequest,
    ChangeNameRequest,
    ChangePasswordRequest,
    ChangePatientSettingRequest,
    CreateNewPatientRequest,
    DeletePatientRequest,
    DeletePatientSettingRequest,
    GetDailyAveragesRequest,
    GetDailyAveragesResponseData,
    GetDetailedNightRequest,
    GetDetailedNightResponseData,
    GetRealTimeNoninDataRequest,
    GetRealTimeNoninDataResponse,
    ValueAndTime as NoninValueAndTime,
} from "api/types/Patient";
import {
    archivePatientApiCall,
    changeNameApiCall,
    changePasswordApiCall,
    changePatientSettingApiCall,
    createNewPatientApiCall,
    deletePatientApiCall,
    deletePatientSettingApiCall,
    getDailyAveragesApiCall,
    getDetailedNightDataApiCall,
    getPatientSettingKeysApiCall,
    getPatientSettingsApiCall,
    getRealTimeNoninDataApiCall,
} from "api/calls/patient";
import {
    DailyAverage,
    DailyAverageGraphData,
    DetailedNight,
    emptyGraphObject,
    PatientOverview,
    RealTimeNoninData,
    ValueAndTime,
} from "types/patientdata";
import store from "store/store";
import { floatToDouble, floatToSingle } from "helpers/floatToDouble";
import PatientActions from "./patient.constants";
import { getData } from "../../helpers/graphdata";

export type GetPatientList = NoArgumentsReturnsVoid;
export const getPatientList = () => async (dispatch: Dispatch) => {
    try {
        let patientOverviewList: PatientOverview[] = [];
        for (const organisation of store.getState().user.currentOrganisations) {
            const request = { organisation_id: organisation.id };
            // eslint-disable-next-line no-await-in-loop
            const responseData: GetPatientsResponseData = await getPatients(
                getApiConfig(),
                request
            );
            if (responseData) {
                patientOverviewList = [
                    ...patientOverviewList,
                    ...responseData.map((patientOverviewData) => ({
                        ...patientOverviewData,
                        name: patientOverviewData.user_name,
                        lastNoninEntry: patientOverviewData.last_active_nonin,
                        lastFitbitEntry: patientOverviewData.last_active_fitbit,
                        organisation: organisation.name,
                    })),
                ];
            }
        }

        return dispatch({
            type: PatientActions.GetPatientList,
            payload: {
                patients: [...patientOverviewList],
            },
        });
    } catch (e) {
        console.error(e);
    }
    return null;
};

export type ChangePassword = (
    changePasswordData: ChangePasswordRequest
) => void;
export const changePassword =
    (changePasswordData: ChangePasswordRequest) => async () => {
        try {
            await changePasswordApiCall(getApiConfig(), changePasswordData);
        } catch (e) {
            console.error(e);
        }
    };

export type ChangeName = (changeNameData: ChangeNameRequest) => void;
export const changeName =
    (changeNameData: ChangeNameRequest) => async (dispatch: Dispatch) => {
        try {
            await changeNameApiCall(getApiConfig(), changeNameData);
            await getPatientList()(dispatch);
        } catch (e) {
            console.error(e);
        }
    };

export type ArchivePatient = (
    archivePatientData: ArchivePatientRequest
) => void;
export const archivePatient =
    (archivePatientData: ArchivePatientRequest) =>
    async (dispatch: Dispatch) => {
        try {
            await archivePatientApiCall(getApiConfig(), archivePatientData);
            await getPatientList()(dispatch);
        } catch (e) {
            console.error(e);
        }
    };

export type DeletePatient = (deletePatientData: DeletePatientRequest) => void;
export const deletePatient =
    (deletePatientData: DeletePatientRequest) => async (dispatch: Dispatch) => {
        try {
            await deletePatientApiCall(getApiConfig(), deletePatientData);
            await getPatientList()(dispatch);
        } catch (e) {
            console.error(e);
        }
    };

export type CreateNewPatient = (
    createNewPatientData: CreateNewPatientRequest
) => void;
export const createNewPatient =
    (createNewPatientData: CreateNewPatientRequest) =>
    async (dispatch: Dispatch) => {
        try {
            await createNewPatientApiCall(getApiConfig(), createNewPatientData);
            await getPatientList()(dispatch);
        } catch (e) {
            throw new Error(e);
        }
    };

export const getPatientSettingKeys = () => async (dispatch: Dispatch) => {
    try {
        const response = await getPatientSettingKeysApiCall(getApiConfig());
        if (response != null) {
            return dispatch({
                type: PatientActions.SetPatientSettingKeys,
                payload: {
                    settingKeys: response,
                },
            });
        }
    } catch (e) {
        console.error(e);
    }
    return dispatch({
        type: PatientActions.SetPatientSettingKeys,
        payload: {
            settings: [],
        },
    });
};

export type GetPatientSettings = (patientName: string) => void;
export const getPatientSettings =
    (patientName: string) => async (dispatch: Dispatch) => {
        try {
            const response = await getPatientSettingsApiCall(
                getApiConfig(),
                patientName
            );
            if (response != null) {
                return dispatch({
                    type: PatientActions.SetPatientSettings,
                    payload: {
                        settings: response,
                    },
                });
            }
        } catch (e) {
            console.error(e);
        }
        return dispatch({
            type: PatientActions.SetPatientSettings,
            payload: {
                settings: [],
            },
        });
    };

export type ChangePatientSetting = (data: ChangePatientSettingRequest) => void;
export const changePatientSetting =
    (data: ChangePatientSettingRequest) => async (dispatch: Dispatch) => {
        try {
            const response = await changePatientSettingApiCall(
                getApiConfig(),
                data
            );
            if (response != null) {
                return dispatch({
                    type: PatientActions.SetPatientSettings,
                    payload: {
                        settings: response,
                    },
                });
            }
        } catch (e) {
            console.error(e);
        }
        return dispatch({
            type: PatientActions.SetPatientSettings,
            payload: {
                settings: [],
            },
        });
    };

export type DeletePatientSetting = (data: DeletePatientSettingRequest) => void;
export const deletePatientSetting =
    (data: DeletePatientSettingRequest) => async (dispatch: Dispatch) => {
        try {
            const response = await deletePatientSettingApiCall(
                getApiConfig(),
                data
            );
            if (response != null) {
                return dispatch({
                    type: PatientActions.SetPatientSettings,
                    payload: {
                        settings: response,
                    },
                });
            }
        } catch (e) {
            console.error(e);
        }
        return dispatch({
            type: PatientActions.SetPatientSettings,
            payload: {
                settings: [],
            },
        });
    };

const trimDate = (date: string): string => date.slice(0, -1);

const responseToDailyAverageData = (
    responseData: GetDailyAveragesResponseData
): DailyAverage[] | null => {
    if (responseData) {
        const dailyAverages: DailyAverage[] = responseData.map(
            (dailyAverage) => ({
                date: trimDate(dailyAverage.date),
                data: {
                    ...dailyAverage.data,
                    heart_rate_nonin_average:
                        dailyAverage.data.heart_rate_nonin_average &&
                        floatToDouble(
                            dailyAverage.data.heart_rate_nonin_average
                        ),
                    spo2_average_average:
                        dailyAverage.data.spo2_average_average &&
                        floatToDouble(dailyAverage.data.spo2_average_average),
                    spo2_minimum_average:
                        dailyAverage.data.spo2_minimum_average &&
                        floatToDouble(dailyAverage.data.spo2_minimum_average),
                    hrv_average:
                        dailyAverage.data.hrv_average &&
                        floatToDouble(dailyAverage.data.hrv_average),
                    resting_heart_rate:
                        dailyAverage.data.resting_heart_rate &&
                        floatToDouble(dailyAverage.data.resting_heart_rate),
                    total_sleeptime_fitbit:
                        dailyAverage.data.total_sleeptime_fitbit &&
                        floatToDouble(dailyAverage.data.total_sleeptime_fitbit),
                    sleep_efficiency_fitbit:
                        dailyAverage.data.sleep_efficiency_fitbit &&
                        floatToDouble(
                            dailyAverage.data.sleep_efficiency_fitbit
                        ),
                    temperature_difference:
                        dailyAverage.data.temperature_difference &&
                        floatToDouble(dailyAverage.data.temperature_difference),

                    weight:
                        dailyAverage.data.weight &&
                        floatToSingle(dailyAverage.data.weight),

                    total_activity_fitbit:
                            dailyAverage.data.total_activity_fitbit &&
                            floatToSingle(dailyAverage.data.total_activity_fitbit),
                    body_fat:
                            dailyAverage.data.body_fat&&
                            floatToDouble(dailyAverage.data.body_fat),


                    breathing_rate:
                        dailyAverage.data.breathing_rate &&
                        floatToSingle(dailyAverage.data.breathing_rate),
                },
            })
        );
        return dailyAverages;
    }
    return null;
};

const responseToDailyAverageGraphData = (
    responseData: GetDailyAveragesResponseData
): DailyAverageGraphData | null => {
    if (responseData) {
        const heartRateNoninValues: NoninValueAndTime[] = [];
        const spo2AverageValues: NoninValueAndTime[] = [];
        const spo2MinimumValues: NoninValueAndTime[] = [];
        const hrvValues: NoninValueAndTime[] = [];
        const restingHeartRateValues: NoninValueAndTime[] = [];
        const totalSleepTimeValues: NoninValueAndTime[] = [];
        const weightValues: NoninValueAndTime[] = []; // TODO: remove weight
        const totalActivityValues: NoninValueAndTime[] = [];
        const temperatureDifferenceValues: NoninValueAndTime[] = [];
        const bodyFatValues: NoninValueAndTime[] = [];
        const breathingRateValues: NoninValueAndTime[] = [];

        responseData.forEach((day) => {
            const { data, date } = day;
            if (data.heart_rate_nonin_average)
                heartRateNoninValues.push({
                    date,
                    value: data.heart_rate_nonin_average,
                });
            if (data.spo2_average_average)
                spo2AverageValues.push({
                    date,
                    value: data.spo2_average_average,
                });
            if (data.spo2_minimum_average)
                spo2MinimumValues.push({
                    date,
                    value: data.spo2_minimum_average,
                });
            if (data.hrv_average)
                hrvValues.push({
                    date,
                    value: data.hrv_average,
                });
            if (data.resting_heart_rate)
                restingHeartRateValues.push({
                    date,
                    value: data.resting_heart_rate,
                });
            if (data.total_sleeptime_fitbit)
                totalSleepTimeValues.push({
                    date,
                    value: data.total_sleeptime_fitbit,
                });
                // TODO: remove weight
            if (data.weight)
                weightValues.push({
                    date,
                    value: data.weight,
                });
            if (data.total_activity_fitbit)
                totalActivityValues.push({
                    date,
                    value: data.total_activity_fitbit,
                });
            if (data.temperature_difference)
                temperatureDifferenceValues.push({
                    date,
                    value: data.temperature_difference,
                });
            if (data.body_fat)
                bodyFatValues.push({ date, value: data.body_fat });
            if (data.breathing_rate)
                breathingRateValues.push({ date, value: data.breathing_rate });
        });
        const dailyAverageGraphData: DailyAverageGraphData = {
            heart_rate_nonin_average: getData(
                dataToGraphValueAndTime(heartRateNoninValues),
                "Heart rate nonin",
                "scatter",
                1,
                false,
                "heart_rate_nonin_average"
            ),
            spo2_average_average: getData(
                dataToGraphValueAndTime(spo2AverageValues),
                "SpO2 gem",
                "scatter",
                2,
                false,
                "spo2_average_average"
            ),
            spo2_minimum_average: getData(
                dataToGraphValueAndTime(spo2MinimumValues),
                "SpO2 min",
                "scatter",
                2,
                false,
                "spo2_minimum_average"
            ),
            hrv_average: getData(
                dataToGraphValueAndTime(hrvValues),
                "HRV",
                "scatter",
                3,
                false,
                "hrv_average"
            ),
            resting_heart_rate: getData(
                dataToGraphValueAndTime(restingHeartRateValues),
                "HR in rust",
                "scatter",
                1,
                false,
                "resting_heart_rate"
            ),
            total_sleeptime_fitbit: getData(
                dataToGraphValueAndTime(totalSleepTimeValues),
                "Totale Slaapduur",
                "scatter",
                5,
                false,
                "total_sleeptime_fitbit"
            ),
            // TODO: remove weight
            weight: getData(
                dataToGraphValueAndTime(weightValues),
                "Gewicht",
                "scatter",
                6,
                false,
                "weight"
            ),
            total_activity_fitbit: getData(
                dataToGraphValueAndTime(totalActivityValues),
                "Stappen",
                "scatter",
                8,
                false,
                "total_activity_fitbit"
            ),

            breathing_rate: getData(
                dataToGraphValueAndTime(breathingRateValues),
                "Ademhaling",
                "scatter",
                9,
                false,
                "breathing_rate"
            ),
            temperature_difference: getData(
                dataToGraphValueAndTime(temperatureDifferenceValues),
                "Temp verschil",
                "scatter",
                7,
                false,
                "temperature_difference"
            ),
        };

        return dailyAverageGraphData;
    }
    return null;
};

export type GetDailyAveragesOnReturn = (
    getDailyAveragesRequest: GetDailyAveragesRequest
) => Promise<DailyAverage[] | null>;
export const getDailyAveragesOnReturn =
    (getDailyAveragesRequest: GetDailyAveragesRequest) => async () => {
        try {
            const responseData: GetDailyAveragesResponseData =
                await getDailyAveragesApiCall(
                    getApiConfig(),
                    getDailyAveragesRequest
                );
            return responseToDailyAverageData(responseData);
        } catch (e) {
            console.error(e);
        }
        return null;
    };

export type GetDailyAverages = (
    getDailyAverageRequest: GetDailyAveragesRequest
) => void;
export const getDailyAverages =
    (getDailyAverageRequest: GetDailyAveragesRequest) =>
    async (dispatch: Dispatch) => {
        dispatch({
            type: PatientActions.StartLoadingAverages,
        });
        try {
            const responseData: GetDailyAveragesResponseData =
                await getDailyAveragesApiCall(
                    getApiConfig(),
                    getDailyAverageRequest
                );
            return dispatch({
                type: PatientActions.GetDailyAverages,
                payload: {
                    dailyAverages: responseToDailyAverageData(responseData),
                    dailyAverageGraphData:
                        responseToDailyAverageGraphData(responseData),
                },
            });
        } catch (e) {
            console.error(e);
            return dispatch({
                type: PatientActions.GetDailyAverages,
                payload: {
                    dailyAverages: null,
                    dailyAverageGraphData: {
                        heart_rate_nonin_average: emptyGraphObject,
                        spo2_average_values: emptyGraphObject,
                        spo2_minimum_values: emptyGraphObject,
                        pai_values: emptyGraphObject,
                        hrv_values: emptyGraphObject,
                    },
                },
            });
        }
    };

const dataToGraphValueAndTime = (array: NoninValueAndTime[]): ValueAndTime[] =>
    array.map((noninValue) => ({
        x: trimDate(noninValue.date),
        y: floatToDouble(noninValue.value),
    }));

const responseToRealTimeNoninData = (
    responseData: GetRealTimeNoninDataResponse
): RealTimeNoninData | null => {
    if (responseData) {
        const realTimeNonin: RealTimeNoninData = {
            heart_rate_nonin_values: getData(
                dataToGraphValueAndTime(responseData.heart_rate_nonin_values),
                "Heart rate nonin",
                "scatter",
                1,
                false,
                "heart_rate_nonin_average"
            ),
            spo2_values: getData(
                dataToGraphValueAndTime(responseData.spo2_values),
                "SpO2",
                "scatter",
                3,
                false,
                "spo2_values"
            ),
            pai_values: getData(
                dataToGraphValueAndTime(responseData.pai_values),
                "PAI",
                "scatter",
                0,
                false,
                "pai_average"
            ),
            hrv_values: getData(
                dataToGraphValueAndTime(responseData.hrv_values),
                "HRV",
                "scatter",
                2,
                false,
                "hrv_average"
            ),
        };
        return realTimeNonin;
    }
    return null;
};

const responseToDetailedNightData = (
    responseData: GetDetailedNightResponseData
): DetailedNight | null => {
    if (responseData) {
        const { date, data } = responseData;
        const detailedNight: DetailedNight = {
            date,
            data: {
                ...data,
                heart_rate_nonin_average:
                    data.heart_rate_nonin_average &&
                    floatToDouble(data.heart_rate_nonin_average),
                spo2_average_average:
                    data.spo2_average_average &&
                    floatToDouble(data.spo2_average_average),
                spo2_minimum_minimum:
                    data.spo2_minimum_minimum &&
                    floatToDouble(data.spo2_minimum_minimum),
                heart_rate_nonin_values: getData(
                    dataToGraphValueAndTime(data.heart_rate_nonin_values),
                    "Heart rate nonin",
                    "scatter",
                    1,
                    false,
                    "heart_rate_nonin_average"
                ),
                spo2_average_values: getData(
                    dataToGraphValueAndTime(data.spo2_average_values),
                    "SpO2 gem",
                    "scatter",
                    3,
                    false,
                    "spo2_average_average"
                ),
                spo2_minimum_values: getData(
                    dataToGraphValueAndTime(data.spo2_minimum_values),
                    "SpO2 min",
                    "scatter",
                    3,
                    false,
                    "spo2_minimum_average"
                ),

                pai_values: getData(
                    dataToGraphValueAndTime(data.pai_values),
                    "PAI",
                    "scatter",
                    0,
                    false,
                    "pai_average"
                ),

                hrv_values: getData(
                    dataToGraphValueAndTime(data.hrv_values),
                    "HRV",
                    "scatter",
                    2,
                    false,
                    "hrv_average"
                ),
                sleep_stage_values: data.sleep_stage_values
                    ? getData(
                          dataToGraphValueAndTime(data.sleep_stage_values),
                          "Sleep stage",
                          "line",
                          5,
                          true,
                          "sleep_stage_values"
                      )
                    : undefined,
                heart_rate_fitbit_values: data.heart_rate_fitbit_values
                    ? getData(
                          dataToGraphValueAndTime(
                              data.heart_rate_fitbit_values
                          ),
                          "Heart rate fitbit",
                          "scatter",
                          1,
                          false,
                          "heart_rate_fitbit_values"
                      )
                    : undefined,
                activity_fitbit_values: data.activity_fitbit_values
                    ? getData(
                          dataToGraphValueAndTime(data.activity_fitbit_values),
                          "Activity",
                          "scatter",
                          6,
                          false,
                          "activity_fitbit_values"
                      )
                    : undefined,
                cumulative_activity_fitbit_values:
                    data.cumulative_activity_fitbit_values
                        ? getData(
                              dataToGraphValueAndTime(
                                  data.cumulative_activity_fitbit_values
                              ),
                              "Cumulative Activity",
                              "scatter",
                              7,
                              false,
                              "cumulative_activity_fitbit_values"
                          )
                        : undefined,
            },
        };
        return detailedNight;
    }
    return null;
};

export type GetDetailedNightOnReturn = (
    getDetailedNightRequest: GetDetailedNightRequest
) => Promise<DetailedNight | null>;
export const getDetailedNightOnReturn =
    (getDetailedNightRequest: GetDetailedNightRequest) => async () => {
        try {
            const responseData: GetDetailedNightResponseData =
                await getDetailedNightDataApiCall(
                    getApiConfig(),
                    getDetailedNightRequest,
                    false
                );
            return responseToDetailedNightData(responseData);
        } catch (e) {
            console.error(e);
            // clearing detailedNightData when night doesnt exist in backend
            return null;
        }
    };

export type GetDetailedNight = (
    getDetailedNightRequest: GetDetailedNightRequest,
    initialCall?: boolean
) => void;
export const getDetailedNight =
    (getDetailedNightRequest: GetDetailedNightRequest, initialCall?: boolean) =>
    async (dispatch: Dispatch) => {
        if (initialCall) {
            dispatch({
                type: PatientActions.StartLoadingDetails,
            });
        }
        try {
            const responseData: GetDetailedNightResponseData =
                await getDetailedNightDataApiCall(
                    getApiConfig(),
                    getDetailedNightRequest,
                    !initialCall
                );
            return dispatch({
                type: PatientActions.GetDetailedNight,
                payload: {
                    detailedNight: responseToDetailedNightData(responseData),
                    initialCall,
                },
            });
        } catch (e) {
            console.error(e);
            // clearing detailedNightData when night doesnt exist in backend
            const detailedNight: DetailedNight = {
                date: getDetailedNightRequest.night_date,
                data: {
                    heart_rate_nonin_values: emptyGraphObject,
                    spo2_average_values: emptyGraphObject,
                    spo2_minimum_values: emptyGraphObject,
                    pai_values: emptyGraphObject,
                    hrv_values: emptyGraphObject,
                },
            };
            return dispatch({
                type: PatientActions.GetDetailedNight,
                payload: {
                    detailedNight,
                    initialCall,
                },
            });
        }
    };

export type GetRealTimeNonin = (
    getRealTimeNoninDataRequest: GetRealTimeNoninDataRequest
) => void;
export const getRealTimeNonin =
    (getRealTimeNoninDataRequest: GetRealTimeNoninDataRequest) =>
    async (dispatch: Dispatch) => {
        try {
            const responseData: GetRealTimeNoninDataResponse =
                await getRealTimeNoninDataApiCall(
                    getApiConfig(),
                    getRealTimeNoninDataRequest
                );
            return dispatch({
                type: PatientActions.GetRealTimeNonin,
                payload: {
                    realTimeNonin: responseToRealTimeNoninData(responseData),
                },
            });
        } catch (e) {
            console.error(e);
            return null;
        }
    };
