import { DailyAverageData } from "../types/patientdata";
import { dataColors } from "../components/PatientNightDetailsGraph/PatientNightDetailsGraph.styles";

export const getPieChartData = (data: DailyAverageData | null): number[] => {
    const result: number[] = [];
    if (data !== null) {
        const {
            total_light_sleep_fitbit,
            total_wake_sleep_fitbit,
            total_rem_sleep_fitbit,
            total_deep_sleep_fitbit,
        } = data;
        if (total_wake_sleep_fitbit) result.push(total_wake_sleep_fitbit);
        if (total_rem_sleep_fitbit) result.push(total_rem_sleep_fitbit);
        if (total_light_sleep_fitbit) result.push(total_light_sleep_fitbit);
        if (total_deep_sleep_fitbit) result.push(total_deep_sleep_fitbit);
    }

    return result;
};

export const getPieChartLabels = (data: DailyAverageData | null): string[] => {
    const result: string[] = [];
    if (data !== null) {
        const {
            total_light_sleep_fitbit,
            total_wake_sleep_fitbit,
            total_rem_sleep_fitbit,
            total_deep_sleep_fitbit,
        } = data;
        if (total_wake_sleep_fitbit) result.push("awake");
        if (total_rem_sleep_fitbit) result.push("rem");
        if (total_light_sleep_fitbit) result.push("light");
        if (total_deep_sleep_fitbit) result.push("deep");
    }
    return result;
};

export const getPieChartColours = (data: DailyAverageData | null): string[] => {
    const result: string[] = [];
    if (data !== null) {
        const {
            total_light_sleep_fitbit,
            total_wake_sleep_fitbit,
            total_rem_sleep_fitbit,
            total_deep_sleep_fitbit,
        } = data;
        if (total_wake_sleep_fitbit)
            result.push(dataColors.total_wake_sleep_fitbit);
        if (total_rem_sleep_fitbit)
            result.push(dataColors.total_rem_sleep_fitbit);
        if (total_light_sleep_fitbit)
            result.push(dataColors.total_light_sleep_fitbit);
        if (total_deep_sleep_fitbit)
            result.push(dataColors.total_deep_sleep_fitbit);
    }
    return result;
};
