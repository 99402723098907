import React, { useEffect, useState } from "react";
import ReduxState from "store/store.types";
import {
    archiveOrganisation,
    renameOrganisation,
    getOrganisations,
    enableFitbit,
    disableFitbit,
} from "store/user/user.actions";
import { connect } from "react-redux";
import {
    Table,
    TableContainer,
    Paper,
    Dialog,
    DialogContent,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    FilledInput,
    FormHelperText,
} from "@material-ui/core";
import { Organisation } from "types/user";
import { Edit, HighlightOff } from "@material-ui/icons";
import RoundedButton from "components/RoundedButton/RoundedButton";
import styles from "./OrganisationTable.styles";
import {
    OrganisationTableProps,
    PopupBooleans,
} from "./OrganisationTable.types";
import {
    OrganisationTableBody,
    OrganisationTableFooter,
    OrganisationTableHeader,
} from "./OrganisationTable.components";

const clearOrganisation: Organisation = {
    id: 0,
    name: "",
    enable_fitbit: true,
};
const clearPopups: PopupBooleans = { rename: false, archive: false };

const OrganisationTable: React.FC<OrganisationTableProps> = ({
    organisations,
    archiveOrganisation,
    renameOrganisation,
    getOrganisations,
    enableFitbit,
    disableFitbit,
}) => {
    const classes = styles();
    const [popups, setPopups] = useState<PopupBooleans>(clearPopups);
    const [organisation, setOrganisation] =
        useState<Organisation>(clearOrganisation);
    const [newName, setNewName] = useState("");
    const [error, setError] = useState("");
    const errorCheck = error !== "";
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const [sortingOrder, setSortingOrder] = useState(true);

    const archiveOrganisationAndClosePopup = async () => {
        try {
            await archiveOrganisation({ organisation_id: organisation.id });
            close();
        } catch (e) {
            console.error(e);
        }
    };

    const renameOrganisationAndClosePopup = async () => {
        try {
            await renameOrganisation({
                organisation_id: organisation.id,
                organisation_name: newName,
            });
            close();
        } catch (e) {
            setError(
                `Organisatienaam "${newName}" bestaat al binnen de database`
            );
        }
    };
    const close = () => {
        setOrganisation(clearOrganisation);
        setPopups(clearPopups);
        setError("");
        setNewName("");
    };
    const handlePopup = (
        value: boolean,
        org: Organisation,
        key: keyof PopupBooleans
    ) => {
        setPopups({
            ...popups,
            [key]: value,
        });
        setOrganisation(org);
    };
    const changeSorting = () => setSortingOrder(!sortingOrder);
    const handlePageChange = (event: unknown, newPage: number) =>
        setPage(newPage);
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredOrganisations = organisations.filter((org) =>
        org.name.toUpperCase().includes(search.toUpperCase())
    );
    const sortedOrganisations = filteredOrganisations.sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
        if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
        return 0;
    });
    if (!sortingOrder) {
        sortedOrganisations.reverse();
    }

    useEffect(() => {
        getOrganisations();
    }, [getOrganisations]);
    return (
        <Paper>
            <TableContainer>
                <Table>
                    <OrganisationTableHeader
                        search={search}
                        changeSorting={changeSorting}
                        setSearch={setSearch}
                    />
                    <OrganisationTableBody
                        filteredOrganisations={sortedOrganisations}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handlePopup={handlePopup}
                        enableFitbit={enableFitbit}
                        disableFitbit={disableFitbit}
                    />
                    <OrganisationTableFooter
                        filteredOrganisations={sortedOrganisations}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handlePageChange={handlePageChange}
                        page={page}
                        rowsPerPage={rowsPerPage}
                    />
                </Table>
            </TableContainer>
            <Dialog open={popups.rename} onClose={() => close()}>
                <DialogContent className={classes.popup}>
                    <div className={classes.topContainer}>
                        <Grid container justify="center">
                            <Grid item>
                                <Edit className={classes.editFormIcon} />
                            </Grid>
                        </Grid>
                        <Typography className={classes.formTitle} variant="h4">
                            Verander naam
                        </Typography>
                        <Typography className={classes.formTitle} variant="h4">
                            {organisation.name}
                        </Typography>
                        <FormControl className={classes.textField}>
                            <InputLabel
                                error={errorCheck}
                                className={classes.formLabel}
                                htmlFor="filled-name"
                            >
                                Nieuwe naam
                            </InputLabel>
                            <FilledInput
                                id="filled-name"
                                type="text"
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                error={errorCheck}
                            />
                            {errorCheck && (
                                <FormHelperText error>{error}</FormHelperText>
                            )}
                        </FormControl>
                    </div>

                    <Grid
                        className={classes.formContainer}
                        container
                        justify="center"
                    >
                        <Grid item>
                            <RoundedButton
                                text="Verander"
                                onClick={() =>
                                    renameOrganisationAndClosePopup()
                                }
                                disabled={newName === ""}
                                color="darkBlue"
                                margin="center"
                            />
                        </Grid>
                        <Grid item>
                            <RoundedButton
                                text="Annuleer"
                                onClick={() => close()}
                                color="grey"
                                margin="center"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog open={popups.archive} onClose={() => close()}>
                <DialogContent className={classes.popup}>
                    <div className={classes.topContainer}>
                        <Grid container justify="center">
                            <Grid item>
                                <HighlightOff className={classes.formIcon} />
                            </Grid>
                        </Grid>
                        <Typography className={classes.formTitle} variant="h4">
                            Verwijder organisatie
                        </Typography>
                        <Typography className={classes.formText}>
                            Weet je zeker dat je organisatie{" "}
                            <b>&quot;{organisation.name}&quot;</b> wilt
                            verwijderen?
                        </Typography>
                    </div>

                    <Grid
                        className={classes.formContainer}
                        container
                        justify="center"
                    >
                        <Grid item>
                            <RoundedButton
                                text="Verwijder"
                                onClick={archiveOrganisationAndClosePopup}
                                color="red"
                                margin="center"
                            />
                        </Grid>
                        <Grid item>
                            <RoundedButton
                                text="Annuleer"
                                onClick={() => close()}
                                color="grey"
                                margin="center"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Paper>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    organisations: state.user.user.organisations,
});
const mapDispatchToProps = {
    archiveOrganisation,
    renameOrganisation,
    getOrganisations,
    enableFitbit,
    disableFitbit,
};
const ConnectedOrganisationTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganisationTable);

export default ConnectedOrganisationTable;
