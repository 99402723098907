import React from "react";
import { Button, Grid, Input, Typography } from "@material-ui/core";
import { Scatter } from "react-chartjs-2";
import moment from "moment";
import { ChevronRight } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import {
    NoninRealTimeGraphBodyProps,
    NoninRealTimeGraphFooterProps,
    NoninRealTimeGraphHeaderProps,
} from "./NoninRealTimeGraph.types";
import styles from "./NoninRealTimeGraph.styles";
import ValuesFilterFormControl from "../ValuesFilterFormControl/ValuesFilterFormControl";
import { Filters } from "../ValuesFilterFormControl/ValuesFilterFormControl.types";
import { getRealTimeDataSet } from "../../helpers/graphdata";
import {
    dateToDayMonthYearString,
    dateToRealTimeString,
} from "../../helpers/dateToString";

export const NoninRealTimeGraphHeader: React.FC<NoninRealTimeGraphHeaderProps> =
    ({ handleInputChange, timeWindow, patientName }) => {
        const classes = styles({});
        const history = useHistory();
        const toDetailPage = () => {
            const night = dateToDayMonthYearString(
                moment.utc(moment.now()),
                "YYYY-MM-DD"
            );
            history.push(`/${patientName}/${night}`);
        };
        return (
            <Grid
                container
                item
                className={classes.tableheader}
                alignItems="center"
                justify="space-between"
            >
                <Grid item className={classes.tableheaderCell}>
                    <Typography className={classes.text}>
                        <b>Tijdsvenster</b>
                        <Input
                            className={classes.timeWindowSelector}
                            value={timeWindow}
                            onChange={handleInputChange}
                            inputProps={{
                                step: 1,
                                min: 1,
                                max: 60,
                                type: "number",
                                style: {
                                    textAlign: "center",
                                    width: "50px",
                                },
                            }}
                            disableUnderline
                        />
                        <b>minuten</b>
                    </Typography>
                </Grid>
                <Grid item className={classes.tableheaderCell}>
                    <Typography className={classes.text}>
                        <b>
                            {patientName} &#8226; {moment().format("HH:mm:ss")}{" "}
                            &#8226; Real time
                        </b>
                    </Typography>
                </Grid>
                <Grid item className={classes.tableheaderCell}>
                    <Button
                        className={classes.headerButton}
                        endIcon={<ChevronRight />}
                        onClick={toDetailPage}
                    >
                        Detail pagina
                    </Button>
                </Grid>
            </Grid>
        );
    };

export const NoninRealTimeGraphBody: React.FC<NoninRealTimeGraphBodyProps> = ({
    maxHR,
    minSpO2,
    maxSpO2,
    minHR,
    legendFilteredData,
    timeWindow,
}) => {
    const classes = styles({ empty: !legendFilteredData });
    const maxTime = moment()
        .utcOffset(0, true)
        .add(1, "minute")
        .toISOString()
        .slice(0, -1);
    const minTime = moment()
        .utcOffset(0, true)
        .add(-1 * timeWindow, "minutes")
        .toISOString()
        .slice(0, -1);

    return (
        <Grid container className={classes.graphContainer}>
            {legendFilteredData ? (
                <Scatter
                    data={() => getRealTimeDataSet(legendFilteredData)}
                    options={{
                        maintainAspectRatio: false,
                        animation: {
                            duration: 0, // general animation time
                        },
                        hover: {
                            animationDuration: 0, // duration of animations when hovering an item
                        },
                        responsiveAnimationDuration: 0, // animation duration after a resize
                        responsive: true,
                        legend: {
                            display: false,
                        },

                        tooltips: {
                            callbacks: {
                                label(tooltipItem, data) {
                                    let label = "undefined";
                                    const found = data.datasets.find(
                                        (dataset) =>
                                            dataset.data.find(
                                                (point) =>
                                                    point.x ===
                                                        tooltipItem.xLabel &&
                                                    point.y ===
                                                        tooltipItem.yLabel
                                            )
                                    );
                                    if (found !== undefined) {
                                        label = found.label;
                                    }
                                    const y = tooltipItem.yLabel;
                                    const date = moment.utc(tooltipItem.xLabel);
                                    return `${label}\n(${dateToRealTimeString(
                                        date
                                    )}, ${y})`;
                                },
                            },
                        },
                        scales: {
                            xAxes: [
                                {
                                    type: "time",
                                    stacked: false,

                                    time: {
                                        unit: "minute",
                                        min: minTime,
                                        max: maxTime,
                                    },
                                    displayFormats: {
                                        minute: "HH:mm",
                                    },

                                    gridLines: {
                                        zeroLineColor: "transparent",
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    display: true,
                                    stacked: true,
                                    position: "right",
                                    id: "y-axis-0",
                                    text: "PAI",
                                },
                                {
                                    stacked: true,
                                    position: "left",
                                    id: "y-axis-1",
                                    ticks: {
                                        min: minHR,
                                        max: maxHR,
                                    },
                                    text: "HR",
                                },
                                {
                                    display: true,
                                    stacked: true,
                                    position: "right",
                                    id: "y-axis-2",
                                    text: "HRV",
                                },
                                {
                                    stacked: true,
                                    position: "left",
                                    id: "y-axis-3",
                                    ticks: {
                                        min: minSpO2,
                                        max: maxSpO2,
                                        stepSize: 1,
                                    },
                                },
                            ],
                        },
                    }}
                />
            ) : (
                <Typography>Geen data beschikbaar</Typography>
            )}
        </Grid>
    );
};

const realtimeValues: (keyof Filters["realTime"])[] = [
    "heart_rate_nonin_values",
    "spo2_values",
    "pai_values",
    "hrv_values",
];

export const NoninRealTimeGraphFooter: React.FC<NoninRealTimeGraphFooterProps> =
    ({ handleFilterChange, filters }) => {
        const classes = styles({});
        return (
            <Grid
                container
                justify="flex-end"
                className={classes.tableFooterCell}
            >
                {realtimeValues.map((realtimeValue) => (
                    <ValuesFilterFormControl
                        value={realtimeValue}
                        type="realTime"
                        filters={filters}
                        onChange={handleFilterChange}
                    />
                ))}
            </Grid>
        );
    };
