import { Moment } from "moment";
import {
    DetailedNightData,
    DipArrays,
    GraphDataObject,
} from "types/patientdata";

export const calculateOxygenDesaturationIndex = (
    data: DetailedNightData | undefined,
    odiPercent: number
) => {
    if (data && data.spo2_minimum_values.data.length > 1) {
        let count = 0;
        data.spo2_minimum_values.data.forEach((minimumValue, index) => {
            if (
                data.spo2_average_values.data[index - 1] &&
                data.spo2_average_values.data[index - 1].y - minimumValue.y >=
                    odiPercent
            ) {
                count += 1;
            }
        });
        return count;
    }
    return 0;
};

export const calculateAmountOfDips = (
    limit: number | string,
    data: DetailedNightData | undefined,
    key: keyof DipArrays,
    over?: boolean
): number => {
    const newLimit = limit === "" ? 0 : limit;
    if (data) {
        let count = 0;
        if (over) {
            data[key]?.data.forEach((value) => {
                if (value.y > newLimit) {
                    count += 1;
                }
            });
        } else {
            data[key]?.data.forEach((value) => {
                if (value.y < newLimit) {
                    count += 1;
                }
            });
        }
        return count;
    }
    return 0;
};

export const calculateMinimumFromGraphObject = (
    data: GraphDataObject | undefined
): number | undefined => {
    if (data) {
        if (data.data.length !== 0) {
            let minimum = data.data[0].y;
            data.data.forEach((value) => {
                if (value.y < minimum) {
                    minimum = value.y;
                }
            });

            return minimum;
        }
    }
    return undefined;
};

export const calculateAverageFromGraphObject = (
    data: GraphDataObject | undefined
): number | undefined => {
    if (data) {
        let total = 0;
        data.data.forEach((value) => {
            total += value.y;
        });
        return total / data.data.length;
    }
    return undefined;
};

export const calculateAmountOfDipsRealtime = (
    limit: number | string,
    data: GraphDataObject | undefined
): number => {
    const newLimit = limit === "" ? 0 : limit;
    if (data) {
        let count = 0;
        data.data.forEach((value) => {
            if (value.y < newLimit) {
                count += 1;
            }
        });
        return count;
    }
    return 0;
};

export const calculateDipsPerHour = (
    dips: number,
    startTime: Moment,
    endTime: Moment
) => {
    const totalTime = endTime.valueOf() - startTime.valueOf();
    if (totalTime === 0) {
        return 0;
    }
    return dips / (totalTime / 1000 / 60 / 60);
};
