import localStorageManager from "data/localStorageManager";
import { UserDispatches, UserState } from "./user.types";
import UserActions from "./user.constants";

export const clearState: UserState = {
    user: {
        isLoggedIn: false,
        organisations: [],
        role: undefined,
        email: undefined,
        authToken: undefined,
    },
    currentOrganisations: [],
    error: false,
    manageableUsers: [],
};

const initialEmail = localStorageManager.getEmail();
const initialAuth = localStorageManager.getJWT();
const initialOrganisations = localStorageManager.getCurrentOrganisations();
const initialOrganisationsList = localStorageManager.getOrganisations();
const initialManageableUsers = localStorageManager.getManageableUsers();
const initialRole = localStorageManager.getRole();

export const initialState: UserState = {
    user: {
        isLoggedIn: initialEmail != null && initialAuth != null,
        organisations: initialOrganisationsList || [],
        role: initialRole || undefined,
        email: initialEmail || undefined,
        authToken: initialAuth || undefined,
    },
    currentOrganisations: initialOrganisations || [],
    error: false,
    manageableUsers: initialManageableUsers || [],
};

const userReducer = (
    state: UserState = initialState,
    action: UserDispatches
): UserState => {
    switch (action.type) {
        case UserActions.Login:
            return {
                ...state,
                user: action.payload.user,
                currentOrganisations: action.payload.currentOrganisations,
                error: false,
            };
        case UserActions.LoginError:
            return {
                ...state,
                user: action.payload.user,
                error: true,
            };
        case UserActions.SetCurrentOrganisation:
            return {
                ...state,
                currentOrganisations: action.payload.currentOrganisations,
            };
        case UserActions.GetManageableUsers:
            return {
                ...state,
                manageableUsers: action.payload.manageableUsers,
            };
        case UserActions.Logout:
            return {
                ...action.payload.state,
            };
        case UserActions.GetOrganisations:
            const newCurrentOrgs = state.currentOrganisations.map((x) => {
                const find = action.payload.organisations.find(
                    (y) => y.id === x.id
                );
                return find || x;
            });
            localStorageManager.setCurrentOrganisations(newCurrentOrgs);
            return {
                ...state,
                user: {
                    ...state.user,
                    organisations: action.payload.organisations,
                },
                currentOrganisations: newCurrentOrgs,
            };
        default:
            return state;
    }
};

export default userReducer;
