export const minutesToHourMinuteString = (
    totalMinutes: number,
    long = true
): string => {
    if (totalMinutes < 0) return "";
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    const hoursSuffix = "uur";
    const minutesSuffix = minutes === 1 ? "minuut" : "minuten";
    if (long)
        return `${
            hours !== 0 ? `${hours} ${hoursSuffix} ` : ""
        }${minutes} ${minutesSuffix}`;
    return `${hours !== 0 ? `${hours}:` : ""}${
        minutes > 9 ? minutes : `0${minutes}`
    }`;
};

export const phaseMinutesToString = (
    phaseMinutes: number,
    totalMinutes: number
): string => {
    const percentageNum = (phaseMinutes / totalMinutes) * 100;
    const percentage = ` (${percentageNum.toFixed(1)} %)`;
    return minutesToHourMinuteString(phaseMinutes) + percentage;
};
