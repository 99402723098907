import moment, { Moment } from "moment";

// This function changes Date to a day string (eg. Day/Month/Year) it is used to remove unnecessary timestamps from dates that represent average (day) data
export const dateToDayMonthYearString = (
    date: Moment,
    format: string
): string =>
    // Extracting the day, month and year numbers from the Date
    date.format(format);

// creates a readable string from the date string provided by the backend
export const dateStringToLocaleDateString = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString("default", {
        weekday: "short",
        month: "long",
        day: "numeric",
    })}`;
};

export const dateToTimeAndDateString = (date: Moment): string =>
    date.format("DD MMM, HH:mm");

export const dateToRealTimeString = (date: Moment): string =>
    date.format("HH:mm:ss");

export const dateToTimeString = (date: Moment): string => {
    const [hours, minutes] = [
        `${date.get("hour") < 10 ? `0${date.get("hour")}` : date.get("hour")}`,
        `${
            date.get("minute") < 10
                ? `0${date.get("minute")}`
                : date.get("minute")
        }`,
    ];
    return `${hours}:${minutes}`;
};

// een minute geleden, N minuten ago, an hour ago, N hours ago, a day ago, N days ago,
// a month ago, N months ago, a year ago, N years ago

export const calculateLastActive = (date: Moment | null): string => {
    if (date) {
        const now = moment();
        const differenceInMinutes = now.diff(date, "minutes");
        const differenceInHours = now.diff(date, "hours");
        const differenceInDays = now.diff(date, "days");
        const differenceInMonths = now.diff(date, "months");
        const differenceInYears = now.diff(date, "years");

        if (differenceInMinutes < 2) return "een minuut geleden";
        if (differenceInMinutes < 60)
            return `${Math.round(differenceInMinutes)} minuten geleden`;

        if (differenceInHours < 2) return "een uur geleden";
        if (differenceInHours < 23.5)
            return `${Math.round(differenceInHours)} uren geleden`;

        if (differenceInDays < 2) return "een dag geleden";
        if (differenceInMonths < 1)
            return `${Math.round(differenceInDays)} dagen geleden`;

        if (differenceInMonths < 2) return "een maand geleden";
        if (differenceInYears < 1)
            return `${Math.round(differenceInMonths)} maanden geleden`;

        if (differenceInYears < 2) return "een jaar geleden";
        return `${Math.round(differenceInYears)} jaren geleden`;
    }

    return "Nooit";
};

export const getDaysSinceDate = (date: Moment | null): number | null => {
    if (date) {
        const now = moment();
        const differenceInDays = now.diff(date, "days");
        return differenceInDays;
    }
    return null;
};

export const getWeighInNotification = (days: number | null) => {
    if (days === null) {
        return null;
    }
    if (days > 99) {
        return "99+";
    }
    return days;
};
