import React from "react";
import { Paper, Grid } from "@material-ui/core";
import { DetailedNightData, emptyGraphObject } from "types/patientdata";
import {
    getMaxHeartRateFromData,
    getMaxSpO2FromData,
    getMinHeartRateFromData,
    getMinSpO2FromData,
} from "helpers/graphdata";
import { PatientNightDetailsGraphProps } from "./PatientNightDetailsGraph.types";
import styles from "./PatientNightDetailsGraph.styles";
import {
    PatientNightDetailsGraphBody,
    PatientNightDetailsGraphFooter,
    PatientNightDetailsGraphHeader,
} from "./PatientNightDetailsGraph.components";

const PatientNightDetailsGraph: React.FC<PatientNightDetailsGraphProps> = ({
    handleDateChange,
    handleInputChange,
    handleBlur,
    changeDipData,
    amountOfDips,
    dipData,
    changeNight,
    timeFilteredData,
    times,
    patientName,
    filters,
    handleFilterChange,
    loadingData,
}) => {
    const classes = styles({});

    const legendFilteredData: DetailedNightData | undefined = {
        heart_rate_nonin_values: emptyGraphObject,
        spo2_minimum_values: emptyGraphObject,
        spo2_average_values: emptyGraphObject,
        hrv_values: emptyGraphObject,
        pai_values: emptyGraphObject,
        heart_rate_fitbit_values: emptyGraphObject,
        activity_fitbit_values: emptyGraphObject,
        sleep_stage_values: emptyGraphObject,
        cumulative_activity_fitbit_values: emptyGraphObject,
    };
    if (timeFilteredData) {
        Object.keys(timeFilteredData).map((key) => {
            if (filters.detailed[key] === true) {
                legendFilteredData[key] = timeFilteredData[key];
                return true;
            }
            return false;
        });
    }
    const minHR = getMinHeartRateFromData(
        5,
        legendFilteredData.heart_rate_nonin_values.data,
        legendFilteredData.heart_rate_fitbit_values?.data
    );
    const maxHR = getMaxHeartRateFromData(
        5,
        legendFilteredData.heart_rate_nonin_values.data,
        legendFilteredData.heart_rate_fitbit_values?.data
    );
    const minSpO2 = getMinSpO2FromData(
        5,
        legendFilteredData.spo2_minimum_values.data,
        legendFilteredData.spo2_average_values.data
    );
    const maxSpO2 = getMaxSpO2FromData(
        5,
        legendFilteredData.spo2_minimum_values.data,
        legendFilteredData.spo2_minimum_values.data
    );

    return (
        <Paper className={classes.paper}>
            <Grid container>
                <PatientNightDetailsGraphHeader
                    handleDateChange={handleDateChange}
                    changeNight={changeNight}
                    times={times}
                    patientName={patientName}
                />
                <PatientNightDetailsGraphBody
                    legendFilteredData={legendFilteredData}
                    times={times}
                    minHR={minHR}
                    maxHR={maxHR}
                    minSpO2={minSpO2}
                    maxSpO2={maxSpO2}
                    loadingData={loadingData}
                />
                <PatientNightDetailsGraphFooter
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    dipData={dipData}
                    amountOfDips={amountOfDips}
                    changeDipData={changeDipData}
                    handleInputChange={handleInputChange}
                    handleBlur={handleBlur}
                />
            </Grid>
        </Paper>
    );
};

export default PatientNightDetailsGraph;
