import React, { ReactNode } from "react";
import { Redirect } from "react-router-dom";
import localStorageManager from "data/localStorageManager";

export const isAuthenticated = () => {
    const token = localStorageManager.getJWT();
    const email = localStorageManager.getEmail();
    return token && email;
};
export const requireAuthWithMatch =
    (Component: any) =>
    ({ match }) =>
        !isAuthenticated() ? (
            <Redirect to="/login" />
        ) : (
            <Component match={match} />
        );
export const requireAuth = (Component: any) => (): ReactNode =>
    !isAuthenticated() ? <Redirect to="/login" /> : <Component />;
export const loggedInRedirect = (Component: any) => (): ReactNode =>
    isAuthenticated() ? <Redirect to="/" /> : <Component />;

export const getAuthHeader = () => ({
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
});
