import moment from "moment";
import { Organisation, Role, ManageableUser } from "types/user";
import { Times } from "screens/PatientNightDetails/PatientNightDetails.types";
import { Dates } from "screens/PatientHome/PatientHome.types";

const localStorageManager = {
    getJWT: () => localStorage.getItem("jwt"),
    setJWT: (value: string) => localStorage.setItem("jwt", value),
    getEmail: () => localStorage.getItem("email"),
    setEmail: (value: string) => localStorage.setItem("email", value),
    getRole: () => {
        const roleString = localStorage.getItem("role");
        if (roleString) {
            return Role[roleString];
        }
        return undefined;
    },
    setRole: (value: string) => localStorage.setItem("role", value),
    getCurrentOrganisations: () => {
        const currentOrganisations = localStorage.getItem(
            "currentOrganisations"
        );
        if (currentOrganisations) {
            return JSON.parse(currentOrganisations);
        }
        return [];
    },
    setCurrentOrganisations: (organisations: Organisation[]) =>
        localStorage.setItem(
            "currentOrganisations",
            JSON.stringify(organisations)
        ),
    getManageableUsers: () => {
        const manageableUsers = localStorage.getItem("manageableUsers");
        if (manageableUsers) {
            return JSON.parse(manageableUsers);
        }
        return [];
    },
    setManageableUsers: (manageableUsers: ManageableUser[]) =>
        localStorage.setItem(
            "manageableUsers",
            JSON.stringify(manageableUsers)
        ),
    getOrganisations: () => {
        const organisations = localStorage.getItem("organisations");
        if (organisations) {
            return JSON.parse(organisations);
        }
        return [];
    },
    setOrganisations: (values: Organisation[]) =>
        localStorage.setItem("organisations", JSON.stringify(values)),
    setDates: (dates: Dates, patient: string) => {
        if (patient !== "login")
            localStorage.setItem(`${patient}dates`, JSON.stringify(dates));
    },
    getDates: (patient: string): Dates | undefined => {
        const dates = localStorage.getItem(`${patient}dates`);
        if (dates) {
            const parsed = JSON.parse(dates);
            return {
                startDate: moment.utc(parsed.startDate),
                endDate: moment.utc(parsed.endDate),
            };
        }
        return undefined;
    },
    setTimes: (times: Times, patient: string) => {
        if (patient !== "login")
            localStorage.setItem(`${patient}times`, JSON.stringify(times));
    },
    getTimes: (patient: string): Times | undefined => {
        const times = localStorage.getItem(`${patient}times`);
        if (times) {
            const parsed = JSON.parse(times);
            return {
                startTime: moment.utc(parsed.startTime),
                endTime: moment.utc(parsed.endTime),
            };
        }
        return undefined;
    },
    clear: () => localStorage.clear(),
};

export default localStorageManager;
