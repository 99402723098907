import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles({
    root: { margin: "1rem", padding: "1rem" },
    grid: { minWidth: "50vw" },
    textField: {
        width: "40vw",
        margin: "10px 0",
        background: "#FAFAFA",
        borderRadius: "10px 10px 10px 10px",
        "& .PrivateNotchedOutline-root-32": {
            borderWidth: "2px !important",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            fontSize: "16px",
            color: "#FAFAFA",
            background: "#3779AE",
            transform: "translate(8px, -6px) scale(1.2)",
        },
    },
    label: {},
    input: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "29px",
        color: "#585858",
    },
});
