import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    div: {
        padding: "64px 50px",
        minHeight: "calc(100vh - 64px)",
        [theme.breakpoints.up("lg")]: {
            padding: "65px 100px",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "64px 140px",
        },
    },
    title: { height: "40px", marginBottom: "20px" },
    titleText: { fontWeight: 700, color: "#044272", marginBottom: "20px" },
    link: { display: "flex", color: "#044272", fontWeight: 700 },
    currentPageBreadCrumb: { display: "flex", color: "#044272" },
    icon: { width: 20, height: 20 },
    divider: { color: "#044272", fontWeight: 700 },
}));
