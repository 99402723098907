import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    popup: { width: "360px" },
    formTitle: { fontWeight: 700, textAlign: "center" },
    topContainer: { marginBottom: "30px" },
    formContainer: { margin: "20px 0 30px" },
    formText: {
        textAlign: "center",
    },
    formIcon: {
        color: "#DB303F",
        width: "100px",
        height: "100px",
        textAlign: "center",
    },
    editFormIcon: {
        color: "#044272",
        width: "100px",
        height: "100px",
        textAlign: "center",
    },
    textField: {
        width: "100%",
        margin: "10px 0",
        "& .MuiFilledInput-root": {
            background: "#FAFAFA",
            borderRadius: "10px 10px 0px 0px",
        },
        "& .MuiFilledInput-input": {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "29px",
            color: "#585858",
        },
    },
    formLabel: { padding: "0 5px", zIndex: 100 },

    tableHeaderItem: {
        color: "#044272",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "32px",
        alignItems: "center",
        display: "flex",
    },
    deleteItem: {
        color: "#044272",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "32px",
        alignItems: "center",
        display: "flex",
    },
    tableheader: {
        height: "52px",
        width: "1160px",
        boxShadow: "inset 0px -1px 0px rgba(4, 66, 114, 0.16)",
    },
    headerIcon: { margin: "0 5px", width: "30px", height: "30px" },
    button: {
        padding: 0,
        textTransform: "revert",
        justifyContent: "revert",
        minWidth: "30px",
        minHeight: "30px",
    },
    icon: { width: "30px", height: "30px", margin: "0 5px" },
    footer: {
        color: "#044272",
        "& .MuiTablePagination-caption": { fontWeight: 600 },
    },
    organisationName: {
        fontWeight: 700,
        fontSize: "16px",
        alignItems: "center",
        display: "flex",
        padding: "0 4px",
    },
    type: {
        fontSize: "13px",
        backgroundColor: "rgb(187,186,186)",
        color: "white",
        marginLeft: "6px",
        borderRadius: "3px",
        padding: "0 4px",
        fontWeight: 700,
    },
    avatarIcon: {
        backgroundColor: "#044272",
    },
    avatar: {
        paddingRight: "6px",
    },
});
