import moment, { Moment } from "moment";
import { DetailedNightData, ValueAndTime } from "types/patientdata";

const filterValueAndTimeArrayByTime = (
    array: ValueAndTime[] | undefined,
    startTime: Moment,
    endTime: Moment
): ValueAndTime[] => {
    if (array) {
        const result = array.filter((item) => {
            const dateTime = moment.utc(item.x).valueOf();
            return (
                dateTime >= startTime.valueOf() && dateTime <= endTime.valueOf()
            );
        });
        return result;
    }
    return [];
};

const filterCumulativeActivityArrayByTime = (
    array: ValueAndTime[] | undefined,
    startTime: Moment,
    endTime: Moment
) => {
    if (array) {
        let lastEntryBeforeStartTime = 0;
        const result = array.filter((item) => {
            const dateTime = moment.utc(item.x).valueOf();
            if (dateTime <= startTime.valueOf()) {
                lastEntryBeforeStartTime = item.y;
            }
            return (
                dateTime >= startTime.valueOf() && dateTime <= endTime.valueOf()
            );
        });

        result.unshift({
            x: startTime.toISOString().slice(0, -1),
            y: lastEntryBeforeStartTime,
        });
        result.push({
            x: endTime.toISOString().slice(0, -1),
            y: result[result.length - 1].y,
        });

        return result;
    }
    return [];
};

export const filterDetailedNightDataByTime = (
    detailedNightData: DetailedNightData | undefined,
    startTime: Moment,
    endTime: Moment
): DetailedNightData | undefined => {
    if (detailedNightData) {
        const result: DetailedNightData = {
            heart_rate_nonin_average:
                detailedNightData.heart_rate_nonin_average,
            spo2_average_average: detailedNightData.spo2_average_average,
            spo2_minimum_minimum: detailedNightData.spo2_minimum_minimum,
            heart_rate_fitbit_average:
                detailedNightData.heart_rate_fitbit_average,
            total_sleeptime_fitbit: detailedNightData.total_sleeptime_fitbit,
            sleep_efficiency_fitbit: detailedNightData.sleep_efficiency_fitbit,
            heart_rate_nonin_values: {
                ...detailedNightData.heart_rate_nonin_values,
                data: filterValueAndTimeArrayByTime(
                    detailedNightData.heart_rate_nonin_values.data,
                    startTime,
                    endTime
                ),
            },
            spo2_average_values: {
                ...detailedNightData.spo2_average_values,
                data: filterValueAndTimeArrayByTime(
                    detailedNightData.spo2_average_values.data,
                    startTime,
                    endTime
                ),
            },
            spo2_minimum_values: {
                ...detailedNightData.spo2_minimum_values,
                data: filterValueAndTimeArrayByTime(
                    detailedNightData.spo2_minimum_values.data,
                    startTime,
                    endTime
                ),
            },
            pai_values: {
                ...detailedNightData.pai_values,
                data: filterValueAndTimeArrayByTime(
                    detailedNightData.pai_values.data,
                    startTime,
                    endTime
                ),
            },
            hrv_values: {
                ...detailedNightData.hrv_values,
                data: filterValueAndTimeArrayByTime(
                    detailedNightData.hrv_values.data,
                    startTime,
                    endTime
                ),
            },
        };
        if (detailedNightData.sleep_stage_values)
            result.sleep_stage_values = {
                ...detailedNightData.sleep_stage_values,
                data: filterValueAndTimeArrayByTime(
                    detailedNightData.sleep_stage_values.data,
                    startTime,
                    endTime
                ),
            };
        if (detailedNightData.heart_rate_fitbit_values)
            result.heart_rate_fitbit_values = {
                ...detailedNightData.heart_rate_fitbit_values,
                data: filterValueAndTimeArrayByTime(
                    detailedNightData.heart_rate_fitbit_values.data,
                    startTime,
                    endTime
                ),
            };
        if (detailedNightData.activity_fitbit_values)
            result.activity_fitbit_values = {
                ...detailedNightData.activity_fitbit_values,
                data: filterValueAndTimeArrayByTime(
                    detailedNightData.activity_fitbit_values.data,
                    startTime,
                    endTime
                ),
            };
        if (detailedNightData.cumulative_activity_fitbit_values)
            result.cumulative_activity_fitbit_values = {
                ...detailedNightData.cumulative_activity_fitbit_values,
                data: filterCumulativeActivityArrayByTime(
                    detailedNightData.cumulative_activity_fitbit_values.data,
                    startTime,
                    endTime
                ),
            };
        return result;
    }
    return undefined;
};
