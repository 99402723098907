import React, { useState } from "react";
import { Dialog, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { PersonAdd } from "@material-ui/icons";
import RoundedButton from "components/RoundedButton/RoundedButton";
import { createUser } from "store/user/user.actions";
import UserTable from "components/UserTable/UserTable";
import AddUserPopup from "components/AddUserPopup/AddUserPopup";
import { Role } from "types/user";
import ReduxState from "store/store.types";
import styles from "./UserManagement.styles";
import { InputFieldValues, UserManagementProps } from "./UserManagement.types";

const clearInputValues: InputFieldValues = {
    user_name: "",
    email: "",
    password: "",
    role: "",
    organisation_id: "",
};

const UserManagement: React.FC<UserManagementProps> = ({
    createUser,
    currentOrganisations,
    organisations,
    userRole,
}) => {
    const classes = styles();
    const [popup, setPopup] = useState(false);
    const [inputFieldValues, setInputFieldValues] =
        useState<InputFieldValues>(clearInputValues);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const handlePopupClose = () => {
        setInputFieldValues(clearInputValues); // TODO: set to current org (from store)
        setError("");
        setPopup(false);
    };
    const handleInputFieldChange =
        (prop: keyof InputFieldValues) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setInputFieldValues({
                ...inputFieldValues,
                [prop]: event.target.value,
            });
        };
    const handleOrganisationSelectChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setInputFieldValues({
            ...inputFieldValues,
            organisation_id: event.target.value,
        });
    };
    const handleRoleSelectChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setInputFieldValues({
            ...inputFieldValues,
            role: event.target.value,
        });
    };

    const handleShowPassword = () => setShowPassword(!showPassword);
    const handlePopupOpen = () => {
        setPopup(true);
    };
    const addUser = async () => {
        const { user_name, email, password, role, organisation_id } =
            inputFieldValues;
        if (
            user_name !== "" &&
            email !== "" &&
            role !== "" &&
            password !== "" &&
            organisation_id !== ""
        ) {
            if (userRole === "admin") {
                try {
                    await createUser({
                        user_name,
                        email,
                        password,
                        role: role as Role,
                        organisation_id: organisation_id as number,
                    });
                    handlePopupClose();
                } catch (e) {
                    setError(
                        `Gebruiker ${user_name} bestaat al binnen organisatie`
                    );
                }
            } else if (userRole === "manager") {
                try {
                    await createUser({
                        user_name,
                        email,
                        password,
                        role: role as Role,
                        organisation_id: currentOrganisations[0].id,
                    });
                    handlePopupClose();
                } catch (e) {
                    setError(
                        `Gebruiker ${user_name} bestaat al binnen organisatie`
                    );
                }
            }
        } else {
            setError("Vul aub alle velden in");
        }
    };
    return (
        <Grid className={classes.div}>
            <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.title}
            >
                <Grid item>
                    <Typography variant="h4" className={classes.titleText}>
                        Gebruikers
                    </Typography>
                </Grid>
                <Grid item>
                    <RoundedButton
                        text="Toevoegen van gebruiker"
                        onClick={handlePopupOpen}
                        color="darkBlue"
                        Icon={PersonAdd}
                    />
                    <Dialog open={popup} onClose={handlePopupClose}>
                        <AddUserPopup
                            addUser={addUser}
                            errorText={error}
                            inputFieldValues={inputFieldValues}
                            showPassword={showPassword}
                            handleShowPassword={handleShowPassword}
                            handleInputFieldChange={handleInputFieldChange}
                            handlePopupClose={handlePopupClose}
                            handleOrganisationSelectChange={
                                handleOrganisationSelectChange
                            }
                            handleRoleSelectChange={handleRoleSelectChange}
                            organisations={organisations}
                            role={userRole}
                            currentOrganisations={currentOrganisations}
                        />
                    </Dialog>
                </Grid>
            </Grid>
            <UserTable />
        </Grid>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    organisations: state.user.user.organisations,
    currentOrganisations: state.user.currentOrganisations,
    userRole: state.user.user.role,
});
const mapDispatchToProps = {
    createUser,
};
const ConnectedUserManagement = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserManagement);

export default ConnectedUserManagement;
