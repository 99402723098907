import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    tableTitle: {
        fontWeight: 700,
        color: "#044272",
        alignSelf: "center",
    },
    tableHeaderItem: {
        color: "#044272",
        fontWeight: 700,
        size: "16px",
        lineHeight: "32px",
        alignItems: "center",
        display: "flex",
    },
    options: {
        color: "#044272",
        fontWeight: 700,
        size: "16px",
        lineHeight: "32px",
    },
    tableheader: {
        height: "52px",
        width: "1160px",
        boxShadow: "inset 0px -1px 0px rgba(4, 66, 114, 0.16)",
    },
    headerIcon: {
        margin: "0 5px",
        marginTop: "10px",
        width: "30px",
        height: "30px",
    },
    searchContainer: { margin: "25px 0" },
    tableCellItem: {
        fontWeight: 700,
        size: "16px",
        lineHeight: "32px",
        alignItems: "center",
        display: "flex",
    },
    organisationText: {
        color: "rgb(187,186,186)",
        fontWeight: 600,
        size: "16px",
        lineHeight: "32px",
        alignItems: "center",
        display: "flex",
    },
    button: {
        padding: 0,
        textTransform: "revert",
        justifyContent: "revert",
        minWidth: "30px",
        minHeight: "30px",
    },
    optionsButton: {
        padding: 0,
        textTransform: "revert",
        justifyContent: "flex-end",
        color: "#C9CFDA",
    },
    icon: { width: "30px", height: "30px", margin: "0 5px" },
    footer: {
        color: "#044272",
        "& .MuiTablePagination-caption": { fontWeight: 600 },
    },
    tableCell: {
        cursor: "pointer",
        padding: "0 16px",
        "&:hover": { backgroundColor: "rgb(239,239,239)" },
    },
    generalCell: { padding: "0 16px " },
    flagCell: { padding: "8px 16px" },
    userTitle: {
        fontWeight: 700,
        fontSize: "16px",
        alignItems: "center",
        display: "flex",
    },
    userSubtitle: {
        fontSize: "13px",
        alignItems: "center",
        display: "flex",
        color: "#969798",
    },
    flagContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: "5px",
    },
    flagTitle: {
        fontSize: 14,
        size: "16px",
        color: "#044272",
        fontWeight: 700,
    },
    avatarIcon: {
        backgroundColor: "#044272",
    },
    avatar: {
        paddingRight: "6px",
    },
    roleText: {
        fontSize: "13px",
        backgroundColor: "rgb(187,186,186)",
        color: "white",
        marginLeft: "6px",
        borderRadius: "3px",
        padding: "0 4px",
        fontWeight: 700,
    },
    tableCellText: {
        fontWeight: 400,
        color: "#969798",
    },
    fitbitIcon: {
        width: "20px",
        height: "20px",
        marginLeft: "5px",
    },
    noninIcon: {
        height: "22px",
        marginLeft: "5px",
    },
    sortIconTop: {
        height: "30px",
        width: "30px",
        position: "absolute",
        top: "-16px",
        left: "30%",
    },
    sortIconBottom: {
        height: "30px",
        width: "30px",
        position: "absolute",
        top: "20px",
        left: "30%",
    },
    sortIconContainer: {
        height: "0px",
        width: "0px",
    },
});
