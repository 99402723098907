import React from "react";
import {
    Avatar,
    Button,
    Grid,
    InputBase,
    Paper,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles,
} from "@material-ui/core";
import {
    Delete,
    DeleteForever,
    Person,
    Search,
    Sort,
} from "@material-ui/icons";
import moment from "moment";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import { calculateLastActive } from "helpers/dateToString";
import {
    UserTableBodyProps,
    UserTableFooterProps,
    UserTableHeaderProps,
} from "./UserTable.types";
import styles from "./UserTable.styles";

import RoundedButton from "../RoundedButton/RoundedButton";

export const UserTableHeader: React.FC<UserTableHeaderProps> = ({
    search,
    setSearch,
    changeSorting,
}) => {
    const classes = styles();

    return (
        <TableHead className={classes.tableheader}>
            <TableRow>
                <TableCell align="left" style={{ minWidth: "60px" }}>
                    <Button
                        className={classes.button}
                        onClick={() => changeSorting("username")}
                    >
                        <Typography className={classes.tableHeaderItem}>
                            <Sort className={classes.icon} />
                        </Typography>
                    </Button>
                </TableCell>
                <TableCell align="left" style={{ minWidth: "300px" }}>
                    <Paper component="form" className={classes.tableHeaderItem}>
                        <Search className={classes.headerIcon} />
                        <InputBase
                            placeholder="Zoeken..."
                            value={search}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setSearch(e.target.value)}
                        />
                    </Paper>
                </TableCell>
                <TableCell align="left" style={{ minWidth: "146px" }}>
                    <Button
                        className={classes.button}
                        onClick={() => changeSorting("organisation")}
                    >
                        <Typography className={classes.tableHeaderItem}>
                            Organisatie
                            <Sort className={classes.headerIcon} />
                        </Typography>
                    </Button>
                </TableCell>
                <TableCell align="left" style={{ minWidth: "150px" }}>
                    <Button
                        className={classes.button}
                        onClick={() => changeSorting("lastActive")}
                    >
                        <Typography className={classes.tableHeaderItem}>
                            Laatst ingelogd
                            <Sort className={classes.headerIcon} />
                        </Typography>
                    </Button>
                </TableCell>
                <TableCell align="right" style={{ minWidth: "180px" }}>
                    <Typography className={classes.deleteItem}>
                        Verwijder gebruiker
                        <Delete />
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

const StyledTableRow = withStyles({
    root: {
        height: "65px",
        "&:nth-of-type(even)": {
            backgroundColor: "#f8f9fc",
        },
    },
})(TableRow);

export const UserTableBody: React.FC<UserTableBodyProps> = ({
    manageableUsers,
    page,
    rowsPerPage,
    handlePopup,
}) => {
    const classes = styles();

    return (
        <TableBody>
            {manageableUsers.length !== 0 ? (
                (rowsPerPage > 0
                    ? manageableUsers.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                      )
                    : manageableUsers
                ).map((manageableUser) => (
                    <StyledTableRow key={manageableUser.username}>
                        <TableCell align="left" colSpan={2}>
                            <Grid
                                container
                                direction="row"
                                alignItems="flex-start"
                            >
                                <Grid item className={classes.avatar}>
                                    <Avatar className={classes.avatarIcon}>
                                        <Person />
                                    </Avatar>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    direction="column"
                                    alignItems="flex-start"
                                    style={{ width: "auto" }}
                                >
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography
                                                className={classes.userTitle}
                                            >
                                                {manageableUser.username}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                className={classes.roleText}
                                            >
                                                {manageableUser.role.toUpperCase()}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            className={classes.userSubtitle}
                                        >
                                            {manageableUser.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell align="left">
                            <Typography className={classes.tableCellText}>
                                {manageableUser.organisation}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography className={classes.tableCellText}>
                                {calculateLastActive(
                                    manageableUser.lastActive
                                        ? moment.utc(manageableUser.lastActive)
                                        : null
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <RoundedButton
                                text="Verwijder"
                                onClick={() =>
                                    handlePopup(true, {
                                        user_name: manageableUser.username,
                                        email: manageableUser.email,
                                    })
                                }
                                color="red"
                                Icon={DeleteForever}
                            />
                        </TableCell>
                    </StyledTableRow>
                ))
            ) : (
                <StyledTableRow>
                    <TableCell colSpan={5}>Geen userdata beschikbaar</TableCell>
                </StyledTableRow>
            )}
        </TableBody>
    );
};

export const UserTableFooter: React.FC<UserTableFooterProps> = ({
    manageableUsers,
    handlePageChange,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
}) => {
    const classes = styles();

    return (
        <TableFooter>
            <TableRow>
                <TablePagination
                    count={manageableUsers.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    colSpan={5}
                    rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "Alle", value: -1 },
                    ]}
                    SelectProps={{
                        native: true,
                    }}
                    ActionsComponent={TablePaginationActions}
                    className={classes.footer}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to > 0 ? to : count} van ${count}`
                    }
                    labelRowsPerPage="Rijen per pagina"
                />
            </TableRow>
        </TableFooter>
    );
};
