import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { resetPasswordComplete } from "store/user/user.actions";
import { connect } from "react-redux";
import styles from "./NewPasswordForm.styles";
import {
    NewPasswordFormProps,
    PasswordValues,
    ShowPassword,
} from "./NewPasswordForm.types";
import RoundedButton from "../RoundedButton/RoundedButton";

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({
    resetPasswordComplete,
}) => {
    const token = useLocation().search.replace("?token=", "");
    const history = useHistory();
    const classes = styles();
    const [passwordValues, setPasswordValues] = useState<PasswordValues>({
        new: "",
        repeat: "",
    });
    const [showPassword, setShowPassword] = useState<ShowPassword>({
        new: false,
        repeat: false,
    });
    const [errors, setErrors] = useState<PasswordValues>({
        new: "",
        repeat: "",
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const handleTextFieldChange =
        (prop: keyof PasswordValues) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setPasswordValues({
                ...passwordValues,
                [prop]: event.target.value,
            });
        };
    const handleClickShowPassword = (prop: keyof ShowPassword) => {
        setShowPassword({
            ...showPassword,
            [prop]: !showPassword[prop],
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (passwordValues.new !== passwordValues.repeat) {
            setErrors({
                ...errors,
                repeat: "Beide wachtwoorden moeten gelijk zijn",
            });
        } else {
            try {
                setLoading(true);
                await resetPasswordComplete({
                    token,
                    password: passwordValues.new,
                });
                setLoading(false);
                setSuccess(true);
            } catch {
                setLoading(false);
                setErrors({
                    ...errors,
                    repeat: "Er is iets fout gegaan", // TODO: add error handling when it is implemented in api calls
                });
            }
        }
    };
    const backToLogin = () => history.push("/login");
    const canSendForm =
        passwordValues.new !== "" && passwordValues.repeat !== "";
    const newError = errors.new !== "";
    const repeatError = errors.repeat !== "";
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.grid}
            spacing={success ? 2 : 0}
        >
            {success ? (
                <>
                    <Grid item>
                        <Typography className={classes.subtitle}>
                            Wachtwoord is veranderd!
                        </Typography>
                    </Grid>
                    <Grid item>
                        <RoundedButton
                            text="Terug naar Login"
                            onClick={backToLogin}
                            color="darkBlue"
                            margin="center"
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item>
                        <TextField
                            id="outlined-password-new"
                            label="Nieuw Wachtwoord"
                            variant="outlined"
                            value={passwordValues.new}
                            autoFocus
                            onChange={handleTextFieldChange("new")}
                            className={classes.textField}
                            type={showPassword.new ? "text" : "password"}
                            InputProps={{
                                className: classes.input,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                handleClickShowPassword("new")
                                            }
                                            edge="end"
                                        >
                                            {showPassword.new ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={newError}
                            helperText={newError && errors.new}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="outlined-password-repeat"
                            label="Herhaal wachtwoord"
                            variant="outlined"
                            value={passwordValues.repeat}
                            onChange={handleTextFieldChange("repeat")}
                            className={classes.textField}
                            type={showPassword.repeat ? "text" : "password"}
                            InputProps={{
                                className: classes.input,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                handleClickShowPassword(
                                                    "repeat"
                                                )
                                            }
                                            edge="end"
                                        >
                                            {showPassword.repeat ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={repeatError}
                            helperText={repeatError && errors.repeat}
                        />
                    </Grid>
                    <Grid item>
                        <RoundedButton
                            text="Stel wachtwoord in"
                            onClick={handleSubmit}
                            color="darkBlue"
                            margin="center"
                            disabled={!canSendForm || loading}
                            loading={loading}
                        />
                    </Grid>
                    <Grid item className={classes.link} onClick={backToLogin}>
                        <Typography variant="subtitle1">
                            Terug naar Login
                        </Typography>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    resetPasswordComplete,
};
const ConnectedNewPasswordForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewPasswordForm);
export default ConnectedNewPasswordForm;
