import React, { useState } from "react";
import {
    CircularProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { phaseMinutesToString, minutesToHourMinuteString } from "helpers/time";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {
    ChangeHeartRateDipData,
    DipArrayKeyStrings,
    HandleInputChange,
    HeartRateDipData,
    PatientNightDetailsTableProps,
} from "./PatientNightDetailsTable.types";
import styles from "./PatientNightDetailsTable.styles";
import DetailedTableRow from "../DetailedTableRow/DetailedTableRow";
import { floatToDouble } from "../../helpers/floatToDouble";
import { HandleDipChange } from "../../screens/PatientNightDetails/PatientNightDetails.types";
import { HeartRateLimitCell } from "./PatientNightDetailsTable.components";
import { calculateAmountOfDips } from "../../helpers/calculateOxygenDesaturationIndex";

const PatientNightDetailsTable: React.FC<PatientNightDetailsTableProps> = ({
    detailedNightData,
    oxygenDesaturationIndex,
    getSpo2MinDips,
    dipData,
    amountOfDips,
    loadingData,
    spo2Minimum,
    spo2Average,
}) => {
    const classes = styles();
    const [spo2DipLimit, setSpo2DipLimit] = useState(89);
    const [hrDips, setHrDips] = useState<HeartRateDipData>({
        type: "heart_rate_fitbit_values",
        upperLimit: 140,
        lowerLimit: 50,
    });
    const changeHrDipData: ChangeHeartRateDipData = (event) => {
        if (event.target.name && hrDips[event.target.name]) {
            setHrDips({
                ...hrDips,
                [event.target.name]: event.target.value,
            });
        }
    };
    const handleInputChange: HandleInputChange = (event, upper) => {
        if (upper) {
            setHrDips({
                ...hrDips,
                upperLimit:
                    event.target.value === "" ? "" : Number(event.target.value),
            });
        } else {
            setHrDips({
                ...hrDips,
                lowerLimit:
                    event.target.value === "" ? "" : Number(event.target.value),
            });
        }
    };
    const handleBlur = () => {
        if (hrDips.lowerLimit < 0) {
            setHrDips({
                ...hrDips,
                lowerLimit: 0,
            });
        } else if (hrDips.lowerLimit > hrDips.upperLimit) {
            setHrDips({
                ...hrDips,
                lowerLimit: Number(hrDips.upperLimit) - 1,
            });
        } else if (hrDips.upperLimit < hrDips.lowerLimit) {
            setHrDips({
                ...hrDips,
                upperLimit: Number(hrDips.lowerLimit) + 1,
            });
        }
    };
    const handleDipChange: HandleDipChange = (event, newDipLimit) =>
        setSpo2DipLimit(newDipLimit);
    const heartRateDipsAbove = () =>
        calculateAmountOfDips(
            hrDips.upperLimit,
            detailedNightData,
            hrDips.type,
            true
        );
    const heartRateDipsBelow = () =>
        calculateAmountOfDips(
            hrDips.lowerLimit,
            detailedNightData,
            hrDips.type
        );
    if (loadingData) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead className={classes.tableheader}>
                        <TableRow>
                            <TableCell
                                align="left"
                                className={classes.tableheaderCell}
                            >
                                <Typography className={classes.tableHeaderItem}>
                                    Stats
                                </Typography>
                            </TableCell>
                            <TableCell
                                align="left"
                                className={classes.tableheaderCell}
                            >
                                <Typography className={classes.tableHeaderItem}>
                                    Value
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {detailedNightData ? (
                            <>
                                <DetailedTableRow
                                    stat="SpO2 minimum"
                                    value={
                                        spo2Minimum
                                            ? `${spo2Minimum} %`
                                            : "Geen data beschikbaar"
                                    }
                                />
                                <DetailedTableRow
                                    stat="ODI"
                                    value={`${oxygenDesaturationIndex.toFixed(
                                        2
                                    )} keer per uur`}
                                />
                                <DetailedTableRow
                                    value={`${floatToDouble(
                                        getSpo2MinDips(spo2DipLimit)
                                    )}`}
                                >
                                    SpO2 min aantal dips onder{" "}
                                    <ToggleButtonGroup
                                        value={spo2DipLimit}
                                        exclusive
                                        onChange={handleDipChange}
                                        aria-label="text alignment"
                                    >
                                        <ToggleButton
                                            value={85}
                                            aria-label="left aligned"
                                        >
                                            84%
                                        </ToggleButton>
                                        <ToggleButton
                                            value={87}
                                            aria-label="center aligned"
                                        >
                                            86%
                                        </ToggleButton>
                                        <ToggleButton
                                            value={89}
                                            aria-label="right aligned"
                                        >
                                            88%
                                        </ToggleButton>
                                        <ToggleButton
                                            value={91}
                                            aria-label="justify"
                                        >
                                            90%
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </DetailedTableRow>
                                <DetailedTableRow
                                    stat="SpO2 gemiddeld"
                                    value={
                                        spo2Average
                                            ? `${floatToDouble(spo2Average)} %`
                                            : "Geen data beschikbaar"
                                    }
                                />
                                <DetailedTableRow
                                    stat={`Aantal dips van ${
                                        DipArrayKeyStrings[dipData.type]
                                    } onder ${dipData.limit}`}
                                    value={`${amountOfDips}`}
                                />
                                <DetailedTableRow
                                    stat="Hartslag gemiddeld Nonin"
                                    value={
                                        detailedNightData.heart_rate_nonin_average
                                            ? `${detailedNightData.heart_rate_nonin_average} bpm`
                                            : "Geen data beschikbaar"
                                    }
                                />
                                <DetailedTableRow
                                    stat="Hartslag gemiddeld Fitbit"
                                    value={
                                        detailedNightData.heart_rate_fitbit_average
                                            ? `${detailedNightData.heart_rate_fitbit_average} bpm`
                                            : "Geen data beschikbaar"
                                    }
                                />
                                <DetailedTableRow
                                    stat="Hartslag in rust Fitbit"
                                    value={
                                        detailedNightData.resting_heart_rate
                                            ? `${detailedNightData.resting_heart_rate} bpm`
                                            : "Geen data beschikbaar"
                                    }
                                />
                                <DetailedTableRow
                                    value={`${
                                        heartRateDipsAbove() +
                                        heartRateDipsBelow()
                                    } (boven:${heartRateDipsAbove()}, onder:${heartRateDipsBelow()})`}
                                >
                                    <HeartRateLimitCell
                                        hrDipData={hrDips}
                                        changeHeartRateDipData={changeHrDipData}
                                        handleInputChange={handleInputChange}
                                        handleBlur={handleBlur}
                                    />
                                </DetailedTableRow>
                                <DetailedTableRow
                                    stat="Totaal aantal stappen"
                                    value={
                                        detailedNightData.total_steps_fitbit
                                            ? `${detailedNightData.total_steps_fitbit} stappen`
                                            : "Geen data beschikbaar"
                                    }
                                />
                                <DetailedTableRow
                                    stat="Totale slaapduur"
                                    value={
                                        detailedNightData.total_sleeptime_fitbit
                                            ? `${minutesToHourMinuteString(
                                                  detailedNightData.total_sleeptime_fitbit
                                              )}`
                                            : "Geen data beschikbaar"
                                    }
                                />
                                <DetailedTableRow
                                    stat="Totaal wakker"
                                    value={
                                        detailedNightData.total_wake_sleep_fitbit &&
                                        detailedNightData.total_sleeptime_fitbit
                                            ? `${phaseMinutesToString(
                                                  detailedNightData.total_wake_sleep_fitbit,
                                                  detailedNightData.total_sleeptime_fitbit
                                              )}`
                                            : "Geen data beschikbaar"
                                    }
                                />
                                <DetailedTableRow
                                    stat="Totale REM slaap"
                                    value={
                                        detailedNightData.total_rem_sleep_fitbit &&
                                        detailedNightData.total_sleeptime_fitbit
                                            ? `${phaseMinutesToString(
                                                  detailedNightData.total_rem_sleep_fitbit,
                                                  detailedNightData.total_sleeptime_fitbit
                                              )}`
                                            : "Geen data beschikbaar"
                                    }
                                />
                                <DetailedTableRow
                                    stat="Totale lichte slaap"
                                    value={
                                        detailedNightData.total_light_sleep_fitbit &&
                                        detailedNightData.total_sleeptime_fitbit
                                            ? `${phaseMinutesToString(
                                                  detailedNightData.total_light_sleep_fitbit,
                                                  detailedNightData.total_sleeptime_fitbit
                                              )}`
                                            : "Geen data beschikbaar"
                                    }
                                />
                                <DetailedTableRow
                                    stat="Totale diepe slaap"
                                    value={
                                        detailedNightData.total_deep_sleep_fitbit &&
                                        detailedNightData.total_sleeptime_fitbit
                                            ? `${phaseMinutesToString(
                                                  detailedNightData.total_deep_sleep_fitbit,
                                                  detailedNightData.total_sleeptime_fitbit
                                              )}`
                                            : "Geen data beschikbaar"
                                    }
                                />
                            </>
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={2}
                                    className={classes.tableCell}
                                >
                                    <Typography
                                        className={classes.tableCellItem}
                                    >
                                        Geen patientdata beschikbaar
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default PatientNightDetailsTable;
