import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "normalize.css";

import App from "App";
import store from "store/store";

const AppWithProvider = () => (
    <Provider store={store}>
        <App />
    </Provider>
);

ReactDOM.render(<AppWithProvider />, document.getElementById("root"));
