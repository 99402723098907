import React from "react";
import {
    Avatar,
    Button,
    Checkbox,
    Grid,
    InputBase,
    Paper,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles,
} from "@material-ui/core";
import {
    Apartment,
    DeleteForever,
    Edit,
    Search,
    SortByAlpha,
} from "@material-ui/icons";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import {
    OrganisationTableBodyProps,
    OrganisationTableFooterProps,
    OrganisationTableHeaderProps,
} from "./OrganisationTable.types";
import styles from "./OrganisationTable.styles";
import RoundedButton from "../RoundedButton/RoundedButton";

export const OrganisationTableHeader: React.FC<OrganisationTableHeaderProps> =
    ({ search, setSearch, changeSorting }) => {
        const classes = styles();
        return (
            <TableHead className={classes.tableheader}>
                <TableRow>
                    <TableCell align="left" style={{ minWidth: "120px" }}>
                        <Button
                            className={classes.button}
                            onClick={() => changeSorting()}
                        >
                            <Typography className={classes.tableHeaderItem}>
                                <SortByAlpha className={classes.icon} />
                            </Typography>
                        </Button>
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: "300px" }}>
                        <Paper
                            component="form"
                            className={classes.tableHeaderItem}
                        >
                            <Search className={classes.headerIcon} />
                            <InputBase
                                placeholder="Zoeken..."
                                value={search}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setSearch(e.target.value)}
                            />
                        </Paper>
                    </TableCell>
                    <TableCell align="right" style={{ minWidth: "180px" }}>
                        <Typography className={classes.deleteItem}>
                            Activeer/Deactiveer fitbit
                        </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ minWidth: "180px" }}>
                        <Typography className={classes.deleteItem}>
                            Verander naam
                        </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ minWidth: "180px" }}>
                        <Typography className={classes.deleteItem}>
                            Verwijder organisatie
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    };

const StyledTableRow = withStyles({
    root: {
        height: "65px",
        "&:nth-of-type(even)": {
            backgroundColor: "#f8f9fc",
        },
    },
})(TableRow);

export const OrganisationTableBody: React.FC<OrganisationTableBodyProps> = ({
    filteredOrganisations,
    rowsPerPage,
    page,
    handlePopup,
    disableFitbit,
    enableFitbit,
}) => {
    const classes = styles();
    return (
        <TableBody>
            {filteredOrganisations.length !== 0 ? (
                (rowsPerPage > 0
                    ? filteredOrganisations.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                      )
                    : filteredOrganisations
                ).map((organisation) => (
                    <StyledTableRow key={organisation.id}>
                        <TableCell align="left" colSpan={2}>
                            <Grid
                                container
                                direction="row"
                                alignItems="flex-start"
                            >
                                <Grid item className={classes.avatar}>
                                    <Avatar className={classes.avatarIcon}>
                                        <Apartment />
                                    </Avatar>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    direction="column"
                                    alignItems="flex-start"
                                    style={{ width: "auto" }}
                                >
                                    <Grid item>
                                        <Typography
                                            className={classes.organisationName}
                                        >
                                            {organisation.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.type}>
                                            ORGANISATION
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                checked={organisation.enable_fitbit}
                                onChange={() => {
                                    if (organisation.enable_fitbit) {
                                        disableFitbit(organisation.id);
                                    } else {
                                        enableFitbit(organisation.id);
                                    }
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <RoundedButton
                                text="Verander"
                                onClick={() =>
                                    handlePopup(true, organisation, "rename")
                                }
                                color="darkBlue"
                                Icon={Edit}
                            />
                        </TableCell>
                        <TableCell>
                            <RoundedButton
                                text="Verwijder"
                                onClick={() =>
                                    handlePopup(true, organisation, "archive")
                                }
                                color="darkBlue"
                                Icon={DeleteForever}
                            />
                        </TableCell>
                    </StyledTableRow>
                ))
            ) : (
                <StyledTableRow>
                    <TableCell colSpan={4}>
                        Geen organisaties gevonden
                    </TableCell>
                </StyledTableRow>
            )}
        </TableBody>
    );
};

export const OrganisationTableFooter: React.FC<OrganisationTableFooterProps> =
    ({
        filteredOrganisations,
        handleChangeRowsPerPage,
        handlePageChange,
        page,
        rowsPerPage,
    }) => {
        const classes = styles();
        return (
            <TableFooter>
                <TableRow>
                    <TablePagination
                        count={filteredOrganisations.length}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        colSpan={5}
                        rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            { label: "Alle", value: -1 },
                        ]}
                        SelectProps={{
                            native: true,
                        }}
                        ActionsComponent={TablePaginationActions}
                        className={classes.footer}
                        labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to > 0 ? to : count} van ${count}`
                        }
                        labelRowsPerPage="Rijen per pagina"
                    />
                </TableRow>
            </TableFooter>
        );
    };
