export const settingKeysToNL = {
    maximum_steps_during_sleep: "Actief in slaap ondergrens (aantal stappen)",
    active_during_sleep_upper_range:
        "Actief in slaap bovengrens (aantal stappen)",
    days_for_heart_rate_calculation: "Opgelopen HR in rust (x dagen)",
    percentage_for_heart_rate_calculation: "Opgelopen HR in rust (%)",
    steps_to_bathroom: "Toilet (min. aantal stappen)",
    maximum_times_bathroom: "Toilet (min. episodes)",
    restless_sleep_duration_minutes:
        "Oppervlakkig slapen [tijd (min.) boven gemiddeld]",
    nap_duration_minutes: "Dutjes (min. aantal minuten)",
    temperature_upper_range: "Temperatuur bovengrens (graden °C)",
    temperature_lower_range: "Temperatuur ondergrens (graden °C)",
    weight_lower_range: "Dagelijks gewicht ondergrens (kg)",
    weight_upper_range: "Dagelijks gewicht bovengrens (kg)",
    starting_weight: "Start gewicht (kg)",
    weight_gained: "Gewicht aangekomen sinds start behandeling (%)",
    weight_lost: "Gewicht afgevallen sinds start behandeling (%)",
    days_since_weigh_in: "Tijd niet gewogen (x dagen)",
    days_for_sleep_phase_counter: "Slaapfasen teller (x dagen)",
    maximum_sleep_phase_nights: "Slaapfasen teller alarm (x dagen)",
    hrv_alarm_days: "HRV berekening periode (x dagen)",
    hrv_alarm_upper_range: "Gestegen HRV in periode bovengrens (%)",
    hrv_alarm_lower_range: "Gedaalde HRV in periode ondergrens (%)"
};

export const patientSettingKeyToString = (settingKey: string) => {
    if (settingKeysToNL[settingKey]) {
        return settingKeysToNL[settingKey];
    }
    return (
        settingKey.charAt(0).toUpperCase() +
        settingKey.replace(/_/g, " ").slice(1)
    );
};
