import { makeStyles } from "@material-ui/core/styles";
import pattern from "static/pattern";

export default makeStyles((theme) => ({
    root: {
        background: "#3779AE",
        backgroundImage: pattern,
        maxWidth: "100vw",
        height: "64px",
        color: "#FFFFFF",
        justifyContent: "space-between",
        alignItems: "flex-end",
        flexDirection: "row",
        padding: "0 50px",

        [theme.breakpoints.up("lg")]: {
            padding: "0 100px",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "0 140px",
        },
    },
    menuItems: { width: "450px", height: "60px" },
    menuItem: {
        color: "#FFF",
        fontWeight: "bold",
        fontStyle: "normal",
        textAlign: "center",
        textTransform: "revert",
        borderRadius: "revert",
        padding: "0px",
        paddingBottom: "3px",
        lineHeight: "20px",
        height: "38px",
    },
    logo: { width: "64px", "&:hover": { cursor: "pointer" } },
    logoImage: { width: "56px", height: "29px" },
    logoText: {
        fontWeight: "bold",
        fontStyle: "normal",
        textAlign: "center",
        fontSize: "18px",
        lineHeight: "16px",
        width: "64px",
        height: "26px",
    },
    organisationContainer: {
        color: "#FFF",
        fontWeight: "bold",
        fontStyle: "normal",
        textAlign: "center",
        textTransform: "revert",
        borderRadius: 0,
        padding: "5px",
        lineHeight: "20px",
        height: "38px",
        margin: "0 12px",
        borderBottom: "solid #fff 3px",
    },
    underlinedMenuItem: {
        color: "#FFF",
        fontWeight: "bold",
        fontStyle: "normal",
        textAlign: "center",
        textTransform: "revert",
        borderRadius: 0,
        padding: "5px",
        lineHeight: "20px",
        height: "38px",
        margin: "0 12px",

        borderBottom: "solid #fff 3px",
        "&:hover": {
            backgroundColor: "#2e6796",
            borderBottom: "solid #214b6c 4px",
        },
    },

    buttonRoot: (props: {
        selected?: any | ((element: any) => any) | null;
    }) => ({
        color: "#FFF",
        fontWeight: "bold",
        fontStyle: "normal",
        textAlign: "center",
        textTransform: "revert",
        borderRadius: 0,
        padding: "5px",
        lineHeight: "20px",
        height: "38px",
        margin: "0 12px",
        backgroundColor: props.selected ? "#2e6796" : "transparent",
        borderBottom: props.selected ? "solid #214b6c 3px" : "solid #fff 3px",
        "&:hover": {
            backgroundColor: "#2e6796",
            borderBottom: "solid #214b6c 3px",
        },
    }),
    buttonTitle: {
        color: "#FFF",
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        alignSelf: "flex-start",
    },
    userTitle: {
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        alignSelf: "flex-start",
    },
    buttonText: {
        color: "#FFF",
        fontWeight: "bold",
        fontSize: "14px",
        padding: 0,
        margin: 0,
        display: "flex",
        alignItems: "center",
        lineHeight: "15px",
        alignSelf: "flex-end",
    },
    arrowDropdown: {
        width: "15px",
        height: "15px",
    },
    userText: {
        fontWeight: "bold",
        fontSize: "14px",
        padding: 0,
        margin: 0,
        display: "flex",
        alignItems: "center",
        lineHeight: "15px",
        alignSelf: "flex-end",
    },
    checkbox: {
        "&.Mui-checked": {
            color: "#2e6796",
        },
    },
    popup: { width: "400px" },
    popupText: { fontWeight: "bold" },
    textField: {
        width: "100%",
        margin: "10px 0",
        "& .MuiFilledInput-root": {
            background: "#FAFAFA",
            borderRadius: "10px 10px 0px 0px",
        },
        "& .MuiFilledInput-input": {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "29px",
            color: "#585858",
        },
    },
    formLabel: { padding: "0 5px", zIndex: 100 },
    formTitle: { fontWeight: 700 },
    formContainer: { margin: "20px 0 30px" },
}));
