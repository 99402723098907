import React from "react";
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles({
    fitbitIcon: {
        width: "20px",
        height: "20px",
    },
});

const FitbitIcon: React.FC = () => {
    const classes = styles();
    return (
        <Avatar
            className={classes.fitbitIcon}
            src={require("static/logo-fitbit-icon.png")}
        />
    );
};

export const NoninIcon: React.FC = () => {
    const classes = styles();
    return (
        <Avatar
            className={classes.fitbitIcon}
            src={require("static/nonin-logo.png")}
        />
    );
};

export default FitbitIcon;
