import {
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    Grid,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles,
} from "@material-ui/core";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import {
    ArrowDropDown,
    ArrowDropUp,
    Person,
    SortByAlpha,
    NotificationImportant,
} from "@material-ui/icons";
import { renderFlags } from "components/PatientOverviewTable/PatientOverviewTable.components";
import TableOptionsMenu from "components/TableOptionsMenu/TableOptionsMenu";
import { calculateLastActive, getDaysSinceDate } from "helpers/dateToString";
import { minutesToHourMinuteString } from "helpers/time";
import moment from "moment";
import React, { useState } from "react";
import { PatientOverview } from "types/patientdata";
import RoundedButton from "components/RoundedButton/RoundedButton";
import { removeWeightAlarm } from "store/user/user.actions";
import styles from "./PatientTable.styles";
import {
    PatientTableBodyProps,
    PatientTableFooterProps,
    PatientTableHeaderProps,
    WeightCellProps,
} from "./PatientTable.types";

export const PatientTableHeader: React.FC<PatientTableHeaderProps> = ({
    changeSorting,
}) => {
    const classes = styles();

    return (
        <TableHead className={classes.tableheader}>
            <TableRow>
                <TableCell align="left" style={{ minWidth: "120px" }}>
                    <Button
                        className={classes.button}
                        onClick={() => changeSorting("name")}
                    >
                        <Typography className={classes.tableHeaderItem}>
                            <SortByAlpha className={classes.icon} />
                        </Typography>
                    </Button>
                </TableCell>
                <TableCell align="left" style={{ minWidth: "180px" }}>
                    <Typography className={classes.options}>
                        Meldingen
                    </Typography>
                </TableCell>
                <TableCell align="left" style={{ minWidth: "80px" }}>
                    <Button
                        className={classes.button}
                        onClick={() => changeSorting("steps_last_day")}
                    >
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropUp className={classes.sortIconTop} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.tableHeaderItem}>
                                    Stappen
                                </Typography>
                            </Grid>
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropDown
                                    className={classes.sortIconBottom}
                                />
                            </Grid>
                        </Grid>
                    </Button>
                </TableCell>
                <TableCell align="left" style={{ minWidth: "120px" }}>
                    <Button
                        className={classes.button}
                        onClick={() =>
                            changeSorting("sleep_duration_last_night")
                        }
                    >
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropUp className={classes.sortIconTop} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.tableHeaderItem}>
                                    Slaapduur
                                </Typography>
                            </Grid>
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropDown
                                    className={classes.sortIconBottom}
                                />
                            </Grid>
                        </Grid>
                    </Button>
                </TableCell>
                <TableCell align="left" style={{ minWidth: "160px" }}>
                    <Button
                        className={classes.button}
                        onClick={() => changeSorting("body_weight")}
                    >
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropUp className={classes.sortIconTop} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.tableHeaderItem}>
                                    Gewicht
                                </Typography>
                            </Grid>
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropDown
                                    className={classes.sortIconBottom}
                                />
                            </Grid>
                        </Grid>
                    </Button>
                </TableCell>
                <TableCell align="left" style={{ minWidth: "146px" }}>
                    <Button
                        className={classes.button}
                        onClick={() => changeSorting("lastFitbitEntry")}
                    >
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropUp className={classes.sortIconTop} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.tableHeaderItem}>
                                    Laatste invoer
                                </Typography>
                            </Grid>
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropDown
                                    className={classes.sortIconBottom}
                                />
                            </Grid>
                        </Grid>
                        <Avatar
                            className={classes.fitbitIcon}
                            src={require("static/logo-fitbit-iconblue.png")}
                            alt="Fitbit"
                        />
                    </Button>
                </TableCell>
                <TableCell align="right" style={{ minWidth: "64px" }}>
                    <Typography className={classes.options}>Opties</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

const RenderFlags: React.FC<{
    flags?: string[];
    title: string;
    day?: boolean;
    daysSinceWeighIn?: number | null;
    sleepPhaseCounter?: number | null;
}> = ({ flags, title, day, daysSinceWeighIn, sleepPhaseCounter }) => {
    const classes = styles();

    return (
        <div>
            <Typography className={classes.flagTitle}>{title}</Typography>
            {renderFlags(flags, daysSinceWeighIn, day, sleepPhaseCounter)}
        </div>
    );
};

const StyledTableRow = withStyles({
    root: {
        height: "65px",
        "&:nth-of-type(even)": {
            backgroundColor: "#f8f9fc",
        },
    },
})(TableRow);

const WeightCell: React.FC<WeightCellProps> = ({
    weight,
    alarm,
    patientName,
}) => {
    const [open, setOpen] = useState(false);
    const removeAlarmCall = async () => {
        await removeWeightAlarm(patientName);
        handleClickClose();
    };
    if (weight == null) return null;
    const classes = styles();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickClose = () => {
        setOpen(false);
    };

    if (!alarm)
        return (
            <Typography className={classes.tableCellText}>
                {weight} kg
            </Typography>
        );
    return (
        <>
            <RoundedButton
                color="red"
                onClick={handleClickOpen}
                EndIcon={NotificationImportant}
                text={`${weight} kg`}
                margin="center"
            />
            <Dialog open={open} onClose={handleClickClose}>
                <DialogTitle style={{ textAlign: "center" }}>
                    Weet je zeker dat je het alarm wilt verwijderen?
                </DialogTitle>
                <Typography
                    style={{ padding: "16px 24px", textAlign: "center" }}
                >
                    Op ACCEPTEREN drukken betekent dat het alarm bij volgende
                    gewichtsmeting van het scherm verdwijnt als het volgende
                    gewicht wordt VEILIG geacht (de range is van tevoren
                    vastgesteld en verschilt per patiënt).
                </Typography>

                <Grid
                    container
                    direction="row"
                    style={{ padding: "16px 24px" }}
                    justifyContent="center"
                >
                    <RoundedButton
                        color="darkBlue"
                        onClick={removeAlarmCall}
                        text="Accepteren"
                        margin="center"
                    />
                    <RoundedButton
                        color="grey"
                        onClick={handleClickClose}
                        text="Annuleren"
                        margin="center"
                    />
                </Grid>
            </Dialog>
        </>
    );
};

export const PatientTableBody: React.FC<PatientTableBodyProps> = ({
    sortedPatients,
    rowsPerPage,
    page,
    goToPatientPage,
    loadingData,
    organisations,
}) => {
    const classes = styles();

    if (loadingData) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <div
                    style={{
                        minHeight: "300px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </div>
            </Grid>
        );
    }

    const checkEnabledFitbit = (patient: PatientOverview) => {
        const organisation = organisations.find(
            (x) => x.name === patient.organisation
        );
        if (!organisation) {
            return false;
        }

        return organisation.enable_fitbit;
    };

    return (
        <TableBody>
            {sortedPatients.length !== 0 ? (
                (rowsPerPage > 0
                    ? sortedPatients.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                      )
                    : sortedPatients
                ).map((patient) => {
                    const lastNoninEntry = calculateLastActive(
                        patient.lastNoninEntry
                            ? moment.utc(patient.lastNoninEntry)
                            : null
                    );
                    const lastFitbitEntry = calculateLastActive(
                        patient.lastFitbitEntry
                            ? moment.utc(patient.lastFitbitEntry)
                            : null
                    );

                    const daysSinceWeighIn = getDaysSinceDate(
                        patient.last_weigh_in_date
                            ? moment.utc(patient.last_weigh_in_date)
                            : null
                    );

                    const weightAlarm =
                        patient.flags_last_night?.includes("weight_alarm");

                    console.log(
                        patient.flags_last_night,
                        patient.flags_last_week,
                        weightAlarm
                    );
                    const dailyFlagsWithoutAlarm =
                        patient.flags_last_night?.filter(
                            (flag) => flag !== "weight_alarm"
                        );
                    const weeklyFlagsWithoutAlarm =
                        patient.flags_last_week?.filter(
                            (flag) => flag !== "weight_alarm"
                        );

                    return (
                        <StyledTableRow key={patient.id}>
                            <TableCell
                                align="left"
                                colSpan={1}
                                onClick={() => goToPatientPage(patient.name)}
                                className={classes.tableCell}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="flex-start"
                                >
                                    <Grid item className={classes.avatar}>
                                        <Avatar className={classes.avatarIcon}>
                                            <Person />
                                        </Avatar>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        direction="column"
                                        alignItems="flex-start"
                                        style={{ width: "auto" }}
                                    >
                                        <Grid item>
                                            <Typography
                                                className={classes.userTitle}
                                            >
                                                {patient.name}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            direction="row"
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <Typography
                                                    className={
                                                        classes.userSubtitle
                                                    }
                                                >
                                                    {patient.organisation}
                                                </Typography>
                                            </Grid>

                                            <Grid item>
                                                <Typography
                                                    className={classes.roleText}
                                                >
                                                    PATIENT
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell
                                align="left"
                                className={classes.flagCell}
                            >
                                <div>
                                    <RenderFlags
                                        title="Week"
                                        flags={weeklyFlagsWithoutAlarm}
                                    />
                                    <RenderFlags
                                        title="Dag"
                                        flags={dailyFlagsWithoutAlarm}
                                        day
                                        daysSinceWeighIn={daysSinceWeighIn}
                                        sleepPhaseCounter={
                                            patient.sleep_phase_counter
                                        }
                                    />
                                </div>
                            </TableCell>
                            <TableCell align="left">
                                <Typography className={classes.tableCellText}>
                                    {checkEnabledFitbit(patient)
                                        ? patient.steps_last_day || "Onbekend"
                                        : "Fitbit Uitgeschakeld"}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography className={classes.tableCellText}>
                                    {checkEnabledFitbit(patient) &&
                                    patient.sleep_duration_last_night != null
                                        ? minutesToHourMinuteString(
                                              patient.sleep_duration_last_night
                                          ) // Convert ms to minutes first, then to a hours string.
                                        : "Fitbit Uitgeschakeld"}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography className={classes.tableCellText}>
                                    {checkEnabledFitbit(patient) ? (
                                        <WeightCell
                                            weight={patient.body_weight}
                                            alarm={weightAlarm}
                                            patientName={patient.name}
                                        />
                                    ) : (
                                        <Typography
                                            className={classes.tableCellText}
                                        >
                                            Fitbit Uitgeschakeld
                                        </Typography>
                                    )}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography className={classes.tableCellText}>
                                    {checkEnabledFitbit(patient)
                                        ? lastFitbitEntry
                                        : "Uitgeschakeld"}
                                </Typography>
                            </TableCell>

                            <TableOptionsMenu
                                organisations={organisations}
                                patient={patient}
                                lastEntry={lastNoninEntry}
                            />
                        </StyledTableRow>
                    );
                })
            ) : (
                <StyledTableRow>
                    <TableCell>Geen patientdata beschikbaar</TableCell>
                </StyledTableRow>
            )}
        </TableBody>
    );
};

export const PatientTableFooter: React.FC<PatientTableFooterProps> = ({
    sortedPatients,
    handlePageChange,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
}) => {
    const classes = styles();
    return (
        <TableFooter>
            <TableRow>
                <TablePagination
                    count={sortedPatients.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    colSpan={5}
                    rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "Alle", value: -1 },
                    ]}
                    SelectProps={{
                        native: true,
                    }}
                    ActionsComponent={TablePaginationActions}
                    className={classes.footer}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to > 0 ? to : count} van ${count}`
                    }
                    labelRowsPerPage="Rijen per pagina"
                />
            </TableRow>
        </TableFooter>
    );
};
