import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    popup: { width: "360px" },
    popupText: { fontWeight: "bold" },
    textField: {
        width: "100%",
        margin: "10px 0",
        "& .MuiFilledInput-root": {
            background: "#FAFAFA",
            borderRadius: "10px 10px 0px 0px",
        },
        "& .MuiFilledInput-input": {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "29px",
            color: "#585858",
        },
    },
    formLabel: { padding: "0 5px", zIndex: 100 },
    formTitle: { fontWeight: 700 },
    formContainer: { margin: "20px 0 30px" },
    formButton: { justifyContent: "flex-end", display: "flex" },
});
