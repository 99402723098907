import {
    DialogContent,
    Divider,
    FilledInput,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core";
import React from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import RoundedButton from "components/RoundedButton/RoundedButton";
import { AddPatientPopupProps } from "./AddPatientPopup.types";
import styles from "./AddPatientPopup.styles";

const AddPatientPopup: React.FC<AddPatientPopupProps> = ({
    handleSelectChange,
    handlePopupClose,
    handleShowPassword,
    handleInputFieldChange,
    showPassword,
    addPatient,
    errorText,
    inputFieldValues,
    role,
    organisations,
    currentOrganisations,
}) => {
    const classes = styles();
    const error = errorText !== "";

    return (
        <DialogContent className={classes.popup}>
            <div className={classes.formContainer}>
                <Typography className={classes.formTitle} variant="h5">
                    Nieuwe patient toevoegen
                </Typography>
                <FormControl className={classes.textField}>
                    <InputLabel
                        error={error}
                        className={classes.formLabel}
                        htmlFor="filled-username"
                    >
                        Naam
                    </InputLabel>
                    <FilledInput
                        id="filled-username"
                        type="text"
                        value={inputFieldValues.user_name}
                        onChange={handleInputFieldChange("user_name")}
                        error={error}
                    />
                </FormControl>
                {error && <FormHelperText error>{errorText}</FormHelperText>}
                <FormControl className={classes.textField}>
                    <InputLabel
                        className={classes.formLabel}
                        htmlFor="filled-password"
                    >
                        Wachtwoord
                    </InputLabel>
                    <FilledInput
                        id="filled-password"
                        type={showPassword ? "text" : "password"}
                        value={inputFieldValues.password}
                        onChange={handleInputFieldChange("password")}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl className={classes.textField}>
                    <InputLabel
                        className={classes.formLabel}
                        htmlFor="select-organisation"
                    >
                        Organisation
                    </InputLabel>
                    {role === "admin" && organisations ? (
                        <Select
                            labelId="select-organisation"
                            value={inputFieldValues.organisation_id}
                            label="Organisation"
                            onChange={handleSelectChange}
                        >
                            {organisations.map((organisation) => (
                                <MenuItem
                                    key={organisation.id}
                                    value={organisation.id}
                                >
                                    {organisation.name}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <FilledInput
                            disabled
                            id="select-organisation"
                            value={currentOrganisations[0].name}
                        />
                    )}
                </FormControl>
            </div>
            <Divider />
            <Grid
                className={classes.formContainer}
                container
                justify="space-between"
            >
                <Grid item>
                    <RoundedButton
                        text="Voeg patient toe"
                        onClick={addPatient}
                        color="darkBlue"
                    />
                </Grid>
                <Grid item>
                    <RoundedButton
                        text="Annuleren"
                        onClick={handlePopupClose}
                        color="grey"
                    />
                </Grid>
            </Grid>
        </DialogContent>
    );
};

export default AddPatientPopup;
