import { DetailedNightData, DipArrays } from "types/patientdata";
import React from "react";

export interface DipData {
    type: keyof DipArrays;
    limit: number | string;
}
export type ChangeHeartRateDipData = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>
) => void;
export type HandleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    upper?: boolean
) => void;
export interface HeartRateDipData {
    type: "heart_rate_fitbit_values" | "heart_rate_nonin_values";
    upperLimit: number | string;
    lowerLimit: number | string;
}
export enum DipArrayKeyStrings {
    heart_rate_nonin_values = "Heart rate",
    spo2_average_values = "SpO2 gemiddeld",
    spo2_minimum_values = "SpO2 minimum",
    pai_values = "PAI",
    hrv_values = "HRV",
}
export interface PatientNightDetailsTableProps {
    detailedNightData: DetailedNightData | undefined;
    oxygenDesaturationIndex: number;
    getSpo2MinDips: (limit: number) => number;
    spo2Minimum?: number;
    spo2Average?: number;
    dipData: DipData;
    amountOfDips: number;
    loadingData: boolean;
}

export interface HeartRateLimitTypeSelectorProps {
    hrDipData: HeartRateDipData;
    changeHeartRateDipData: ChangeHeartRateDipData;
}
export interface HeartRateLimitCellProps {
    hrDipData: HeartRateDipData;
    changeHeartRateDipData: ChangeHeartRateDipData;
    handleInputChange: HandleInputChange;
    handleBlur: () => void;
}
export interface HeartRateLimitValuesSelectorProps {
    hrDipData: HeartRateDipData;
    handleInputChange: HandleInputChange;
    type: keyof HeartRateDipData;
    handleBlur: () => void;
}
