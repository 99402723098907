import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles({
    root: {
        background: "#E5E5E5",
        minHeight: "100vh",
        maxWidth: "100vw",
    },
});

export default styles;
