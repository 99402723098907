import React from "react";
import {
    ChangePassword,
    DeletePatient,
    GetDailyAveragesOnReturn,
    GetPatientSettings,
    ChangePatientSetting,
    DeletePatientSetting,
    ChangeName,
} from "store/patient/patient.actions";
import {
    NoArgumentsReturnsVoid,
    StringArgumentReturnsVoid,
} from "types/general";
import { PatientOverview, PatientSetting } from "types/patientdata";
import { Moment } from "moment";
import { Dates } from "screens/PatientHome/PatientHome.types";
import { Organisation } from "types/user";

export const initialErrorValues = {
    name: "",
    new: "",
    repeat: "",
    archive: "",
    delete: "",
};

export interface ShowPassword {
    new: boolean;
    repeat: boolean;
}

export interface TextFieldValues {
    name: string;
    new: string;
    repeat: string;
    archive: string;
    delete: string;
}

type HandleTextFieldChange = (
    prop: keyof TextFieldValues
) => (event: React.ChangeEvent<HTMLInputElement>) => void;

export interface PopupHeaderProps {
    enabledFitbit?: boolean;
    patient: PatientOverview;
    date: string;
}

export interface PatientSettingProps {
    patientName: string;
    setting: PatientSetting;
    changePatientSetting: ChangePatientSetting;
    deletePatientSetting: DeletePatientSetting;
}

export interface AddPatientSettingProps {
    patientName: string;
    changePatientSetting: ChangePatientSetting;
    settingKeys: string[];
    settings: PatientSetting[];
}

export interface PatientSettingsFormProps {
    patientName: string;
    settings: PatientSetting[];
    getPatientSettings: GetPatientSettings;
    getPatientSettingKeys: NoArgumentsReturnsVoid;
    settingKeys: string[];
    deletePatientSetting: DeletePatientSetting;
    changePatientSetting: ChangePatientSetting;
}

export interface PasswordChangeFormProps {
    showPassword: ShowPassword;
    password: TextFieldValues;
    handlePasswordChange: HandleTextFieldChange;
    handleClickShowPassword: StringArgumentReturnsVoid;
    errors: TextFieldValues;
}

export interface PatientChangeNameFormProps {
    name: TextFieldValues;
    handleTextfieldChange: HandleTextFieldChange;
    errors: TextFieldValues;
}

export interface ArchivePatientFormProps {
    textFieldValues: TextFieldValues;
    handleTextfieldChange: HandleTextFieldChange;
    prop: keyof TextFieldValues;
    removePatient: NoArgumentsReturnsVoid;
    errors: TextFieldValues;
    title: string;
    buttonText: string;
}

export interface PopupFooterProps {
    savePopupData: NoArgumentsReturnsVoid;
    handlePopupClose: NoArgumentsReturnsVoid;
}

export interface CsvHeaderProps {
    patient: string;
}
type HandleDateChange = (date: Moment | null, props: keyof Dates) => void;
export interface CsvFormProps {
    handleDateChange: HandleDateChange;
    dates: Dates;
}
export interface CsvFooterProps {
    createCsv: NoArgumentsReturnsVoid;
    handlePopupClose: NoArgumentsReturnsVoid;
    loading: boolean;
}

export interface TableOptionsMenuProps {
    changePassword: ChangePassword;
    changeName: ChangeName;
    deletePatient: DeletePatient;
    organisations: Organisation[];
    patient: PatientOverview;
    lastEntry: string;
    getDailyAveragesOnReturn: GetDailyAveragesOnReturn;
    getPatientSettings: GetPatientSettings;
    settings: PatientSetting[];
    getPatientSettingKeys: NoArgumentsReturnsVoid;
    settingKeys: string[];
    deletePatientSetting: DeletePatientSetting;
    changePatientSetting: ChangePatientSetting;
}
