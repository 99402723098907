import { Link as RouterLink, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Breadcrumbs, Grid, Link, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { getRealTimeNonin } from "store/patient/patient.actions";
import { Home, NavigateNext, Person } from "@material-ui/icons";
import moment from "moment";
import { Filters } from "../../components/ValuesFilterFormControl/ValuesFilterFormControl.types";
import { NoninRealTimeProps } from "./NoninRealTime.types";
import styles from "./NoninRealTime.styles";
import ReduxState from "../../store/store.types";
import NoninRealTimeGraph from "../../components/NoninRealTimeGraph/NoninRealTimeGraph";
import {
    calculateAmountOfDipsRealtime,
    calculateAverageFromGraphObject,
    calculateMinimumFromGraphObject,
} from "../../helpers/calculateOxygenDesaturationIndex";
import NoninRealTimeTable from "../../components/NoninRealTimeTable/NoninRealTimeTable";

const filtersAllTrue: Filters = {
    average: {},
    detailed: {},
    realTime: {
        heart_rate_nonin_values: true,
        spo2_values: true,
        hrv_values: true,
        pai_values: true,
    },
};

const NoninRealTime: React.FC<NoninRealTimeProps> = ({
    currentPatient,
    getRealTimeNonin,
}) => {
    const { patient } = useParams();
    const classes = styles();
    const [timeWindow, setTimeWindow] = useState<number>(30);
    const [filters, setFilters] = useState<Filters>(filtersAllTrue);

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({
            ...filters,
            realTime: {
                ...filters.realTime,
                [event.target.name]: event.target.checked,
            },
        });
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTimeWindow(Number(event.target.value));
    };

    const getSpO2Dips = () =>
        calculateAmountOfDipsRealtime(
            89,
            currentPatient.realTimeNonin?.spo2_values
        );
    const getSpO2Min = () =>
        calculateMinimumFromGraphObject(
            currentPatient.realTimeNonin?.spo2_values
        );
    const getSpO2Average = () =>
        calculateAverageFromGraphObject(
            currentPatient.realTimeNonin?.spo2_values
        );
    const getHeartRateNoninAverage = () =>
        calculateAverageFromGraphObject(
            currentPatient.realTimeNonin?.heart_rate_nonin_values
        );

    useEffect(() => {
        const now: string = moment().format("YYYY-MM-DDTHH:mm:ss");
        const realTimeNoninRequest = {
            patient_name: patient,
            moment_date: now,
            timeWindow,
        };
        getRealTimeNonin(realTimeNoninRequest);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const timer = setInterval(
            () =>
                getRealTimeNonin({
                    patient_name: patient,
                    moment_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
                    timeWindow,
                }),
            2500
        );
        return () => {
            // Cleanup
            clearInterval(timer);
        };
    }, [patient, timeWindow, getRealTimeNonin]);

    return (
        <Grid className={classes.div}>
            <Typography variant="h4" className={classes.titleText}>
                {patient} &#8226; {moment().format("YYYY-MM-DD")}
            </Typography>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.title}
            >
                <Grid item>
                    <Breadcrumbs
                        separator={<NavigateNext className={classes.divider} />}
                    >
                        <Link
                            to="/"
                            className={classes.link}
                            component={RouterLink}
                        >
                            <Home className={classes.icon} />
                            Patienten
                        </Link>
                        <Link
                            to={`/${patient}`}
                            className={classes.link}
                            component={RouterLink}
                        >
                            <Person className={classes.icon} />
                            {patient}
                        </Link>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <NoninRealTimeGraph
                realTimeNoninData={currentPatient.realTimeNonin}
                filters={filters}
                patientName={patient}
                handleFilterChange={handleFilterChange}
                handleInputChange={handleInputChange}
                timeWindow={timeWindow}
            />
            <NoninRealTimeTable
                spO2Dips={getSpO2Dips()}
                heartRateNoninAverage={getHeartRateNoninAverage()}
                spO2Average={getSpO2Average()}
                spO2Minimum={getSpO2Min()}
            />
        </Grid>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    currentPatient: state.patient.currentPatient,
});
const mapDispatchToProps = {
    getRealTimeNonin,
};
const ConnectNoninRealTime = connect(
    mapStateToProps,
    mapDispatchToProps
)(NoninRealTime);

export default ConnectNoninRealTime;
