import React from "react";
import { Route, Switch } from "react-router-dom";
import {
    loggedInRedirect,
    requireAuth,
    requireAuthWithMatch,
} from "helpers/auth";
import Main from "layouts/Main/Main";
import Home from "screens/Home/Home";
import Login from "screens/Login/Login";
import PatientHome from "screens/PatientHome/PatientHome";
import PatientNightDetails from "screens/PatientNightDetails/PatientNightDetails";
import OrganisationManagement from "screens/OrganisationManagement/OrganisationManagement";
import UserManagement from "screens/UserManagement/UserManagement";
import ChangePassword from "screens/ChangePassword/ChangePassword";
import ResetPassword from "screens/ResetPassword/ResetPassword";
import NoninRealTime from "./screens/NoninRealTime/NoninRealTime";

const Router = () => (
    <Switch>
        <Route
            path="/create-new-password"
            render={loggedInRedirect(ChangePassword)}
        />
        <Route
            path="/reset-password"
            render={loggedInRedirect(ResetPassword)}
        />
        <Route exact path="/login" render={loggedInRedirect(Login)} />

        <Route exact path="/" render={requireAuth(Home)} />
        <Route
            exact
            path="/user-management"
            render={requireAuth(UserManagement)}
        />
        <Route
            exact
            path="/organisation-management"
            render={requireAuth(OrganisationManagement)}
        />
        <Route
            exact
            path="/:patient"
            render={requireAuthWithMatch(PatientHome)}
        />
        <Route
            exact
            path="/:patient/realtime"
            render={requireAuthWithMatch(NoninRealTime)}
        />
        <Route
            exact
            path="/:patient/:night"
            render={requireAuthWithMatch(PatientNightDetails)}
        />
    </Switch>
);
const App: React.FC = () => (
    <Main>
        <Router />
    </Main>
);

export default App;
