import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    paper: {
        margin: "0 0 50px",
    },
    tableheader: {
        width: "100%",
    },
    sliderContainer: {
        width: "100%",
        boxShadow: "inset 0px -1px 0px rgba(4, 66, 114, 0.16)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        padding: "10px 20px",
    },
    text: {
        color: "#044272",
        fontWeight: 400,
    },
    headerButton: {
        margin: "0 7px",
        width: "163px",
        height: "36px",
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        textTransform: "revert",
        color: "#044272",
        fontWeight: 600,
        fontSize: "16px",
        padding: "2px 10px",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
        "& .MuiButton-endIcon": {
            marginLeft: 0,
        },
    },
    tableheaderCell: {
        padding: "10px 0px",
    },
    tableFooterCell: {
        color: "#044272",
        padding: "10px 4px 10px 10px",
        border: "none",
    },
    timefilterContainer: {
        color: "#044272",
        padding: "10px 4px 10px 4px",
        border: "none",
    },
    filterCheckBox: {
        background: "#F8F9FC",
        margin: "0 7px",
        padding: "5px 10px 5px 0",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        height: "36px",
    },
    checkboxLabel: {
        color: "#044272",
        fontWeight: 600,
    },
    graphContainer: (props: { empty?: boolean }) => ({
        padding: "10px 20px",
        height: props.empty ? "none" : "calc(60vw/16*9)",
    }),
    limitTypeSelector: {
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        color: "#044272",
        fontWeight: 600,
        fontSize: "16px",
        padding: "2px 5px",
        marginLeft: "5px",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
        "& .MuiSelect-select": {
            padding: "5px 5px 5px 0",
        },
    },
    dipsDisplay: {
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        color: "#044272",
        fontWeight: 600,
        fontSize: "16px",
        padding: "5px 10px",
        marginLeft: "5px",
    },
    limitTypeGrid: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    limitNumberSelector: {
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        color: "#044272",
        fontWeight: 600,
        fontSize: "16px",
        padding: "2px 5px",
        marginLeft: "5px",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
    },
    datePickerTextField: {
        margin: "0 7px",
        width: "160px",
        height: "36px",
        background: "#F8F9FC",
        border: "1px solid rgba(212, 218, 225, 0.16)",
        borderRadius: "6px",
        boxSizing: "border-box",
        "&:hover": {
            cursor: "pointer",
            background: "rgb(239,239,239)",
        },
        "& .MuiInputBase-root": {
            color: "#044272",
            fontWeight: 600,
            padding: "2px 10px",
            cursor: "pointer",
        },
        "& .MuiInputBase-input": {
            cursor: "pointer",
        },
    },
    topFilterContainer: {
        color: "#044272",
        padding: "10px 10px",
        border: "none",
    },
    bottomFilterContainer: {
        color: "#044272",
        padding: "10px 4px 10px 0px",
        border: "none",
    },
});

export const dataColors = {
    heart_rate_nonin_values: "#D05B56",
    spo2_average_values: "#63CB61",
    spo2_minimum_values: "#429329",
    pai_values: "#044272",
    hrv_values: "#AD679B",
    heart_rate_fitbit_values: "#E5A73A",
    activity_fitbit_values: "#8190dc",
    sleep_stage_values: "#E06E91",
    cumulative_activity_fitbit_values: "#4153AF",
    spo2_values: "#429329",
    total_wake_sleep_fitbit: "#EE3F767F",
    total_rem_sleep_fitbit: "#8190dc",
    total_deep_sleep_fitbit: "#044272",
    total_light_sleep_fitbit: "#4153AF",
    // for average graph
    heart_rate_nonin_average: "#D05B56",
    spo2_average_average: "#63CB61",
    spo2_minimum_average: "#429329",
    pai_average: "#044272",
    hrv_average: "#AD679B",
    resting_heart_rate: "#E5A73A",
    sleep_efficiency_fitbit: "#E06E91",
    total_sleeptime_fitbit: "#d33465",
    temperature_difference: "#fe6601",
    weight: "#8190dc",
    body_fat: "#D05B56",
    breathing_rate: "#429329",
    total_activity_fitbit: "#201E43",
};
